import { useContext, useEffect } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useQueryParams, useSubmitUserInput } from 'src/hooks';
import { toast } from 'react-toastify';
import { DEFAULT_CHAT_ID } from 'src/constants';
import { sendGTMEvent } from 'src/utils';
import { BannerType, GTMEvent } from 'src/types';
import { useBanner } from 'src/hooks/useBanner';

/**
 * useChatPageQueryParams handles all query parameters on the Main chat page.
 */
export const useChatPageQueryParams = () => {
  const {
    searchParams: { session_id, query },
    removeSearchParam,
  } = useQueryParams();
  const { onSubmitUserInput } = useSubmitUserInput();
  const { removeBanners } = useBanner();

  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);

  useEffect(() => {
    if (session_id) {
      //TODO(olha): WIP call checking endpoint
      toast('Welcome to Ninja PRO');
      removeBanners([
        BannerType.INSUFFICIENT_CREDITS,
        BannerType.LOW_CREDITS,
        BannerType.LOW_TASKS,
      ]);
      sendGTMEvent(GTMEvent.SUCCESSFUL_NEW_PRO_SUBSCRIPTION);
      removeSearchParam('session_id');
      //TODO(olha): WIP error handling toast('Session ID is not valid.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_id, removeSearchParam]);

  useEffect(() => {
    if (query) {
      const decodedQuery = decodeURIComponent(query);
      setThreadInputBoxValue(decodedQuery);
      onSubmitUserInput(decodedQuery, { conversation_id: DEFAULT_CHAT_ID });
      removeSearchParam('query');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setThreadInputBoxValue, removeSearchParam]);
};
