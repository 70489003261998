import { Modal } from 'src/components/Modal';
import { SignupForFreeButton } from 'src/components/SignupForFreeButton';
import { useBreakpoint, useSession } from 'src/hooks';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { LazyReactPlayer } from 'src/components/LazyReactPlayer';

type UpsellVideoModalProps = {
  videoUrl: string;
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
};

export const UpsellVideoModal = ({
  videoUrl,
  title,
  description,
  isOpen,
  onClose,
}: UpsellVideoModalProps) => {
  const { isOpenTier, isFreeTier } = useSession();
  const { isMobile } = useBreakpoint();
  return (
    <Modal open={isOpen} onClose={onClose} className="nj-upsell-video-modal">
      <div className="nj-upsell-video-modal-content">
        <h3 className="nj-upsell-video-modal-title">{title}</h3>

        <div className="nj-upsell-video-modal-video-wrapper">
          <LazyReactPlayer
            url={videoUrl}
            controls
            width="100%"
            height={isMobile ? '320px' : '450px'}
            playing
          />
        </div>

        <p className="nj-upsell-video-modal-description">{description}</p>

        <div className="nj-upsell-video-modal-button-wrapper">
          {isOpenTier && <SignupForFreeButton onClick={onClose} />}
          {isFreeTier && <UpgradeButton onClick={onClose} />}
        </div>
      </div>
    </Modal>
  );
};
