import dayjs from 'dayjs';
import { PAYMENTS_DATE_FORMAT } from 'src/constants';
import { useSession } from 'src/hooks';
import { LabelPro } from 'src/components/LabelPro';
import { UpgradeButton } from 'src/components/UpgradeButton';
import './SubscriptionPlansForm.scss';
import { useGetUserSubscriptionInfoQuery } from 'src/store/services';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { UserTier } from 'src/types/models/UserTier';
import { LabelFree } from 'src/components/LabelFree';
import { LabelProTrial } from 'src/components/FlatAppearance/components/LabelProTrial';

// TODO(olha): divide to small components
export const SubscriptionPlansForm = () => {
  const { isProTier, appUser, isProTrialTier, isFreeTier } = useSession();
  const { data, isLoading } = useGetUserSubscriptionInfoQuery(
    {
      user_id: appUser.user_id,
    },
    { skip: appUser.tier_id !== UserTier.PAID },
  );

  const { onChangeIsVisibleUpdatePlanModal } = useContext(SessionContext);

  if (isLoading) {
    return null;
  }

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data?.price_info?.currency || 'USD',
  });

  const creditInfo = moneyFormatter.format(data?.price_info?.credit || 0);

  const amountInfo = moneyFormatter.format(data?.price_info?.amount || 0);

  const cancelledDate = dayjs(data?.curr_period_end_timestamp).format(
    PAYMENTS_DATE_FORMAT,
  );

  const upcomingPayment = data?.is_cancelled
    ? 'None'
    : `${amountInfo} on ${cancelledDate}`;

  const handleEditClick = () => {
    onChangeIsVisibleUpdatePlanModal(true);
  };

  return (
    <div className="nj-subscription-plans-form">
      <div className="nj-subscription-plans-form--container">
        <div className="nj-subscription-plans-form--header-wrapper">
          <p className="nj-subscription-plans-form--header">
            <span className="nj-subscription-plans-form--title">
              Subscription
            </span>

            <span className="nj-subscription-plans-form--description">
              {isProTier &&
                'Edit or cancel your subscription. Choose between monthly and annual billing. All annual billing plans will receive a 30% discount.'}
              {isFreeTier &&
                'Enjoy unlimited access to Ninja and receive credits towards 24+ external models.'}
              {isProTrialTier &&
                'Continue enjoying PRO features - Upgrade now!'}
            </span>
          </p>
          {isProTier && (
            <button
              className="nj-subscription-plans-form--edit-button"
              type="button"
              onClick={handleEditClick}
              disabled={
                !!appUser?.stripe_info?.renew_early_pending_subscription
              }
            >
              Edit
            </button>
          )}
        </div>

        <div className="nj-subscription-plans-form--field">
          <p className="nj-subscription-plans-form--label">Plan</p>
          {isProTier || isProTrialTier ? (
            <div className="nj-subscription-plans-form--value-wrapper">
              {isProTrialTier ? <LabelProTrial /> : <LabelPro />}
              {data && data?.is_cancelled && (
                <div className="nj-subscription-plans-form--cancelled-label">
                  Plan will be canceled on &nbsp;
                  <span className="nj-subscription-plans-form--cancelled-date">
                    {cancelledDate}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <p className="nj-subscription-plans-form--value">
              <LabelFree />
            </p>
          )}
        </div>

        {isProTier && (
          <>
            <div className="nj-subscription-plans-form--field">
              <p className="nj-subscription-plans-form--label">Billing cycle</p>
              <p className="nj-subscription-plans-form--value capitalize">
                {data?.interval}
              </p>
            </div>

            <div className="nj-subscription-plans-form--field">
              <p className="nj-subscription-plans-form--label">
                Available credits per billing cycle
              </p>
              <p className="nj-subscription-plans-form--value">{creditInfo}</p>
            </div>

            <div className="nj-subscription-plans-form--field">
              <p className="nj-subscription-plans-form--label">
                Upcoming Payment
              </p>
              <p className="nj-subscription-plans-form--value">
                {upcomingPayment}
              </p>
            </div>
          </>
        )}
      </div>

      {!isProTier && <UpgradeButton />}
    </div>
  );
};
