import { useContext, useEffect, useRef } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { GTMEvent } from 'src/types';
import { sendGTMEvent } from 'src/utils';
import { useSession } from './useSession';
import { useUserData } from './useUserData';

export const useTermsServiceAgreement = () => {
  const { onToggleVisibleUpgradeModal, onToggleWelcomeModal } =
    useContext(SessionContext);

  const { appUser, isFreeTier, isProTrialTier } = useSession();

  const { updateUserData } = useUserData();

  // (olha): it's an important flag to avoid multiple calls to the GTM
  const sendedEventCounter = useRef<number>(0);

  const handleConsentUpdate = async () => {
    if (sendedEventCounter.current === 0) {
      sendedEventCounter.current += 1;

      await updateUserData(
        {
          consented: true,
        },
        false,
      );

      sendGTMEvent(GTMEvent.CONSENT_FORM_CONFIRM);

      if (isProTrialTier) {
        onToggleWelcomeModal(true);
      }
      if (isFreeTier) {
        onToggleVisibleUpgradeModal(true);
      }
    }
  };

  useEffect(() => {
    if (!!appUser?.user_id && appUser?.consented === false) {
      handleConsentUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser?.user_id, appUser?.consented]);
};
