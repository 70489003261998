import { Link } from 'react-router-dom';
import classNames from 'classnames';

interface GuestAuthLinksProps {
  className?: string;
}

export const GuestAuthLinks = ({ className }: GuestAuthLinksProps) => {
  return (
    <div
      className={classNames('nj-tier-auth-footer-button-wrapper', className)}
    >
      <Link to="/login" className="nj-tier-auth-footer-button login-button">
        Login
      </Link>
      <Link to="/sign-up" className="nj-tier-auth-footer-button signup-button">
        Try PRO for free
      </Link>
    </div>
  );
};
