import { useSession } from 'src/hooks';
import { Tooltip } from './Tooltip';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';

export const PromoTooltip = () => {
  const { isProTier, isFreeTier, isOpenTier, isProTrialTier } = useSession();

  if (isProTier) {
    return null;
  }

  return (
    <Tooltip>
      {isOpenTier && <GuestAuthLinks />}
      {(isProTrialTier || isFreeTier) && <UpgradeLearnMoreButtons />}
    </Tooltip>
  );
};
