import {
  SignOutButton,
  SupportButton,
} from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons';
import styles from './UserMenuTooltip.module.scss';
import { useSession } from 'src/hooks';
import { UserMenuMainInfo } from '../UserMenuMainInfo';
import { WalletSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { ThemeSelector } from '../ThemeSelector';
import { SettingsLink } from '../SettingsLink';

interface UserMenuTooltipProps {
  onClose: () => void;
}

export const UserMenuTooltip = ({ onClose }: UserMenuTooltipProps) => {
  const { isOpenTier } = useSession();

  return (
    <div className={styles.root}>
      <UserMenuMainInfo />

      <div onClick={onClose}>
        <div className={styles.walletWrapper}>
          <WalletSection />
        </div>

        <SettingsLink />

        <ThemeSelector />

        <SupportButton />
      </div>

      {isOpenTier ? (
        <GuestAuthLinks className={styles.authLinks} />
      ) : (
        <SignOutButton />
      )}
    </div>
  );
};
