import { useCallback, useMemo } from 'react';
import {
  userChatsState,
  useAppSelector,
  useAppDispatch,
  setThreadStatus,
} from 'src/store';
import { ThreadsStatusRegistryPayload } from 'src/store/slices/userChatsSlice';
import { useConversation } from 'src/hooks';

export const useThreads = () => {
  const { shouldAnimate, threadsStatusRegistry } =
    useAppSelector(userChatsState);

  const { currentConversationId } = useConversation();

  const ignoreMessages = useMemo(
    () => threadsStatusRegistry[currentConversationId]?.ignoreMessages,
    [threadsStatusRegistry, currentConversationId],
  );

  const isSubmitHappened = useMemo(
    () => threadsStatusRegistry[currentConversationId]?.isSubmitHappened,
    [threadsStatusRegistry, currentConversationId],
  );

  const dispatch = useAppDispatch();

  const updateThreadStatus = useCallback(
    ({ threadId, statusRegistry }: ThreadsStatusRegistryPayload) => {
      dispatch(
        setThreadStatus({
          threadId,
          statusRegistry,
        }),
      );
    },
    [dispatch],
  );

  return {
    shouldAnimate,
    ignoreMessages,
    isSubmitHappened,
    updateThreadStatus,
  };
};
