import { createApi } from '@reduxjs/toolkit/query/react';
import { baseAuthQuery } from './config';

export enum AuthTags {
  Email = 'Email',
}

export const publicAuthApi = createApi({
  reducerPath: 'publicAuthApi',
  tagTypes: [AuthTags.Email],
  baseQuery: baseAuthQuery,
  endpoints: (builder) => ({
    checkEmailInfo: builder.query<{ provider: string }, string>({
      query: (email) => `/email-info?email=${email}`,
      providesTags: () => [AuthTags.Email],
    }),
  }),
});

export const { useLazyCheckEmailInfoQuery } = publicAuthApi;
