import dayjs from 'dayjs';
import {
  PaymentPlanOption,
  PaymentPlanOptionWithSale,
  PaymentsActivityColumn,
} from 'src/types';
import { CheckCircle } from '@phosphor-icons/react';
import {
  externalModelsSet,
  imageGeneratorsModelsSet,
  ninjaLLMComponents,
} from 'src/constants/llmResources';

export const FREE_CREDITS = 10;

// TODO(olha): use BE data, remove hardcode
export const PLAN_OPTIONS: PaymentPlanOptionWithSale[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    isDefault: true,
    newAmount: 1,
    promotion_code: 'NINJA9',
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
    newAmount: 11,
    promotion_code: 'NINJA9',
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
    newAmount: 21,
    promotion_code: 'NINJA9',
  },
];

export const PLAN_OPTIONS_FOR_FREE: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    isDefault: true,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
  },
];

export const RENEW_EARLY_MONTHLY_OPTIONS: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    tasksCount: 600,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
    tasksCount: 1200,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
    tasksCount: 1800,
  },
];

export const RENEW_EARLY_ANNUALLY_OPTIONS: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_annually_84',
    amount: 8400,
    tasksCount: 24000,
    isAnnual: true,
  },
  {
    lookup_key: 'pro_annually_168',
    amount: 16800,
    tasksCount: 48000,
    isAnnual: true,
  },
  {
    lookup_key: 'pro_annually_252',
    amount: 25200,
    tasksCount: 72000,
    isAnnual: true,
  },
];

export const PLAN_FREE_FEATURES = [
  { title: 'Advisor', withBorder: false },
  { title: 'Researcher', withBorder: false },
  { title: 'Coder', withBorder: false },
  { title: 'Deep Researcher', withBorder: false },
  { title: 'Image Generator', withBorder: false },
  { title: 'Scheduler', withBorder: true },
  { title: '5 minute video chat sessions', withBorder: false },
  { title: '10 custom conversation tones', withBorder: false },
  { title: 'Email & In-product support', withBorder: false },
];

export const PRO_TRIAL_FEATURES = [
  { title: 'Advisor', withBorder: false },
  { title: 'Researcher', withBorder: false },
  { title: 'Coder', withBorder: false },
  { title: 'Deep Researcher', withBorder: false },
  { title: 'Image Generator', withBorder: false },
  { title: 'Image Editor', withBorder: false },
  { title: 'Multi-party Scheduler', withBorder: false },
];

export const PLAN_PRO_FEATURES = [
  { title: 'Advisor', withBorder: false },
  { title: 'Researcher', withBorder: false },
  { title: 'Coder', withBorder: false },
  { title: 'Deep Researcher', withBorder: false },
  { title: 'Image Generator', withBorder: false },
  { title: 'Image Editor', withBorder: false },
  { title: 'Multi-party Scheduler', withBorder: false },
  {
    title: '24+ External models',
    withBorder: true,
    isExternalModels: true,
  },
  {
    title: 'State of art interactive avatars',
    withBorder: false,
    // link: { label: '20 Avatars', href: PAY_PER_USE },
  },
  { title: '10 custom conversation tones', withBorder: false },
  { title: 'Email & In-product support', withBorder: false },
];

export const ACTIVITY_COLUMNS: PaymentsActivityColumn[] = [
  {
    header: 'Task Name',
    accessor: 'task_hash',
    render: (activity) => {
      return (
        <>
          {activity.task_hash}
          <div className="visible-sm hash-timestamp">
            <div>
              {dayjs(activity.timestamp).format(PAYMENTS_DATE_TIME_FORMAT)}
            </div>
          </div>
        </>
      );
    },
  },
  {
    header: 'Models',
    accessor: 'models',
    render: (activity) => {
      const models = activity.models.map((model, index) => {
        const modelKey = [
          model.platform,
          model.provider,
          model.model_name,
        ].join('/');

        return (
          <div key={index} className="activity_models">
            <div className="empty_icon hidden-sm"></div>
            {externalModelsSet[modelKey] &&
              externalModelsSet[modelKey]['display_name']}
            {imageGeneratorsModelsSet[modelKey] &&
              imageGeneratorsModelsSet[modelKey]['display_name']}
          </div>
        );
      });
      const components: React.ReactElement[] = [];
      activity.components.forEach((component, index) => {
        if (ninjaLLMComponents[component.component]) {
          components.push(
            <div key={index} className="activity_models">
              <CheckCircle
                className="hidden-sm"
                size={16}
                weight="fill"
                color="#0A74FF"
                style={{ marginRight: '4px' }}
              />{' '}
              {ninjaLLMComponents[component.component].display_name}
            </div>,
          );
        }
      });
      if (!models.length && !components.length) {
        return ninjaLLMComponents[activity.skill] ? (
          <>
            <CheckCircle
              className="hidden-sm"
              size={16}
              weight="fill"
              color="#0A74FF"
              style={{ marginRight: '4px' }}
            />{' '}
            {ninjaLLMComponents[activity.skill].display_name}
          </>
        ) : null;
      }
      return (
        <>
          {components}
          {models}
        </>
      );
    },
  },
  {
    header: 'Date',
    accessor: 'timestamp',
    render: (activity) => (
      <div>
        <div>{dayjs(activity.timestamp).format(PAYMENTS_TIME_FORMAT)}</div>
        <div>{dayjs(activity.timestamp).format(PAYMENTS_DATE_FORMAT)}</div>
      </div>
    ),
  },
  {
    header: 'Credits',
    accessor: 'cost',
    render: (activity) => {
      const creditInfo =
        activity.status === 'pending'
          ? 'pending...'
          : `$${Number(activity.cost).toFixed(2)}`;
      return (
        <div>
          <div>{creditInfo}</div>
          {activity.cost === 0 && (
            <div className="included_in_pro">
              <CheckCircle size={16} color="#0A74FF" weight="fill" /> INCLUDED
              IN PRO
            </div>
          )}
        </div>
      );
    },
  },
];

export const PAYMENTS_TIME_FORMAT = 'h:mm A';
export const PAYMENTS_DATE_FORMAT = 'MMM D, YYYY';
export const PAYMENTS_DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';
