import { Header } from 'src/pages/AuthPages/components/Header';
import atlasSmile from 'src/images/login/atlas_smile.png';
import AppleLogo from 'src/images/logos/apple-logo.png';
import AmazonLogo from 'src/images/logos/amazon-logo.png';
import GoogleLogo from 'src/images/logos/google-logo.svg';
import { signInWithRedirect } from 'aws-amplify/auth';
import {
  DEFAULT_PRIVACY_POLICY_LINK,
  DEFAULT_TERMS_OF_SERVICE_LINK,
} from 'src/constants/externalLinks';
import AuthContext from 'src/contexts/AuthContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Envelope } from '@phosphor-icons/react';
import { LoginHeader } from 'src/pages/AuthPages/components/LoginHeader';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';
import FacebookLogo from 'src/images/logos/facebook-logo.png';

// todo: needs to refactoring this component (duplicated buttons, functions)
export const LoginPage = () => {
  const { setAuthLoading } = useContext(AuthContext);

  const handleGoogleLoginClick = async () => {
    sendGTMEvent(GTMEvent.GOOGLE_SSO_SIGNUP_CLICKS);

    setAuthLoading(true);
    signInWithRedirect({
      provider: 'Google',
    });
  };

  const handleAmazonLoginClick = async () => {
    sendGTMEvent(GTMEvent.AMAZON_SSO_SIGNUP_CLICKS);

    setAuthLoading(true);
    signInWithRedirect({
      provider: 'Amazon',
    });
  };

  const handleAppleLoginClick = async () => {
    sendGTMEvent(GTMEvent.APPLE_SSO_SIGNUP_CLICKS);

    setAuthLoading(true);
    signInWithRedirect({
      provider: 'Apple',
    });
  };

  const handleFacebookLoginClick = async () => {
    sendGTMEvent(GTMEvent.FACEBOOK_SSO_SIGNUP_CLICKS);

    setAuthLoading(true);
    signInWithRedirect({
      provider: 'Facebook',
    });
  };

  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<LoginHeader />} />
      <main className="nj-auth-content">
        <h1 className="nj-auth-header-main">Welcome back!</h1>
        <h3 className="nj-auth-header-secondary">Login to your account</h3>
        <div className="nj-auth-sign-in-up-buttons">
          <button
            className="nj-auth-sign-in-up-button nj-google-button"
            onClick={handleGoogleLoginClick}
          >
            <div className="nj-google-button-state"></div>
            <div className="nj-google-button-content-wrapper">
              <div>
                <img
                  className="nj-auth-sign-in-up-button-logo"
                  src={GoogleLogo}
                  alt="Google"
                />
              </div>
              <span className="nj-google-button-contents">
                Continue with Google
              </span>
              <span style={{ display: 'none' }}>Continue with Google</span>
            </div>
          </button>
          <button
            className="nj-auth-sign-in-up-button nj-google-button"
            onClick={handleAppleLoginClick}
          >
            <div className="nj-google-button-state"></div>
            <div className="nj-google-button-content-wrapper">
              <div>
                <img
                  className="nj-auth-sign-in-up-button-logo"
                  src={AppleLogo}
                  alt="Apple"
                />
              </div>
              <span className="nj-google-button-contents">
                Continue with Apple
              </span>
              <span style={{ display: 'none' }}>Continue with Apple</span>
            </div>
          </button>
          <button
            className="nj-auth-sign-in-up-button nj-google-button"
            onClick={handleAmazonLoginClick}
          >
            <div className="nj-google-button-state"></div>
            <div className="nj-google-button-content-wrapper">
              <div>
                <img
                  className="nj-auth-sign-in-up-button-logo"
                  src={AmazonLogo}
                  alt="Amazon"
                />
              </div>
              <span className="nj-google-button-contents">
                Continue with Amazon
              </span>
              <span style={{ display: 'none' }}>Continue with Amazon</span>
            </div>
          </button>
          <button
            className="nj-auth-sign-in-up-button nj-google-button"
            onClick={handleFacebookLoginClick}
          >
            <div className="nj-google-button-state"></div>
            <div className="nj-google-button-content-wrapper">
              <div>
                <img
                  className="nj-auth-sign-in-up-button-logo"
                  src={FacebookLogo}
                  alt="Facebook"
                />
              </div>
              <span className="nj-google-button-contents">
                Continue with Facebook
              </span>
              <span style={{ display: 'none' }}>Continue with Facebook</span>
            </div>
          </button>
          <div className="nj-auth-separator-block">
            <div className="nj-auth-separator-block-line" />
            <span>OR</span>
            <div className="nj-auth-separator-block-line" />
          </div>
          <Link className="nj-auth-sign-in-up-button" to="/login-with-email">
            <div className="nj-button-content-wrapper">
              <Envelope size={24} />
              <span className="nj-button-contents">Continue with Email</span>
            </div>
          </Link>
        </div>
        <div className="nj-auth-terms-links-row">
          <a
            href={DEFAULT_TERMS_OF_SERVICE_LINK}
            target="_blank"
            rel="noreferrer"
            className="nj-auth-terms-link"
          >
            Terms of Service
          </a>
          <div className="nj-vertical-line" />
          <a
            href={DEFAULT_PRIVACY_POLICY_LINK}
            target="_blank"
            rel="noreferrer"
            className="nj-auth-terms-link"
          >
            Privacy Policy
          </a>
        </div>
      </main>
      <img className="nj-auth-background-image" src={atlasSmile} alt="Ninja" />
    </div>
  );
};
