import { useContext, useMemo, useCallback } from 'react';
import { GTMEvent, TaskState } from 'src/types';
import { useTaskById } from 'src/hooks';
import SessionContext from 'src/contexts/SessionContext';
import { sendGTMEvent } from 'src/utils';

interface TaskProgressStatusTitleProps {
  taskState?: TaskState;
  taskId?: string;
}

export const TaskProgressStatusTitle = ({
  taskState,
  taskId,
}: TaskProgressStatusTitleProps) => {
  const { onToggleVisibleTaskViewModal } = useContext(SessionContext);
  const { setCurrentTaskId } = useTaskById();

  const handleOpenTaskDetails = useCallback(() => {
    if (taskId) {
      setCurrentTaskId(taskId);
      onToggleVisibleTaskViewModal(true);
      sendGTMEvent(GTMEvent.TASK_VIEW_OPENING, {
        task_id: taskId,
        task_state: taskState,
      });
    }
  }, [taskId, taskState, setCurrentTaskId, onToggleVisibleTaskViewModal]);

  const statusTitle = useMemo(() => {
    switch (taskState) {
      case TaskState.IN_PROGRESS:
        return 'Task in progress';

      case TaskState.DONE:
        return 'Done';

      case TaskState.HALTING:
      case TaskState.STOPPING:
        return 'Task Stopping';

      case TaskState.ARCHIVED:
        return 'Task archived';

      case TaskState.UNDO_IN_PROGRESS:
        return 'Task undone';

      case TaskState.FAILED:
        return 'Task failed';

      case TaskState.STOPPED:
        return 'Task stopped';

      case TaskState.BLOCKED:
        return (
          <>
            We need you to confirm the details.{' '}
            <button
              className="nj-task-progress-indicator--link"
              onClick={handleOpenTaskDetails}
            >
              Click here
            </button>
          </>
        );

      default:
        return 'Task blocked';
    }
  }, [taskState, handleOpenTaskDetails]);

  return (
    <p className="nj-task-progress-indicator--status-title ellipsis">
      {statusTitle}
    </p>
  );
};
