import { useNavigate } from 'react-router-dom';

type SignupForFreeButtonProps = {
  onClick?: () => void;
};

export const SignupForFreeButton = ({ onClick }: SignupForFreeButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/sign-up');
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className="nj-signup-for-free-button"
      onClick={handleClick}
    >
      Try Ninja PRO for free
    </button>
  );
};
