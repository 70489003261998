import {
  useContext,
  useState,
  KeyboardEvent,
  ChangeEvent,
  SyntheticEvent,
  useMemo,
} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import AudioContext from 'src/contexts/AudioContext';
import ForwardRefContext, {
  ForwardRefContextType,
} from 'src/contexts/ForwardRefContext';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import {
  useThreads,
  useSession,
  useSubmitUserInput,
  useBreakpoint,
  useConversation,
} from 'src/hooks';
import { interruptMetahuman } from 'src/utils';
import { useInputBoxState } from './hooks/useInputBoxState';
import { ChatMode, KeyCodes } from 'src/types';
import { MicrophoneTrigger } from 'src/pages/ManageTasksChatPage/components/ChatForm/components/MicrophoneTrigger';
import { SubmitTrigger } from 'src/pages/ManageTasksChatPage/components/ChatForm/components/SubmitTrigger';
import { QuickContactPicker } from './components/QuickContactPicker';
import { QuickCommandPicker } from './components/QuickCommandPicker';
import { ReplaceTooltip } from './components/ReplaceTooltip';
import { AddAttachmentButton } from 'src/components/FlatAppearance/components/ThreadInputBox/components/AddAttachmentButton';
import { AttachedFileItem } from 'src/components/FlatAppearance/components/ThreadInputBox/components/AttachedFileItem';
import { CapturePhoto } from 'src/components/CapturePhoto';

const MAX_INPUT_ROWS = 4;
const DEFAULT_PLACEHOLDER = 'Ask anything';
const RESPONDING_PLACEHOLDER = 'Ninja responding';
const PROCESSING_PLACEHOLDER = 'Processing request';
const LISTENING_PLACEHOLDER = 'Listening';
const ELLIPSIS_SUFFIX = '...';

interface ThreadInputBoxProps {
  startSpeechRecognizing: () => void;
  stopSpeechRecognizing: () => void;
  unMuteMicrophone: () => void;
}

export const ThreadInputBox = ({
  startSpeechRecognizing,
  stopSpeechRecognizing,
  unMuteMicrophone,
}: ThreadInputBoxProps) => {
  const { threadInputBoxRef } =
    useContext<ForwardRefContextType>(ForwardRefContext);
  const {
    threadInputBoxValue,
    setThreadInputBoxValue,
    threadInputBoxFileError,
  } = useContext<ThreadInputBoxContextType>(ThreadInputBoxContext);
  const { microphoneTriggerRef, recordInProgress, metaHumanTalking } =
    useContext(AudioContext);
  const { isSubmitHappened } = useThreads();
  const { isLandingPage } = useConversation();
  const { chatMode, appUser } = useSession();
  const { onSubmitUserInput } = useSubmitUserInput();

  const { isMobileOrTablet } = useBreakpoint();

  const {
    shouldNotSubmitOnEnter,
    isVisibleContactPicker,
    isVisibleCommandPicker,
    onOpenContactPicker,
    onCloseContactPicker,
    onOpenCommandPicker,
    onCloseCommandPicker,
  } = useInputBoxState();

  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const handleCloseAllPickers = () => {
    onCloseContactPicker();
    onCloseCommandPicker();
  };

  const updateCursorPosition = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    const target = e.currentTarget as HTMLTextAreaElement;
    setCursorPosition(target.selectionStart);
  };

  // managing the behavior of opening or closing quick pickers based on keyboard input.
  const handleCheckKeyCode = ({ key }: KeyboardEvent<HTMLTextAreaElement>) => {
    const beforeCursor = threadInputBoxValue.substring(0, cursorPosition);

    switch (key) {
      case KeyCodes.AT:
        if (!threadInputBoxValue || beforeCursor.at(-1) === KeyCodes.SPACE) {
          onOpenContactPicker();
        }
        break;

      case KeyCodes.SLASH:
        if (!threadInputBoxValue) {
          onOpenCommandPicker();
        }
        break;

      case KeyCodes.BACKSPACE || KeyCodes.DELETE:
        if (!threadInputBoxValue) {
          handleCloseAllPickers();
        }

        if (beforeCursor.at(-1) === KeyCodes.AT) {
          onCloseContactPicker();
        } else if (
          beforeCursor.at(-2) === KeyCodes.AT &&
          (beforeCursor.at(-3) === KeyCodes.SPACE ||
            threadInputBoxValue.length === 2)
        ) {
          onOpenContactPicker();
        }

        if (threadInputBoxValue.at(-1) === KeyCodes.SLASH) {
          onCloseCommandPicker();
        } else if (threadInputBoxValue.at(-2) === KeyCodes.SLASH) {
          onOpenCommandPicker();
        }

        break;

      case KeyCodes.SPACE || KeyCodes.ESCAPE:
        handleCloseAllPickers();
        break;

      default:
        break;
    }
  };

  // Disabling submit if the input box is empty, or if the Stop button is Processing, or if the Avatar is talking, or if Speech-to-text in progress
  const isDisabledSubmitButton =
    !threadInputBoxValue ||
    !!isSubmitHappened ||
    !!metaHumanTalking ||
    !!recordInProgress ||
    !!threadInputBoxFileError;

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    // allows the user to switch to the next line when pressing the Shift and Enter keys
    if (e.key === KeyCodes.ENTER && !e.shiftKey) {
      e.preventDefault();
    }

    // submit input by pressing Enter key if the quick picker is not open and if the input is not disabled
    if (
      e.key === KeyCodes.ENTER &&
      !e.shiftKey &&
      !shouldNotSubmitOnEnter &&
      !isDisabledSubmitButton
    ) {
      onSubmitUserInput(threadInputBoxValue);
      return;
    }

    if (e.key !== KeyCodes.ENTER) {
      handleCheckKeyCode(e);
      return;
    }
  };

  const handleChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = target;
    setThreadInputBoxValue(value);
  };

  const handleBlur = () => {
    handleCloseAllPickers();
  };

  const stopRecording = (skipInterrupt?: boolean) => {
    microphoneTriggerRef?.current?.stopRecording(skipInterrupt);
  };

  const handleClick = () => {
    handleCloseAllPickers();

    if (recordInProgress) {
      stopRecording();
    } else {
      // TODO(olha): workaround until we implement Mute button with avatar signal
      interruptMetahuman(appUser.user_id);
    }
  };

  const isAvatarMode = chatMode === ChatMode.AVATAR;

  const inputValue =
    recordInProgress && threadInputBoxValue
      ? `${threadInputBoxValue} ${ELLIPSIS_SUFFIX}`
      : threadInputBoxValue;

  const placeholder = useMemo(() => {
    if ((isSubmitHappened && isAvatarMode) || metaHumanTalking) {
      return RESPONDING_PLACEHOLDER;
    }

    if (isSubmitHappened) {
      return PROCESSING_PLACEHOLDER;
    }

    if (recordInProgress) {
      return LISTENING_PLACEHOLDER;
    }

    return DEFAULT_PLACEHOLDER;
  }, [recordInProgress, isSubmitHappened, metaHumanTalking, isAvatarMode]);

  return (
    <div
      className={classNames('nj-thread-input-box', {
        'in-landing-page': isLandingPage,
      })}
    >
      {!!threadInputBoxFileError && (
        <span className="nj-thread-input-box--error">
          {threadInputBoxFileError}
        </span>
      )}
      <div
        className={classNames('nj-thread-input-box--container', {
          error: !!threadInputBoxFileError,
        })}
      >
        <AttachedFileItem />
        <div className="nj-thread-input-box--container-row-wrapper">
          <div className="nj-thread-input-box--actions nj-thread-input-box--actions-ltr">
            <AddAttachmentButton />
            <CapturePhoto />
          </div>

          <TextareaAutosize
            data-e2e="thread-input-box"
            ref={threadInputBoxRef}
            value={inputValue}
            placeholder={`${placeholder} ${ELLIPSIS_SUFFIX}`}
            minRows={isMobileOrTablet && isLandingPage ? 3 : 1}
            maxRows={MAX_INPUT_ROWS}
            className="nj-thread-input-box--text-area"
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onSelect={updateCursorPosition}
            onBlur={handleBlur}
            onClick={handleClick}
          />

          <div className="nj-thread-input-box--actions">
            <MicrophoneTrigger
              startSpeechRecognizing={startSpeechRecognizing}
              stopSpeechRecognizing={stopSpeechRecognizing}
              unMuteMicrophone={unMuteMicrophone}
            />

            <SubmitTrigger isSubmitDisabled={isDisabledSubmitButton} />
          </div>

          <QuickContactPicker
            isOpen={isVisibleContactPicker}
            cursorPosition={cursorPosition}
            onClose={onCloseContactPicker}
            setCursorPosition={setCursorPosition}
          />

          <QuickCommandPicker
            isOpen={isVisibleCommandPicker}
            onClose={onCloseCommandPicker}
            setCursorPosition={setCursorPosition}
          />

          <ReplaceTooltip />
        </div>
      </div>
    </div>
  );
};
