import { useSession } from 'src/hooks';
import { useGetFileURLQuery } from 'src/store/services';
import { Spinner } from 'src/components/Loading';
import { SVG_SIZE_M } from 'src/constants';
import { Warning } from '@phosphor-icons/react';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { ModalGalleryType } from 'src/types';

type FileImageThumbnailProp = {
  fileName: string;
  originalFileName?: string;
  url?: string;
};
export const FileImageThumbnail = ({
  fileName,
  originalFileName,
  url,
}: FileImageThumbnailProp) => {
  const { onChangeModalGalleryData } = useContext(SessionContext);
  const { appUser } = useSession();
  const { data, isError } = useGetFileURLQuery(
    { user_id: appUser.user_id, file_name: fileName },
    {
      skip: !fileName || !appUser.user_id || !!url,
    },
  );

  if (!data?.url && !url) {
    return (
      <div className="nj-thread-input-box--file-image-wrapper">
        {isError ? (
          <Warning size={SVG_SIZE_M} weight="fill" className="error-icon" />
        ) : (
          <Spinner size={SVG_SIZE_M} inline />
        )}
      </div>
    );
  }

  const handleImageClick = () => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.IMAGES_VIDEOS,
      [
        {
          url: data?.url || url || '',
          title: originalFileName || fileName,
          height: 400,
          width: 400,
          source: '',
        },
      ],
      0,
    );
  };

  return (
    <img
      src={data?.url || url || ''}
      alt={fileName}
      className="nj-thread-input-box--file-image"
      onClick={handleImageClick}
    />
  );
};
