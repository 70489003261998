import { useTaskById } from 'src/hooks';
import { TaskState } from 'src/types';
import { StopTaskButton } from 'src/components/StopTaskButton';
import { CloseTaskButton } from 'src/components/FlatAppearance/components/CloseTaskButton';
import { TaskViewMenu } from './components/TaskViewMenu';
import { ReportIssueToZendeskButton } from './components/ReportIssueToZendeskButton';
import { Prohibit } from '@phosphor-icons/react';

interface TaskViewHeaderProps {
  onCloseTaskView: () => void;
}

export const TaskViewHeader = ({ onCloseTaskView }: TaskViewHeaderProps) => {
  const { currentTaskData } = useTaskById();

  if (!currentTaskData || !currentTaskData?.task_id) {
    return null;
  }

  const taskName = currentTaskData?.task_hash?.replace('#', '') || '';
  const stopTaskButtonLabel = (
    <>
      <Prohibit size={20} />
      <span>Stop task</span>
    </>
  );

  return (
    <div className="nj-task-view-header">
      <div className="nj-task-view-header--actions">
        <h6 className="nj-task-view-header--actions-title ellipsis">
          {taskName}
        </h6>

        <StopTaskButton
          taskId={currentTaskData.task_id}
          taskState={currentTaskData.state || TaskState.IN_PROGRESS}
          taskHash={currentTaskData?.task_hash}
          className="nj-task-view-header--stop-botton"
          buttonName={stopTaskButtonLabel}
        />

        <ReportIssueToZendeskButton
          tags={['task', 'support']}
          taskId={currentTaskData?.task_id}
        />

        <TaskViewMenu
          taskId={currentTaskData?.task_id}
          taskFeedback={currentTaskData?.feedback}
          conversationId={currentTaskData?.parent_conversation_id || ''}
          messageId={currentTaskData?.task_creation_card_message_id || ''}
          taskSkill={currentTaskData?.skill}
        />

        <CloseTaskButton onClick={onCloseTaskView} />
      </div>
    </div>
  );
};
