import {
  FileCsv,
  FileDoc,
  FilePdf,
  FileText,
  FileTxt,
  FileXls,
  Warning,
  XCircle,
  Image,
  X,
} from '@phosphor-icons/react';
import { useContext, useEffect, useState } from 'react';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { Spinner } from 'src/components/Loading';
import { useDeleteFileMutation } from 'src/store/services';
import { useSession } from 'src/hooks';
import { FILE_REGEX, SVG_SIZE_M } from 'src/constants';
import log from 'src/utils/logger';
import { ModalGalleryType } from 'src/types';
import SessionContext from 'src/contexts/SessionContext';

export const AttachedFileItem = () => {
  const {
    threadInputBoxFile,
    deleteAttachment,
    attachmentLoading,
    threadInputBoxFileError,
    fileDataToSend,
  } = useContext<ThreadInputBoxContextType>(ThreadInputBoxContext);
  const { onChangeModalGalleryData } = useContext(SessionContext);
  const { appUser } = useSession();
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);

  const [deleteFileFromS3] = useDeleteFileMutation();

  const isImageFile =
    threadInputBoxFile &&
    (threadInputBoxFile.type === 'image/png' ||
      threadInputBoxFile.type === 'image/jpeg' ||
      threadInputBoxFile.type === 'image/jpg');

  useEffect(() => {
    if (isImageFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader?.result);
      };
      reader.readAsDataURL(threadInputBoxFile);
    }
  }, [threadInputBoxFile, isImageFile, imageUrl]);

  const renderExceptionIcon = (type: string) => {
    switch (type) {
      case 'application/pdf':
        return <FilePdf size={SVG_SIZE_M} />;
      case 'text/plain':
        return <FileTxt size={SVG_SIZE_M} />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <FileDoc size={SVG_SIZE_M} />;
      case 'text/csv':
        return <FileCsv size={SVG_SIZE_M} />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <FileXls size={SVG_SIZE_M} />;
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return <Image size={SVG_SIZE_M} />;
      case 'text/tab-separated-values':
      case 'application/rtf':
      default:
        return <FileText size={SVG_SIZE_M} />;
    }
  };

  const handleDeleteAttachment = async () => {
    const isFileRemovable = FILE_REGEX.test(fileDataToSend);
    deleteAttachment();
    try {
      if (isFileRemovable) {
        const file_name =
          fileDataToSend.match(FILE_REGEX) !== null
            ? (fileDataToSend.match(FILE_REGEX) || [])[2].replaceAll('"', '')
            : '';
        if (file_name.length > 0) {
          await deleteFileFromS3({
            user_id: appUser.user_id,
            file_name,
          });
        }
      }
    } catch (error) {
      log.error(error);
    }
  };

  const handleImageClick = (url: string, fileName: string) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.IMAGES_VIDEOS,
      [
        {
          url: url,
          title: fileName,
          height: 400,
          width: 400,
          source: '',
        },
      ],
      0,
    );
  };

  if (!threadInputBoxFile) {
    return null;
  }

  if (isImageFile && imageUrl) {
    return (
      <div className="nj-thread-input-box--file-image-wrapper">
        {attachmentLoading ? (
          <Spinner size={SVG_SIZE_M} inline />
        ) : !!threadInputBoxFileError ? (
          <Warning size={SVG_SIZE_M} weight="fill" className="error-icon" />
        ) : (
          <img
            src={imageUrl as string}
            alt={threadInputBoxFile?.name}
            className="nj-thread-input-box--file-image"
            onClick={() =>
              handleImageClick(imageUrl as string, threadInputBoxFile?.name)
            }
          />
        )}
        <button
          type="button"
          onClick={handleDeleteAttachment}
          className="nj-thread-input-box--file-label-close"
        >
          <X size={12} weight="bold" />
        </button>
      </div>
    );
  }

  return (
    <div className="nj-thread-input-box--file-label">
      <div className="nj-thread-input-box--file-label-text">
        {attachmentLoading ? (
          <Spinner size={SVG_SIZE_M} inline />
        ) : !!threadInputBoxFileError ? (
          <Warning size={SVG_SIZE_M} weight="fill" className="error-icon" />
        ) : (
          renderExceptionIcon(threadInputBoxFile.type)
        )}
        <span className="nj-thread-input-box--file-name ellipsis">
          {threadInputBoxFile?.name}
        </span>
      </div>
      <button
        type="button"
        onClick={handleDeleteAttachment}
        className="nj-thread-input-box--file-label-close"
      >
        <XCircle size={SVG_SIZE_M} weight="fill" />
      </button>
    </div>
  );
};
