import styles from './WelcomeProTrialModal.module.scss';
import { Modal } from 'src/components/Modal';
import { lazy, Suspense, useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { PRO_TRIAL_FEATURES, SVG_SIZE_L, SVG_SIZE_M } from 'src/constants';
import { ArrowSquareOut, XCircle } from '@phosphor-icons/react';
import {
  DEFAULT_NINJATECH_LINK,
  WELCOME_PRO_TRIAL_IMAGE,
} from 'src/constants/externalLinks';
import { PlanOption } from 'src/types';
import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { AnthropicLogo } from 'src/images/logos/anthropic-logo';
import { Icon } from 'src/components/Icon';
import { useSession, useUserData } from 'src/hooks';

const LazyFeatureItem = lazy(() =>
  import('../../components/UpgradeModal/components/FeatureItem').then(
    (module) => ({ default: module.FeatureItem }),
  ),
);

const EXTERNAL_MODELS_LIST = [
  {
    title: 'OpenAI GPT-4o',
    icon: <ChatGPTLogo size={SVG_SIZE_M} />,
  },
  {
    title: 'Anthropic 3.5 Sonnet',
    icon: <AnthropicLogo size={SVG_SIZE_M} />,
  },
  {
    title: 'Google Gemini 1.5 Pro',
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE_M} />,
  },
  {
    title: 'Stable Diffusion',
    icon: <Icon type="bedrockStableLogo" size={SVG_SIZE_M} />,
  },
  {
    title: 'Mistral Large',
    icon: <Icon type="mistralLogo" size={SVG_SIZE_M} />,
  },
];

export const WelcomeProTrialModal = () => {
  const { appUser } = useSession();
  const { isVisibleWelcomeModal, onToggleWelcomeModal } =
    useContext(SessionContext);
  const { updateUserData } = useUserData();

  const handleCloseModal = () => {
    if (appUser?.user_id && !appUser?.pro_trial_onboarded) {
      updateUserData(
        {
          pro_trial_onboarded: true,
        },
        false,
      );
    }
    onToggleWelcomeModal(false);
  };

  return (
    <Modal
      open={isVisibleWelcomeModal}
      onClose={onToggleWelcomeModal}
      className={styles.welcomeProTrialModal}
      overlayClassName={styles.overlay}
      showCloseIcon={false}
      blockScroll={false}
    >
      <div className={styles.root}>
        <button
          type="button"
          className={styles.buttonClose}
          onClick={handleCloseModal}
        >
          <XCircle size={SVG_SIZE_L} weight="fill" />
        </button>
        <img
          src={WELCOME_PRO_TRIAL_IMAGE}
          alt="Ninja"
          className={styles.image}
        />
        <div className={styles.main}>
          <h2 className={styles.title}>Welcome to Ninja PRO Trial</h2>
          <p className={styles.description}>
            Enjoy 7 days of unlimited access to your SuperGPT Ninja and receive
            $5 in credits for external model usage
          </p>
          <div className={styles.featuresBlock}>
            <h4 className={styles.subtitle}>
              Multi-task with AI agents & seamlessly access 24+ premium LLMs (up
              to $75/month value)
            </h4>
            <div className={styles.featuresWrapper}>
              <ul className={styles.leftSideFeatures}>
                {PRO_TRIAL_FEATURES.map(
                  ({
                    title,
                    withBorder,
                    link,
                    isExternalModels,
                  }: PlanOption) => (
                    <Suspense key={title}>
                      <LazyFeatureItem
                        title={title}
                        withBorder={withBorder}
                        type="pro"
                        link={link}
                        isExternalModels={isExternalModels}
                      />
                    </Suspense>
                  ),
                )}
              </ul>
              <ul className={styles.rightSideFeatures}>
                {EXTERNAL_MODELS_LIST.map((item) => (
                  <li className={styles.listItem} key={item.title}>
                    {item.icon}
                    <span className={styles.text}>{item.title}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <a
            href={DEFAULT_NINJATECH_LINK}
            target="_blank"
            rel="noreferrer"
            className={styles.learnMoreLink}
          >
            <>
              <ArrowSquareOut size={SVG_SIZE_M} />
              <span>Learn more</span>
            </>
          </a>
          <button
            type="button"
            className={styles.button}
            onClick={handleCloseModal}
          >
            Let's start
          </button>
        </div>
      </div>
    </Modal>
  );
};
