import { env } from 'src/env';
import packageJson from '../../package.json';
import { AppUser, ConversationRole, UserType } from 'src/types';

export const E_ASSISTANT = env.REACT_APP_E_ASSISTANT || 'EASSISTANT';

export const DEFAULT_PORTAL = 'manage-tasks';

export const DEFAULT_OPTION = 'default';

export const VERSION_NUMBER = packageJson.version;

// todo: play with these settings if they work on all env after push
export const APP_ENV =
  env.REACT_APP_ENVIRONMENT || env.NODE_ENV || 'development';

export const DEFAULT_AGENT: AppUser = {
  user_id: E_ASSISTANT,
  first_name: 'Ninja',
  last_name: '',
  user_type: UserType.AGENT,
  role: ConversationRole.AGENT,
  email: 'ninja@ninjatech.ai',
  company: 'NinjaTech AI',
  avatar: 'avatarAtlas',
};

export const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const SCHEDULE_ACTION_CREATED = 'created';
export const SCHEDULE_ACTION_DELETED = 'deleted';

export const REGEX_EMAIL_PATTERN =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const IS_USER_PASSED_PASSWORD_PAGE = 'passed_pass_page';

export const GUEST_ACCESS_TOKEN = 'guest_access_token';

export const CALENDAR_URL = 'https://www.googleapis.com/auth/calendar';

// This permissions is only needed for workspace user as opposed to just normal google users,
// i.e with a @gmail.com domain
export const DIRECTORY_URL =
  'https://www.googleapis.com/auth/directory.readonly';

export const CONTACTS_URL = 'https://www.googleapis.com/auth/contacts.readonly';

export const PRODUCTION_ENVIRONMENT = 'production';

export const ONBOARDING_HINT_STEP = 'onboarding_hint_step';
export const SHOW_DEEP_RESEARCH_HINT = 'show_deep_research_hint';

export const SHOWN_ONBOARDING_MODAL = 'shown_onboarding_modal';

export const LOCAL_STORAGE_VALUE = '1';
