import { useContext } from 'react';
import { Archive, X } from '@phosphor-icons/react';
import { Modal } from 'react-responsive-modal';
import SessionContext from 'src/contexts/SessionContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useVisible, useConversation } from 'src/hooks';
import log from 'src/utils/logger';
import { Button } from 'src/components/Button';
import styles from 'src/pages/ManageAccountPage/components/General/General.module.scss';

const SVG_SIZE = 24;

export const ArchiveAll = () => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { setChatsFilter } = useContext(SessionContext);

  const { isVisible, handleVisibilitySet, handleVisibilityRemove } =
    useVisible();

  const { archiveAllConversations, isArchiveAllLoading } = useConversation();

  const handleArchivingAll = async () => {
    try {
      await archiveAllConversations();

      setChatsFilter();
      handleVisibilityRemove();
    } catch (error: unknown) {
      log.error(error);
    }
  };

  return (
    <div className={styles.dataControlContent}>
      <p className={styles.dataControlSubtitle}>
        Archive all available chats & tasks
      </p>
      <button
        onClick={handleVisibilitySet}
        className="nj-settings-tab-content--archive-button"
      >
        <Archive size={SVG_SIZE} />
      </button>

      <Modal
        open={isVisible}
        onClose={handleVisibilityRemove}
        container={rootContainerRef?.current || document.body}
        classNames={{
          root: 'nj-modal--root',
          overlay: 'nj-modal--overlay',
          modal: 'nj-modal--access',
        }}
        showCloseIcon={!isArchiveAllLoading}
        closeIcon={<X size={SVG_SIZE} />}
        center
        closeOnOverlayClick={false}
      >
        <div className="nj-modal--access--wrapper">
          <p className="nj-modal--access--title">Archive Tasks</p>

          {isArchiveAllLoading ? (
            <p className="nj-archiving--loading">
              <span className="nj-archiving--progress-icon" />
              <span className="nj-archiving--loading-title">
                Archive all available chats &amp; tasks
              </span>
            </p>
          ) : (
            <>
              <p className="nj-settings-tab-content--access-description">
                All chats and tasks that are available up to today will be
                archived.
              </p>

              <p className="nj-settings-tab-content--access-description">
                Any in-progress tasks will not be archived.
              </p>

              <p className="nj-settings-tab-content--access-description">
                To proceed, click "Confirm archive" below.
              </p>

              <div className="nj-modal--access--action-wrapper">
                <Button
                  type="reset"
                  onClick={handleVisibilityRemove}
                  className="nj-settings-tab-content--access-button outlined active"
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  className="nj-settings-tab-content--access-button fill"
                  onClick={handleArchivingAll}
                >
                  Confirm archive
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
