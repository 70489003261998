/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserTier {
  OPEN = 'open',
  FREE = 'free',
  PAID = 'paid',
  PRO_TRIAL = 'pro_trial',
  PRO_ZERO = 'pro_zero',
  TESTING = 'testing',
  INTERNAL = 'internal',
  LOAD_TEST = 'load_test',
}
