import { useRef, useState } from 'react';
import Webcam, { WebcamProps } from 'react-webcam';
import { Camera } from '@phosphor-icons/react';
import { isDesktop } from 'react-device-detect';
import { SVG_SIZE_L } from 'src/constants';
import { Modal } from 'src/components/Modal';
import { useVisible, useAttachFile } from 'src/hooks';
import styles from './CapturePhoto.module.scss';
import { convertBase64ToFile } from 'src/utils';
import { CaptureActions } from './components/CaptureActions';
import { ImageEffectTransition } from 'src/animations/ImageEffectTransition';

const videoConstraints: WebcamProps['videoConstraints'] = {
  height: 480,
  width: 640,
};

export const CapturePhoto = () => {
  const { addFile } = useAttachFile();

  const {
    isVisible: isModalVisible,
    handleVisibilityRemove: onModalClose,
    handleVisibilitySet: onModalOpen,
  } = useVisible();

  const {
    isVisible: isConfirmActionsVisible,
    handleVisibilityRemove: onConfirmActionsHide,
    handleVisibilitySet: onConfirmActionsShow,
  } = useVisible();

  const {
    isVisible: isCaptureVisible,
    handleVisibilityRemove: onCaptureHide,
    handleVisibilitySet: onCaptureShow,
  } = useVisible(true);

  const webcamRef = useRef<Webcam | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const [imgSrc, setImgSrc] = useState<string | null>(null);

  const handleCaptureClick = () => {
    onCaptureHide();

    const screenshot = webcamRef.current?.getScreenshot();

    if (screenshot) {
      setImgSrc(screenshot);
    }
  };

  const handleModalClose = () => {
    onModalClose();
    webcamRef.current = null;
    setImgSrc(null);
    onCaptureShow();
    onConfirmActionsHide();
  };

  const handleRetakeClick = () => {
    setImgSrc(null);
    onConfirmActionsHide();
  };

  const handleUpload = () => {
    if (!imgSrc) {
      return;
    }

    const file = convertBase64ToFile(imgSrc);

    addFile(file);
    handleModalClose();
  };

  if (!isDesktop) {
    return null;
  }

  return (
    <div className={styles.root}>
      <button className={styles.triggerButton} onClick={onModalOpen}>
        <Camera size={SVG_SIZE_L} />
      </button>

      <Modal
        className={styles.modal}
        open={isModalVisible}
        onClose={handleModalClose}
      >
        <div className={styles.modalBody}>
          <p className={styles.title}>Take a photo</p>

          <div className={styles.mediaWrapper}>
            <ImageEffectTransition inProp={!!imgSrc} nodeRef={imageRef}>
              {imgSrc ? (
                <img ref={imageRef} src={imgSrc || ''} alt="user-photo" />
              ) : (
                <></>
              )}
            </ImageEffectTransition>

            {!imgSrc && isModalVisible && (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            )}
          </div>

          <CaptureActions
            isCaptureVisible={isCaptureVisible}
            isConfirmActionsVisible={isConfirmActionsVisible}
            onCaptureShow={onCaptureShow}
            onConfirmActionsShow={onConfirmActionsShow}
            onCaptureClick={handleCaptureClick}
            onRetakeClick={handleRetakeClick}
            onUploadClick={handleUpload}
          />
        </div>
      </Modal>
    </div>
  );
};
