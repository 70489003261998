import { useBreakpoint } from 'src/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { Header } from 'src/pages/AuthPages/components/Header';
import logoDesktop from 'src/images/login/atlas_oops_desktop.png';
import { ArrowLeft } from '@phosphor-icons/react';
import { DEFAULT_NINJA_BETA_LINK } from 'src/constants/externalLinks';

/**
 * @deprecated
 */
export const ErrorLoginPage = () => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();

  const rightSideComponent = (
    <div className="nj-auth-header-right-side">
      {!isMobile && <span>Learn more about</span>}
      {isMobile ? (
        <Link
          to="/login"
          className="nj-button nj-auth-sign-in-up-button nj-auth-login-button"
        >
          Log in
        </Link>
      ) : (
        <a
          href={DEFAULT_NINJA_BETA_LINK}
          target="_blank"
          rel="noreferrer"
          className="nj-button nj-auth-link"
        >
          Ninja Tech AI
        </a>
      )}
    </div>
  );

  return (
    <div className="nj-auth-layout">
      <Header rightSide={rightSideComponent} />
      <main className="nj-auth-login-error-content">
        <h3 className="nj-auth-header-secondary nj-auth-login-error-title">
          The email account is not currently on Beta Waitlist
        </h3>
        <h5 className="nj-auth-login-error-subtitle">
          Our beta program is limited to our valued waitlist members.
        </h5>
        <div className="nj-auth-login-error-blocks-wrapper">
          <div className="nj-auth-login-error-block">
            <span>
              Please use the email address you signed up with to gain access.
            </span>
            <button
              className="nj-button nj-go-to-set-up-button"
              onClick={() => navigate('/sign-up')}
            >
              <ArrowLeft size={20} /> Set up account
            </button>
          </div>
          <div className="nj-auth-login-error-block">
            <span>
              <b>Not on the waitlist?</b>
              <br /> Join our waitlist and we'll contact you directly when we're
              ready to expand.
            </span>
            <a
              href={DEFAULT_NINJA_BETA_LINK}
              target="_blank"
              rel="noreferrer"
              className="nj-button nj-join-button"
            >
              Join Beta
            </a>
          </div>
        </div>
      </main>
      {!isMobile && (
        <img
          className="nj-auth-background-image"
          src={logoDesktop}
          alt="Ninja"
        />
      )}
    </div>
  );
};
