import { useFormContext } from 'react-hook-form';
import { useSession, useAvatarPreferences } from 'src/hooks';
import { useGenerateOfflineVideoMutation } from 'src/store/services';
import { getOfflineRenderRequestData, pause } from 'src/utils';
import { ChatModeSidePanel, MovieStudioFormData } from 'src/types';
import { SuccessNotification } from './components/SuccessNotification';
import { PreviewPlayButton } from './components/PreviewPlayButton';
import { ScriptForm } from './components/ScriptForm';
import { Button } from 'src/components/Button';
import { toast } from 'react-toastify';

/**
 * @deprecated
 */
export const MovieStudioSection = () => {
  const { appUser, updateChatModeSidePanel } = useSession();

  const {
    avatarCameraIndex,
    avatarVoiceID,
    avatarAudioLocale,
    avatarBackgroundHexColor,
    avatarEnvironment,
    selectedAvatarName,
  } = useAvatarPreferences();

  const [generateOfflineVideo, { isLoading: isLoadingGenerate }] =
    useGenerateOfflineVideoMutation();

  const { handleSubmit, reset } =
    useFormContext<Partial<MovieStudioFormData>>();

  const handleSubmitForm = async (data: Partial<MovieStudioFormData>) => {
    if (isLoadingGenerate) {
      return;
    }
    try {
      const body = await getOfflineRenderRequestData(
        {
          ...data,
          cameraIndex: avatarCameraIndex,
          voiceID: avatarVoiceID,
          AudioLang: avatarAudioLocale,
          backgroundColor: avatarBackgroundHexColor,
          environment: avatarEnvironment,
          avatar: selectedAvatarName,
        },
        appUser.email,
      );

      await generateOfflineVideo(body).unwrap();

      toast(<SuccessNotification videoTitle={data.title || ''} />);

      pause(500).then(() => {
        updateChatModeSidePanel(ChatModeSidePanel.SAVED_CONTENT);
        reset();
      });
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
    <form
      className="nj-avatar-recording nj-section--main-container"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <ScriptForm />

      <div className="nj-avatar-recording--submit-actions">
        <PreviewPlayButton />

        <Button
          disabled={isLoadingGenerate}
          type="submit"
          className="nj-avatar-recording--generate-button"
        >
          Generate
        </Button>
      </div>
    </form>
  );
};
