import { ImageCard } from 'src/types/models/ImageCard';
import { useContext, useMemo } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { ModalGalleryType } from 'src/types';
import { prepareGeneratedImages } from 'src/utils';
import './ThreadImageGenerationCard.scss';
import { ResearchMediaThumbnail } from '../ThreadResearchCard/components/ResearchMediaThumbnail';

interface ThreadImageGenerationCardProps {
  imageCard: ImageCard;
}

export const ThreadImageGenerationCard = ({
  imageCard,
}: ThreadImageGenerationCardProps) => {
  const { images } = imageCard;
  const { onChangeModalGalleryData } = useContext(SessionContext);
  const imagesArray = useMemo(() => {
    if (!!images) {
      return prepareGeneratedImages(images);
    }
    return [];
  }, [images]);

  const handleSetCurrentMedia = (index: number) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.GENERATED_IMAGES,
      imagesArray,
      index,
      imageCard.task_id,
    );
  };

  return (
    <div className="nj-thread-research-media nj-thread-image-generation-media-wrapper">
      <h6 className="nj-thread-research-card--summary-title">
        Image generator
      </h6>

      <div className="nj-thread-image-generation-media-wrapper--image-list">
        {imagesArray.map((item, index) => (
          <ResearchMediaThumbnail
            key={item.id}
            thumbnail={item.url}
            title={item.title}
            isVideo={false}
            onClick={() => handleSetCurrentMedia(index)}
            icon={item.icon || undefined}
            status={item.status}
          />
        ))}
      </div>
    </div>
  );
};
