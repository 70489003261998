import { Info, Warning } from '@phosphor-icons/react';
import { useSession } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { SVG_SIZE_L } from 'src/constants';
import { useGetUserTaskQuotaInfoQuery } from 'src/store/services';
import { Link } from 'react-router-dom';

export const LowTasksBanner = () => {
  const { appUser, isOpenTier, isFreeTier } = useSession();
  const { data: quotaData } = useGetUserTaskQuotaInfoQuery(
    {
      user_id: appUser.user_id,
    },
    {
      skip: !appUser.user_id,
    },
  );

  const { onToggleVisibleUpgradeModal } = useContext(SessionContext);

  const handleUpgradeClick = () => {
    onToggleVisibleUpgradeModal(true);
  };

  const tasksCount =
    quotaData && quotaData?.count !== null ? quotaData?.count : 0;

  return (
    <div className="nj-common-banner">
      <div className="nj-common-banner--left-side-wrapper">
        {tasksCount > 0 ? (
          <Info size={SVG_SIZE_L} weight="fill" />
        ) : (
          <Warning size={SVG_SIZE_L} weight="fill" />
        )}
        <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
          <h5 className="nj-common-banner--title">
            {tasksCount > 0
              ? `${tasksCount} tasks left`
              : 'You have reached daily task limit'}
          </h5>
        </div>
      </div>
      <div className="nj-common-banner--right-side-wrapper">
        {isOpenTier && (
          <Link
            to="/sign-up"
            className="nj-tier-auth-footer-button nj-button-try-pro"
          >
            Try PRO for free
          </Link>
        )}
        {isFreeTier && (
          <button
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleUpgradeClick}
          >
            Upgrade
          </button>
        )}
      </div>
    </div>
  );
};
