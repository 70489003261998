import { lazy, Suspense, useContext, useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { List } from '@phosphor-icons/react';
import SessionContext from 'src/contexts/SessionContext';
import { TaskState, GTMEvent } from 'src/types';
import { useOutsideClick, useSession, useUserData } from 'src/hooks';
import { useTaskById } from 'src/hooks';
import { sendGTMEvent } from 'src/utils';
import { ANIMATION_TIMEOUT, SHOW_DEEP_RESEARCH_HINT } from 'src/constants';
import { Icon } from 'src/components/Icon';
import './ViewTaskButton.scss';

const SVG_SIZE = 16;

interface GoToTaskButtonProps {
  taskId: string;
  taskState: TaskState;
  hasDeepResearchSection: boolean;
}

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const ViewTaskButton = ({
  taskId,
  taskState,
  hasDeepResearchSection,
}: GoToTaskButtonProps) => {
  const isShowDeepResearchFromLS = localStorage.getItem(
    SHOW_DEEP_RESEARCH_HINT,
  );
  const { updateUserData } = useUserData();
  const containerRef = useRef<HTMLDivElement>(null);
  const { onToggleVisibleTaskViewModal } = useContext(SessionContext);
  const { appUser } = useSession();

  const handleOutsideOnboardingTooltipClick = async () => {
    if (
      hasDeepResearchSection &&
      (!appUser?.deep_research_onboarded || !isShowDeepResearchFromLS)
    ) {
      await updateUserData(
        {
          deep_research_onboarded: true,
        },
        false,
      );
      localStorage.setItem(SHOW_DEEP_RESEARCH_HINT, '1');
    }
  };

  useOutsideClick(containerRef, handleOutsideOnboardingTooltipClick);

  const { setCurrentTaskId } = useTaskById();

  const gradientRef = useRef<HTMLDivElement>(null);

  const isShadowAnimated = useMemo(
    () => taskState === TaskState.IN_PROGRESS,
    [taskState],
  );

  const handleClick = () => {
    setCurrentTaskId(taskId);
    onToggleVisibleTaskViewModal(true);
    sendGTMEvent(GTMEvent.TASK_VIEW_OPENING, {
      task_id: taskId,
      task_state: taskState,
    });
  };

  return (
    <div
      className={classNames('nj-thread-message--elastic-gradient-button', {
        'is-done': !isShadowAnimated,
        'is-warning': taskState === TaskState.BLOCKED,
      })}
      role="button"
      tabIndex={0}
    >
      <CSSTransition
        in={isShadowAnimated}
        timeout={ANIMATION_TIMEOUT}
        classNames="nj-animate-opacity-pulsation"
        unmountOnExit
        nodeRef={gradientRef}
      >
        <div
          ref={gradientRef}
          className="nj-thread-message--elastic-gradient-shadow"
        />
      </CSSTransition>

      <div className="nj-thread-message--elastic-gradient-border" />

      <button
        data-e2e="open-task-from-bubble-button"
        onClick={handleClick}
        className="nj-thread-message--action-round-button"
        tabIndex={-1}
        data-tooltip-id="task-onboarding-tooltip"
        data-tooltip-place="top-start"
      >
        {isShadowAnimated ? (
          <Icon type="listGradient" />
        ) : (
          <List
            className="nj-thread-message--action-round-button-icon"
            size={SVG_SIZE}
          />
        )}
      </button>

      <div ref={containerRef}>
        <Suspense>
          <LazyReactTooltip
            id="task-onboarding-tooltip"
            noArrow={true}
            className="nj-onboarding-bubble-tooltip task"
            isOpen={
              hasDeepResearchSection &&
              !appUser?.deep_research_onboarded &&
              !isShowDeepResearchFromLS
            }
          >
            <span>
              View the dynamic research plan and sources behind Deep Research
            </span>
          </LazyReactTooltip>
        </Suspense>
      </div>
    </div>
  );
};
