import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_CHAT_ID, TEMPORARY_CONVERSATION_ID } from 'src/constants';
import {
  useConversation,
  useQueryParams,
  useSession,
  useThreads,
} from 'src/hooks';
import { useAppDispatch } from 'src/store';
import AudioContext from 'src/contexts/AudioContext';
import { useSendMessagePDUMutation } from 'src/store/services';
import {
  addConversation,
  addMessageToConversation,
  replaceConversationChatTemporaryMessageId,
} from 'src/store/updateQueries';
import { Conversation, GTMEvent, isMessage, Message } from 'src/types';
import {
  convertConversationIdToSlug,
  sendGTMEvent,
  toPDUMessage,
} from 'src/utils';
import {
  performanceMarkerEnd,
  performanceMarkerStart,
} from 'src/utils/performanceMarkers';
import log from 'src/utils/logger';
import { useContext } from 'react';

// TODO(olha): move to the App all dayjs.extend()
dayjs.extend(utc);

export const useFetchUserInput = () => {
  const dispatch = useAppDispatch();

  const { microphoneTriggerRef } = useContext(AudioContext);

  const [sendPDUMessage] = useSendMessagePDUMutation();

  const {
    appUser: { user_id },
  } = useSession();
  const { setSearchParams } = useQueryParams();
  const { updateThreadStatus } = useThreads();
  const { currentConversationId } = useConversation();

  const addMessageToThread = async (message: Message) => {
    const { conversation_id, ...rest } = message;

    const apiMessage = {
      conversation_id:
        conversation_id === DEFAULT_CHAT_ID ? '' : conversation_id,
      ...rest,
    };

    const envelope = toPDUMessage(JSON.stringify(apiMessage), user_id);

    const timestamp = dayjs().unix();
    const temporaryMessageId = `new-message-${timestamp}`;
    const temporaryConversationId = `${TEMPORARY_CONVERSATION_ID}-${timestamp}`;

    const newMessage: Message = {
      ...message,
      message_id: temporaryMessageId,
    };

    const newConversation: Conversation = {
      user_id,
      conversation_id: temporaryConversationId,
      conversation_hash: '#new-chat',
      messages: [newMessage],
    };

    const newStatusRegistry = {
      ignoreMessages: false,
      isSubmitHappened: true,
    };

    if (currentConversationId === DEFAULT_CHAT_ID) {
      dispatch(addConversation(newConversation));
      setSearchParams(
        {
          conv: temporaryConversationId,
        },
        { replace: true },
      );
      updateThreadStatus({
        threadId: temporaryConversationId,
        statusRegistry: newStatusRegistry,
      });

      microphoneTriggerRef?.current?.stopRecording(true);
    } else {
      dispatch(addMessageToConversation(newMessage));
      updateThreadStatus({
        threadId: currentConversationId,
        statusRegistry: newStatusRegistry,
      });
    }

    try {
      const result = await sendPDUMessage({
        message: envelope,
        apiContext: {
          conversation_id: apiMessage.conversation_id,
        },
      }).unwrap();

      const messageFromPDU = JSON.parse(result.payload);

      if (!isMessage(messageFromPDU)) {
        log.error(`Incorrect data type for socket ${result.payload}`);
        return;
      }

      if (
        currentConversationId === DEFAULT_CHAT_ID &&
        messageFromPDU.conversation_id
      ) {
        const slug = convertConversationIdToSlug(
          messageFromPDU.conversation_id,
        );
        setSearchParams({ conv: slug }, { replace: true });
        updateThreadStatus({
          threadId: messageFromPDU.conversation_id,
          statusRegistry: newStatusRegistry,
        });
      }
      sendGTMEvent(GTMEvent.USER_SENT_QUERY, {
        message_id: messageFromPDU.message_id,
      });

      dispatch(
        replaceConversationChatTemporaryMessageId({
          temporaryMessageId,
          message: messageFromPDU,
        }),
      );
    } catch (error) {
      log.error('Request error:', error);
      updateThreadStatus({
        threadId: currentConversationId,
        statusRegistry: { ignoreMessages: false, isSubmitHappened: false },
      });
    }
  };

  const fetchAiData = async (newMessage: Message) => {
    performanceMarkerStart('ce-response-time');
    await addMessageToThread(newMessage);
    performanceMarkerEnd('ce-response-time', undefined);
  };

  return { fetchAiData };
};
