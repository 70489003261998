import { ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import classNames from 'classnames';
import { FeedbackType, MessageType } from 'src/types';
import { Feedback } from 'src/types/models/Feedback';
import { useFeedback } from 'src/hooks/useFeedback';
import { useCollapse } from 'react-collapsed';

type GetToggleProps = ReturnType<typeof useCollapse>['getToggleProps'];

const SVG_SIZE = 20;

interface MessageThumbsButtonsProps {
  taskId?: string;
  messageId?: string;
  conversationId?: string;
  feedback?: Feedback;
  withTitle?: boolean;
  disabled?: boolean;
  messageType?: MessageType;
  onToggleNegativeFeedbackForm: GetToggleProps;
  onCloseRedoSkillActions: () => void;
  onCloseRedoWithPersona: () => void;
  onCloseNegativeFeedbackForm: () => void;
  isShowNegativeFeedbackForm?: boolean;
}

export const MessageThumbsButtons = ({
  taskId,
  messageId,
  conversationId,
  feedback,
  withTitle,
  disabled,
  onToggleNegativeFeedbackForm,
  onCloseRedoSkillActions,
  onCloseRedoWithPersona,
  onCloseNegativeFeedbackForm,
  isShowNegativeFeedbackForm = false,
}: MessageThumbsButtonsProps) => {
  const { onThumbsClick } = useFeedback({
    messageId: messageId || '',
    conversationId: conversationId || '',
    onToggleNegativeFeedbackForm,
    isShowNegativeFeedbackForm,
    feedback,
    taskId,
  });

  const handleNegativeThumbClick = () => {
    onCloseRedoSkillActions();
    onCloseRedoWithPersona();

    onThumbsClick(FeedbackType.NEGATIVE);
  };

  const handlePositiveThumbClick = () => {
    onCloseRedoSkillActions();
    onCloseRedoWithPersona();
    onCloseNegativeFeedbackForm();

    onThumbsClick(FeedbackType.POSITIVE);
  };

  if (!messageId || !conversationId) {
    return null;
  }

  const isCollapsible =
    feedback?.feedback_type === FeedbackType.NEGATIVE
      ? ''
      : { ...onToggleNegativeFeedbackForm() };

  return (
    <div
      data-testid="thumb-buttons"
      className={classNames('nj-feedback-thumb-buttons nondebug-mode', {
        'in-column': withTitle,
      })}
    >
      <button
        onClick={handlePositiveThumbClick}
        aria-label="Click on the thumb up"
        disabled={disabled}
        className={classNames('nj-feedback-thumb-buttons--button', {
          'button-with-flex': withTitle,
          selected: feedback?.feedback_type === FeedbackType.POSITIVE,
        })}
      >
        <ThumbsUp
          weight={
            feedback?.feedback_type === FeedbackType.POSITIVE
              ? 'fill'
              : 'regular'
          }
          size={SVG_SIZE}
          color="currentColor"
        />
        {withTitle && <span>Good response</span>}
      </button>
      <div {...isCollapsible}>
        <button
          onClick={handleNegativeThumbClick}
          aria-label="Click on the thumb down"
          disabled={disabled}
          className={classNames('nj-feedback-thumb-buttons--button', {
            'button-with-flex': withTitle,
            selected: feedback?.feedback_type === FeedbackType.NEGATIVE,
          })}
        >
          <ThumbsDown
            weight={
              feedback?.feedback_type === FeedbackType.NEGATIVE
                ? 'fill'
                : 'regular'
            }
            size={SVG_SIZE}
            color="currentColor"
          />
          {withTitle && <span>Bad response</span>}
        </button>
      </div>
    </div>
  );
};
