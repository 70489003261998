import { useCallback, useContext, useEffect, useState } from 'react';
import AudioContext from 'src/contexts/AudioContext';
import { ChatMode, ConversationRole } from 'src/types';
import {
  useSession,
  useConversation,
  useAvatarPreferences,
  useAvatarSpeech,
} from 'src/hooks';
import { getStreamableMessageForAvatar } from 'src/utils';
import { AUTO_DETECT } from 'src/constants/languages';
import {
  DEFAULT_AVATAR_MALE_VOICE_ID,
  DEFAULT_AVATAR_VOICE_ID,
} from 'src/constants';

export const useDelegateSpeechToAvatar = () => {
  const { detectedLanguage } = useContext(AudioContext);
  const [avatarMessage, setAvatarMessage] = useState<string>('');
  const { avatarAudioLocale, avatarVoiceID, selectedAvatarGender } =
    useAvatarPreferences();

  const { chatMode } = useSession();

  const { conversationMessages } = useConversation();

  // switch on or off streaming to avatar sentence by sentence
  const { enqueueAvatarSpeech } = useAvatarSpeech();

  const delegateSpeechToAvatar = useCallback(
    (speech?: string) => {
      if (!speech) return;

      const detectedAudioLocale =
        avatarAudioLocale === AUTO_DETECT ? detectedLanguage : undefined;
      const audioLocale = detectedAudioLocale || avatarAudioLocale;
      const voiceID =
        avatarAudioLocale !== AUTO_DETECT
          ? avatarVoiceID
          : selectedAvatarGender === 'female'
            ? DEFAULT_AVATAR_VOICE_ID
            : DEFAULT_AVATAR_MALE_VOICE_ID;

      enqueueAvatarSpeech(speech, audioLocale, voiceID);
    },
    [
      avatarAudioLocale,
      avatarVoiceID,
      detectedLanguage,
      selectedAvatarGender,
      enqueueAvatarSpeech,
    ],
  );

  useEffect(() => {
    if (chatMode !== ChatMode.AVATAR) {
      return;
    }

    const lastMessage = conversationMessages[conversationMessages.length - 1];

    if (!lastMessage || lastMessage.role === ConversationRole.USER) {
      return;
    }

    const messageToSpeech = getStreamableMessageForAvatar(lastMessage);
    // to avoid calling delegateSpeechToAvatar on any message update (we just need to know if only content part of the message is updated)
    setAvatarMessage(messageToSpeech);
  }, [conversationMessages, chatMode, setAvatarMessage]);

  useEffect(() => {
    // this will call delegateSpeechToAvatar only when the avatarMessage is updated
    delegateSpeechToAvatar(avatarMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarMessage]);
};
