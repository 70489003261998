import { useState, createContext } from 'react';
import {
  Message,
  TasksListSortByStatus,
  TasksListSortByTime,
  ChatsFilter,
  ModalGalleryType,
  ModalGalleryList,
} from 'src/types';

export type BubbleActionPanelProps = {
  conversationId: string;
  taskId: string;
  message: Message;
};

export type SessionContextType = {
  currentScrollingTaskId: string | null;
  isOpenBubbleActionPanel: boolean;
  isOpenTaskListControlsPanel: boolean;
  bubbleActionPanelProps: BubbleActionPanelProps | null;
  sortedTasksByStatus: TasksListSortByStatus;
  sortedTasksByTime: TasksListSortByTime;
  isVisibleTaskViewModal: boolean;
  isVisibleModalGallery: boolean;
  isVisibleUpgradeModal: boolean;
  chatsFilter?: ChatsFilter;
  pageToken: string;
  filteredTasksPageToken: string;
  isShowAvatarSelect: boolean;
  isVisibleUpsellAvatarModal: boolean;
  isVisibleWelcomeModal: boolean;
  onToggleAvatarSelect: (value?: boolean) => void;
  onOpenBubbleActionPanel: (props: BubbleActionPanelProps) => void;
  onCloseBubbleActionPanel: () => void;
  onOpenTaskListControlsPanel: () => void;
  onCloseTaskListControlsPanel: () => void;
  setCurrentScrollingTaskId: (value: string | null) => void;
  setSortedTasksByStatus: (value: TasksListSortByStatus) => void;
  setSortedTasksByTime: (value: TasksListSortByTime) => void;
  onToggleVisibleTaskViewModal: (value?: boolean) => void;
  onToggleVisibleUpgradeModal: (value?: boolean) => void;
  onToggleUpsellAvatarModal: (value?: boolean) => void;
  onToggleWelcomeModal: (value?: boolean) => void;
  setChatsFilter: (chatsFilter?: ChatsFilter) => void;
  setPageToken: (pageToken: string) => void;
  setFilteredTasksPageToken: (filteredTasksPageToken: string) => void;
  isVisibleUpdatePlanModal: boolean;
  onChangeIsVisibleUpdatePlanModal: (value: boolean) => void;
  onChangeModalGalleryData: (
    isShow: boolean,
    modalType: ModalGalleryType | null,
    data: ModalGalleryList,
    defaultSelectedIndex: number,
    taskId?: string,
  ) => void;
  currentModalGalleryType: ModalGalleryType | null;
  currentListOfGalleryItems: ModalGalleryList;
  defaultSelectedGalleryIndex: number;
  isOpenSupportModal: boolean;
  onChangeIsOpenSupportModal: (value: boolean) => void;
  isOpenOnboardingModal: boolean;
  onChangeIsOpenOnboardingModal: (value: boolean) => void;
  currentTaskId?: string;
};

type SessionContextProviderProps = {
  children: React.ReactNode;
};

// TODO(olha): needs refactoring and moving to the slice
const SessionContext = createContext<SessionContextType>({
  bubbleActionPanelProps: null,
  currentScrollingTaskId: null,
  isOpenBubbleActionPanel: false,
  isOpenTaskListControlsPanel: false,
  sortedTasksByStatus: TasksListSortByStatus.ALL,
  sortedTasksByTime: TasksListSortByTime.DEFAULT,
  isVisibleTaskViewModal: false,
  isVisibleWelcomeModal: false,
  isVisibleModalGallery: false,
  isVisibleUpgradeModal: false,
  chatsFilter: ChatsFilter.ALL,
  pageToken: '',
  filteredTasksPageToken: '',
  isShowAvatarSelect: false,
  isVisibleUpsellAvatarModal: false,
  onToggleAvatarSelect: () => undefined,
  onOpenBubbleActionPanel: () => undefined,
  onCloseBubbleActionPanel: () => undefined,
  onOpenTaskListControlsPanel: () => undefined,
  onCloseTaskListControlsPanel: () => undefined,
  setCurrentScrollingTaskId: () => undefined,
  setSortedTasksByStatus: () => undefined,
  setSortedTasksByTime: () => undefined,
  onToggleVisibleTaskViewModal: () => undefined,
  onToggleVisibleUpgradeModal: () => undefined,
  onToggleUpsellAvatarModal: () => undefined,
  onToggleWelcomeModal: () => undefined,
  setChatsFilter: () => undefined,
  setPageToken: () => undefined,
  setFilteredTasksPageToken: () => undefined,
  isVisibleUpdatePlanModal: false,
  onChangeIsVisibleUpdatePlanModal: () => undefined,
  onChangeModalGalleryData: () => undefined,
  currentModalGalleryType: null,
  currentListOfGalleryItems: [],
  defaultSelectedGalleryIndex: 0,
  isOpenSupportModal: false,
  onChangeIsOpenSupportModal: () => undefined,
  onChangeIsOpenOnboardingModal: () => undefined,
  isOpenOnboardingModal: false,
});

const SessionContextProvider = ({ children }: SessionContextProviderProps) => {
  const [isVisibleBubbleActionPanel, setVisibleBubbleActionPanel] =
    useState<boolean>(false);
  const [bubbleActionPanelProps, setBubbleActionPanelProps] =
    useState<BubbleActionPanelProps | null>(null);
  const [isShowAvatarSelect, setIsShowAvatarSelect] = useState<boolean>(false);
  const [isVisibleUpdatePlanModal, onChangeIsVisibleUpdatePlanModal] =
    useState<boolean>(false);
  const [defaultSelectedGalleryIndex, setDefaultSelectedGalleryIndex] =
    useState(0);
  const [isOpenSupportModal, onChangeIsOpenSupportModal] =
    useState<boolean>(false);
  const [isOpenOnboardingModal, onChangeIsOpenOnboardingModal] =
    useState<boolean>(false);

  const [isVisibleModalGallery, setIsVisibleModalGallery] =
    useState<boolean>(false);
  const [currentModalGalleryType, setCurrentModalGalleryType] =
    useState<ModalGalleryType | null>(null);
  const [currentListOfGalleryItems, setCurrentListOfGalleryItems] =
    useState<ModalGalleryList>([]);
  const [taskId, setTaskId] = useState<string | undefined>();

  const handleChangeModalGallery = (
    isShowModal: boolean,
    modalContentType: ModalGalleryType | null,
    listOfItems: ModalGalleryList,
    defaultSelectedGalleryIndex: number,
    taskId?: string,
  ) => {
    setCurrentListOfGalleryItems(listOfItems);
    setDefaultSelectedGalleryIndex(defaultSelectedGalleryIndex);
    setCurrentModalGalleryType(modalContentType);
    setIsVisibleModalGallery(isShowModal);
    if (taskId) {
      setTaskId(taskId);
    }
  };

  const handleOpenBubbleActionPanel = (props: BubbleActionPanelProps) => {
    setVisibleBubbleActionPanel(true);
    setBubbleActionPanelProps(props);
  };

  const handleCloseBubbleActionPanel = () => {
    setVisibleBubbleActionPanel(false);
    setBubbleActionPanelProps(null);
  };

  const [isVisibleTaskListControlsPanel, setVisibleTaskListControlsPanel] =
    useState<boolean>(false);

  const handleOpenTaskListControlsPanel = () => {
    setVisibleTaskListControlsPanel(true);
  };

  const handleCloseTaskListControlsPanel = () => {
    setVisibleTaskListControlsPanel(false);
  };

  const [currentScrollingTaskId, setCurrentScrollingTaskId] = useState<
    string | null
  >(null);

  const [sortedTasksByTime, setSortedTasksByTime] =
    useState<TasksListSortByTime>(TasksListSortByTime.DEFAULT);
  const [sortedTasksByStatus, setSortedTasksByStatus] =
    useState<TasksListSortByStatus>(TasksListSortByStatus.ALL);

  const [isVisibleTaskViewModal, serVisibleTaskViewModal] =
    useState<boolean>(false);

  const onToggleVisibleTaskViewModal = (value?: boolean) => {
    serVisibleTaskViewModal(value ?? !isVisibleTaskViewModal);
  };

  const [isVisibleUpgradeModal, serVisibleUpgradeModal] =
    useState<boolean>(false);

  const onToggleVisibleUpgradeModal = (value?: boolean) => {
    serVisibleUpgradeModal(value ?? !isVisibleUpgradeModal);
  };

  const [pageToken, setPageToken] = useState<string>('');

  const onChangePageToken = (pageToken: string) => {
    setPageToken(pageToken);
  };

  const [filteredTasksPageToken, setFilteredTasksPageToken] =
    useState<string>('');

  const onChangeFilteredTasksPageToken = (filteredTasksPageToken: string) => {
    setFilteredTasksPageToken(filteredTasksPageToken);
  };

  const [chatsFilter, setChatsFilter] = useState<ChatsFilter>(ChatsFilter.ALL);

  const onChangeChatsFilter = (chatsFilter?: ChatsFilter) => {
    setPageToken('');
    setFilteredTasksPageToken('');

    setChatsFilter(chatsFilter ?? ChatsFilter.ALL);
  };

  const handleToggleAvatarSelect = (value?: boolean) => {
    setIsShowAvatarSelect(value ?? !isShowAvatarSelect);
  };

  const [isVisibleUpsellAvatarModal, setVisibleUpsellAvatarModal] =
    useState<boolean>(false);

  const [isVisibleWelcomeModal, setVisibleWelcomeModal] =
    useState<boolean>(false);

  const handleToggleUpsellAvatarModal = (value?: boolean) => {
    setVisibleUpsellAvatarModal(value ?? !isVisibleUpsellAvatarModal);
  };

  const handleToggleWelcomeModal = (value?: boolean) => {
    setVisibleWelcomeModal(value ?? !isVisibleWelcomeModal);
  };

  return (
    <SessionContext.Provider
      value={{
        bubbleActionPanelProps,
        currentScrollingTaskId,
        isOpenBubbleActionPanel: isVisibleBubbleActionPanel,
        isOpenTaskListControlsPanel: isVisibleTaskListControlsPanel,
        sortedTasksByStatus,
        sortedTasksByTime,
        isVisibleTaskViewModal,
        isVisibleModalGallery,
        isVisibleUpgradeModal,
        chatsFilter,
        pageToken,
        filteredTasksPageToken,
        isShowAvatarSelect,
        isVisibleUpsellAvatarModal,
        isVisibleWelcomeModal,
        onToggleAvatarSelect: handleToggleAvatarSelect,
        onOpenBubbleActionPanel: handleOpenBubbleActionPanel,
        onCloseBubbleActionPanel: handleCloseBubbleActionPanel,
        onOpenTaskListControlsPanel: handleOpenTaskListControlsPanel,
        onCloseTaskListControlsPanel: handleCloseTaskListControlsPanel,
        setCurrentScrollingTaskId,
        setSortedTasksByStatus,
        setSortedTasksByTime,
        onToggleVisibleTaskViewModal,
        onToggleVisibleUpgradeModal,
        setChatsFilter: onChangeChatsFilter,
        setPageToken: onChangePageToken,
        setFilteredTasksPageToken: onChangeFilteredTasksPageToken,
        isVisibleUpdatePlanModal,
        onChangeIsVisibleUpdatePlanModal,
        onChangeModalGalleryData: handleChangeModalGallery,
        currentModalGalleryType,
        currentListOfGalleryItems,
        defaultSelectedGalleryIndex,
        onToggleUpsellAvatarModal: handleToggleUpsellAvatarModal,
        onToggleWelcomeModal: handleToggleWelcomeModal,
        isOpenSupportModal,
        onChangeIsOpenSupportModal,
        isOpenOnboardingModal,
        onChangeIsOpenOnboardingModal,
        currentTaskId: taskId,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContextProvider };
export default SessionContext;
