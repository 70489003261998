import {
  Message,
  MessageChunk,
  MessageFooter,
  MessageType,
  Feedback,
} from 'src/types';
import { conversationApi } from '../services/conversationApi';
import { DEFAULT_CHAT_ID } from 'src/constants';
import { recursiveDeepMerge, recursiveUpdatePayload } from 'src/utils';

export const addMessageToConversation = (message: Message) => {
  const { user_id, conversation_id = DEFAULT_CHAT_ID } = message;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      // TODO(olha): add implementation for temporary (default) conversation
      return {
        ...draft,
        messages: [...(draft?.messages || []), message],
      };
    },
  );
};

export const replaceConversationChatTemporaryMessageId = ({
  temporaryMessageId,
  message: newMessage,
}: {
  temporaryMessageId: string;
  message: Message;
}) => {
  const { user_id, conversation_id = DEFAULT_CHAT_ID } = newMessage;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      // TODO(olha): add implementation for temporary (default) conversation
      return {
        ...draft,
        messages: draft?.messages?.map((item) =>
          item.message_id === temporaryMessageId ? newMessage : item,
        ),
      };
    },
  );
};

export const appendConversationMessage = (appendedMessage: Message) => {
  const {
    user_id,
    conversation_id = DEFAULT_CHAT_ID,
    message_id,
  } = appendedMessage;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      const focusMessage = draft?.messages?.find(
        (message: Message) => message.message_id === message_id,
      );

      const updatedPayload = focusMessage?.payload
        ? recursiveDeepMerge(
            focusMessage?.payload || {},
            appendedMessage.payload || {},
          )
        : appendedMessage.payload;

      return {
        ...draft,
        messages: draft?.messages?.map((item) =>
          message_id === item.message_id
            ? {
                ...focusMessage,
                ...appendedMessage,
                payload: updatedPayload,
              }
            : item,
        ),
      };
    },
  );
};

export const replaceConversationMessage = (replacedMessage: Message) => {
  const {
    user_id,
    conversation_id = DEFAULT_CHAT_ID,
    message_id,
  } = replacedMessage;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      return {
        ...draft,
        messages: draft?.messages?.map((item) =>
          message_id === item.message_id
            ? {
                ...item,
                ...replacedMessage,
              }
            : item,
        ),
      };
    },
  );
};

export const updateConversationMessage = (updatedMessage: Message) => {
  const {
    user_id,
    conversation_id = DEFAULT_CHAT_ID,
    message_id,
  } = updatedMessage;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      const focusMessage = draft?.messages?.find(
        (message: Message) => message.message_id === message_id,
      );

      const updatedPayload = recursiveUpdatePayload(
        focusMessage?.payload || {},
        updatedMessage.payload || {},
      );

      return {
        ...draft,
        messages: focusMessage
          ? draft?.messages?.map((item) =>
              message_id === item.message_id
                ? {
                    ...focusMessage,
                    ...updatedMessage,
                    payload: updatedPayload,
                  }
                : item,
            )
          : [...(draft?.messages || []), updatedMessage],
      };
    },
  );
};

// TODO(olha): the same as replaceConversationMessage. double-check and replace
export const addConversationMessageFooter = (messageFooter: MessageFooter) => {
  const {
    user_id,
    conversation_id = DEFAULT_CHAT_ID,
    message_id,
  } = messageFooter;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      return {
        ...draft,
        messages: draft?.messages?.map((item) =>
          message_id === item.message_id
            ? {
                ...item,
                ...messageFooter,
              }
            : item,
        ),
      };
    },
  );
};

export const addConversationMessageContentChunk = ({
  user_id,
  messageChunk,
}: {
  user_id: string;
  messageChunk: MessageChunk;
}) => {
  const {
    conversation_id = DEFAULT_CHAT_ID,
    message_id,
    task_id,
  } = messageChunk;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      return {
        ...draft,
        messages: draft?.messages?.map((item) =>
          message_id === item.message_id
            ? {
                ...item,
                task_id,
                content: item.content + messageChunk.content,
              }
            : item,
        ),
      };
    },
  );
};

// TODO(olha): the same as addConversationChatMessageContentChunk. double-check and replace
export const addConversationMessageChunk = ({
  user_id,
  messageChunk,
}: {
  user_id: string;
  messageChunk: MessageChunk;
}) => {
  const {
    conversation_id = DEFAULT_CHAT_ID,
    message_id,
    task_id,
  } = messageChunk;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      return {
        ...draft,
        messages: draft?.messages?.map((item) =>
          message_id === item.message_id
            ? {
                ...item,
                // (olha): it's a small workaround since if BE sends task_id, the message_type actually was changed
                message_type: task_id
                  ? MessageType.TASK_CREATED
                  : MessageType.CONVERSATION,
                task_id,
                content: item.content + messageChunk.content,
              }
            : item,
        ),
      };
    },
  );
};

export const updateConversationMessageFeedback = ({
  user_id,
  conversation_id,
  message_id,
  feedback,
}: {
  user_id: string;
  conversation_id: string;
  message_id: string;
  feedback: Feedback | null;
}) => {
  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      return {
        ...draft,
        messages: draft?.messages?.map((item) =>
          message_id === item.message_id
            ? {
                ...item,
                feedback: feedback || undefined,
              }
            : item,
        ),
      };
    },
  );
};
