import { Header } from 'src/pages/AuthPages/components/Header';
import GoogleLogo from 'src/images/logos/google-logo.svg';
import atlasSmile from 'src/images/login/atlas_smile.png';
import { signInWithRedirect } from 'aws-amplify/auth';
import {
  DEFAULT_PRIVACY_POLICY_LINK,
  DEFAULT_TERMS_OF_SERVICE_LINK,
} from 'src/constants/externalLinks';
import AuthContext from 'src/contexts/AuthContext';
import { useContext, useState } from 'react';
import { Envelope } from '@phosphor-icons/react';
import { SignupHeader } from 'src/pages/AuthPages/components/SignupHeader';
import { useForm, useWatch } from 'react-hook-form';
import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { useLazyCheckEmailInfoQuery } from 'src/store/services';
import { useBreakpoint } from 'src/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { sendGTMEvent } from 'src/utils';
import { isGoogleEmailProvider } from 'src/utils/user';
import { GTMEvent, AppRoutes } from 'src/types';
import AppleLogo from 'src/images/logos/apple-logo.png';
import AmazonLogo from 'src/images/logos/amazon-logo.png';
import FacebookLogo from 'src/images/logos/facebook-logo.png';

enum SignupFields {
  EMAIL = 'email',
}

interface FormData {
  [SignupFields.EMAIL]: string;
}

// todo: needs to refactoring this component (duplicated buttons, functions)
export const SignupPage = () => {
  const { setAuthLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { isMobileOrTablet } = useBreakpoint();
  const [isPromptToSignupWithGoogle, setIsPromptToSignupWithGoogle] =
    useState(false);

  // Always enable FB login, the meta_verification var doesn't seem to be used at all.
  // const { meta_verification } = useQueryParams();
  // const showFacebookLogin =
  //   meta_verification || process.env.REACT_APP_ENVIRONMENT !== 'production';
  const showFacebookLogin = true;

  const [checkEmailInfo] = useLazyCheckEmailInfoQuery();

  const methods = useForm<FormData>({
    defaultValues: {
      [SignupFields.EMAIL]: '',
    },
  });

  const { handleSubmit, control } = methods;

  const fields = useWatch({
    control,
  });

  const encodedEmail = encodeURIComponent(fields[SignupFields.EMAIL] || '');

  const redirectToEmailSignUp = `/sign-up-with-email?email=${encodedEmail}`;

  const handleGoogleSignupClick = async () => {
    sendGTMEvent(GTMEvent.GOOGLE_SSO_SIGNUP_CLICKS);

    // temporary login till signup is not ready
    setAuthLoading(true);
    signInWithRedirect({
      provider: 'Google',
    });
  };

  const handleAmazonSignupClick = async () => {
    sendGTMEvent(GTMEvent.AMAZON_SSO_SIGNUP_CLICKS);

    setAuthLoading(true);
    signInWithRedirect({
      provider: 'Amazon',
    });
  };

  const handleAppleSignupClick = async () => {
    sendGTMEvent(GTMEvent.APPLE_SSO_SIGNUP_CLICKS);

    setAuthLoading(true);
    signInWithRedirect({
      provider: 'Apple',
    });
  };

  const handleFacebookSignupClick = async () => {
    sendGTMEvent(GTMEvent.FACEBOOK_SSO_SIGNUP_CLICKS);

    setAuthLoading(true);
    signInWithRedirect({
      provider: 'Facebook',
    });
  };

  const submitForm = async (formData: FormData) => {
    try {
      setErrorMessage('');
      const { data } = await checkEmailInfo(
        formData[SignupFields.EMAIL].toLowerCase(),
      );

      sendGTMEvent(GTMEvent.EMAIL_SIGNUP_CLICKS);

      if (isGoogleEmailProvider(data?.provider)) {
        setIsPromptToSignupWithGoogle(true);
      } else {
        navigate(redirectToEmailSignUp);
      }
    } catch (error) {
      setErrorMessage(error ? (error as ServiceError).message : 'error');
    }
  };

  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<SignupHeader />} />
      <main className="nj-auth-content">
        <h1 className="nj-auth-header-blue-gradient">Meet Ninja</h1>
        <h2 className="nj-auth-header-small">Your Autonomous AI Agent</h2>
        <h3 className="nj-auth-header-secondary">Set up your account</h3>
        {isPromptToSignupWithGoogle ? (
          <>
            <p className="nj-auth-header-greeting">
              Hi {fields[SignupFields.EMAIL]}
            </p>
            <p className="nj-auth-header-description">
              The easiest way for you to sign up is with Google. By doing so,
              you can connect your calendar, enabling Ninja to access your
              availability and schedule meetings.
            </p>
            <button
              className="nj-auth-sign-in-up-button nj-google-button"
              onClick={handleGoogleSignupClick}
            >
              <div className="nj-google-button-state"></div>
              <div className="nj-google-button-content-wrapper">
                <div>
                  <img
                    className="nj-auth-sign-in-up-button-logo"
                    src={GoogleLogo}
                    alt="Google"
                  />
                </div>
                <span className="nj-google-button-contents">
                  Sign up with Google
                </span>
                <span style={{ display: 'none' }}>Sign up with Google</span>
              </div>
            </button>
            <p className="nj-auth-signup-hint">
              Prefer to create an account with a password?
            </p>
            <Link className="nj-auth-signup-link" to={redirectToEmailSignUp}>
              Click here
            </Link>
          </>
        ) : (
          <>
            <div className="nj-auth-sign-in-up-buttons">
              <button
                className="nj-auth-sign-in-up-button nj-google-button"
                onClick={handleGoogleSignupClick}
              >
                <div className="nj-google-button-state"></div>
                <div className="nj-google-button-content-wrapper">
                  <div>
                    <img
                      className="nj-auth-sign-in-up-button-logo"
                      src={GoogleLogo}
                      alt="Google"
                    />
                  </div>
                  <span className="nj-google-button-contents">
                    Sign up with Google
                  </span>
                  <span style={{ display: 'none' }}>Sign up with Google</span>
                </div>
              </button>
              <button
                className="nj-auth-sign-in-up-button nj-google-button"
                onClick={handleAppleSignupClick}
              >
                <div className="nj-google-button-state"></div>
                <div className="nj-google-button-content-wrapper">
                  <div>
                    <img
                      className="nj-auth-sign-in-up-button-logo"
                      src={AppleLogo}
                      alt="Apple"
                    />
                  </div>
                  <span className="nj-google-button-contents">
                    Sign up with Apple
                  </span>
                  <span style={{ display: 'none' }}>Sign up with Apple</span>
                </div>
              </button>
              <button
                className="nj-auth-sign-in-up-button nj-google-button"
                onClick={handleAmazonSignupClick}
              >
                <div className="nj-google-button-state"></div>
                <div className="nj-google-button-content-wrapper">
                  <div>
                    <img
                      className="nj-auth-sign-in-up-button-logo"
                      src={AmazonLogo}
                      alt="Amazon"
                    />
                  </div>
                  <span className="nj-google-button-contents">
                    Sign up with Amazon
                  </span>
                  <span style={{ display: 'none' }}>Sign up with Amazon</span>
                </div>
              </button>
              {showFacebookLogin && (
                <button
                  className="nj-auth-sign-in-up-button nj-google-button"
                  onClick={handleFacebookSignupClick}
                >
                  <div className="nj-google-button-state"></div>
                  <div className="nj-google-button-content-wrapper">
                    <div>
                      <img
                        className="nj-auth-sign-in-up-button-logo"
                        src={FacebookLogo}
                        alt="Facebook"
                      />
                    </div>
                    <span className="nj-google-button-contents">
                      Sign up with Facebook
                    </span>
                    <span style={{ display: 'none' }}>
                      Sign up with Facebook
                    </span>
                  </div>
                </button>
              )}
              <div className="nj-auth-separator-block">
                <div className="nj-auth-separator-block-line" />
                <span>OR</span>
                <div className="nj-auth-separator-block-line" />
              </div>
              <FormProvider<FormData> methods={methods}>
                <form
                  onSubmit={handleSubmit(submitForm)}
                  className="nj-auth-form no-margin"
                >
                  <FormInput
                    name={SignupFields.EMAIL}
                    type="email"
                    className="nj-auth-form--input"
                    placeholder="Enter Email"
                    required={true}
                    hasErrorBorder={errorMessage.length > 0}
                  />
                  <button className="nj-auth-sign-in-up-button" type="submit">
                    <div className="nj-button-content-wrapper">
                      <Envelope size={24} />
                      <span className="nj-button-contents">
                        Sign up with Email
                      </span>
                    </div>
                  </button>
                </form>
              </FormProvider>
            </div>
            <p className="nj-auth-agreement-label">
              By signing up, you agree to the
              <a
                href={DEFAULT_TERMS_OF_SERVICE_LINK}
                target="_blank"
                rel="noreferrer"
                className="nj-auth-terms-link"
              >
                &nbsp;Terms of Service&nbsp;
              </a>
              and have read and acknowledge our
              <a
                href={DEFAULT_PRIVACY_POLICY_LINK}
                target="_blank"
                rel="noreferrer"
                className="nj-auth-terms-link"
              >
                &nbsp;Privacy Policy
              </a>
            </p>
          </>
        )}

        <Link
          className="nj-auth-signup-link nj-auth-go-back-link"
          to={AppRoutes.HOME}
        >
          Go back to myninja.ai
        </Link>
      </main>
      {!isMobileOrTablet && (
        <img
          className="nj-auth-background-image"
          src={atlasSmile}
          alt="Ninja"
        />
      )}
    </div>
  );
};
