import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { LEARN_MORE_PRICES_LINK } from 'src/constants/externalLinks';

export const UpgradeLearnMoreButtons = () => {
  const { onToggleVisibleUpgradeModal } = useContext(SessionContext);

  return (
    <div className="nj-tier-auth-footer-button-wrapper">
      <a
        target="_blank"
        rel="noreferrer"
        href={LEARN_MORE_PRICES_LINK}
        className="nj-tier-auth-footer-button learn-more-button"
      >
        Learn more
      </a>
      <button
        type="button"
        className="nj-tier-auth-footer-button signup-button"
        onClick={() => onToggleVisibleUpgradeModal(true)}
      >
        Upgrade
      </button>
    </div>
  );
};
