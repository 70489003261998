import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppUser,
  BannerType,
  ConversationRole,
  Notification,
  ApiTaskSelectable,
  TaskSkill,
} from 'src/types';
import { DEFAULT_AGENT } from 'src/constants';
import { fetchUserById } from 'src/store/thunks';
import { usersApi } from '../services';
import { RootState } from '../index';
import { UpdateAllUserStatusesRequest } from 'src/types/models/UpdateAllUserStatusesRequest';
import { UpdateAppVersion } from 'src/types/models/UpdateAppVersion';
import { PURGE } from 'reduxjs-toolkit-persist';
import { UserTier } from 'src/types/models/UserTier';
import { UserTierStatus } from 'src/types/models/UserTierStatus';
import type { StripeSubscription } from 'src/types/models/StripeSubscription';

interface SessionState {
  agent: AppUser;
  appUser: AppUser;
  notification?: Notification;
  isDarkTheme: boolean;
  isShowOnboardingHints: boolean;
  onboardingHintsStep: number;
  isAccessModalShown: boolean;
  isFirstSchedulerTask: boolean;
  bannerType: BannerType | null;
  isFirstUserQuery: boolean;
  // check if we still need keep them in the persist
  isSocketConnected: boolean;
  currentTaskId?: string;
  bannersSet: BannerType[];
  currentBannerIndex: number;
  blackListBanners: Partial<
    Record<
      BannerType,
      {
        timestamp: string;
        isHidden: boolean;
      }
    >
  >;
}

const initialState: SessionState = {
  agent: DEFAULT_AGENT,
  appUser: {} as AppUser,
  isSocketConnected: false,
  notification: undefined,
  isDarkTheme: false,
  isShowOnboardingHints: false,
  onboardingHintsStep: 1,
  currentTaskId: undefined,
  isAccessModalShown: false,
  isFirstSchedulerTask: false,
  bannerType: null,
  isFirstUserQuery: false,
  bannersSet: [],
  currentBannerIndex: 0,
  blackListBanners: {
    [BannerType.INSUFFICIENT_CREDITS]: {
      timestamp: '',
      isHidden: false,
    },
    [BannerType.LOW_CREDITS]: {
      timestamp: '',
      isHidden: false,
    },
    [BannerType.LOW_TASKS]: {
      timestamp: '',
      isHidden: false,
    },
    [BannerType.LOW_DAYS]: {
      timestamp: '',
      isHidden: false,
    },
  },
};

/**
 * Keeps on a session data.
 */
export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetSession: (state) => {
      return initialState;
    },
    setNewBannerToSet: (state, action: PayloadAction<BannerType>) => {
      if (state.bannersSet.includes(action.payload)) {
        return { ...state };
      }
      const newArray = [...state.bannersSet, action.payload];
      return {
        ...state,
        bannersSet: newArray,
        currentBannerIndex: newArray.length - 1 || 0,
      };
    },
    removeBannerFromSet: (state, action: PayloadAction<BannerType>) => {
      const updatedArray = state.bannersSet.filter(
        (item) => item !== action.payload,
      );
      return {
        ...state,
        bannersSet: [...updatedArray],
        currentBannerIndex: updatedArray.length - 1 || 0,
      };
    },
    removeBannersFromSet: (state, action: PayloadAction<BannerType[]>) => {
      const updatedArray = state.bannersSet.filter(
        (item) => !action.payload.includes(item),
      );
      return {
        ...state,
        bannersSet: [...updatedArray],
        currentBannerIndex: updatedArray.length - 1 || 0,
      };
    },
    setCurrentBannerIndex: (state, action: PayloadAction<number>) => {
      return { ...state, currentBannerIndex: action.payload };
    },
    setToBlackListBanner: (state, action: PayloadAction<BannerType>) => {
      return {
        ...state,
        blackListBanners: {
          ...state.blackListBanners,
          [action.payload]: { isHidden: true, timestamp: new Date().valueOf() },
        },
      };
    },
    setNotification: (state, action: PayloadAction<Notification>) => {
      state.notification = action.payload;
    },
    setAppUser: (state, action: PayloadAction<AppUser>) => {
      state.appUser = action.payload;
    },
    setUserStatus: (
      state,
      action: PayloadAction<UpdateAllUserStatusesRequest>,
    ) => {
      return {
        ...state,
        appUser: { ...state.appUser, status: action.payload.new_status },
      };
    },
    updateUserTier: (
      state,
      action: PayloadAction<{
        new_tier: UserTier;
        new_tier_status: UserTierStatus;
      }>,
    ) => {
      return {
        ...state,
        appUser: {
          ...state.appUser,
          tier_id: action.payload.new_tier,
          tier_status: action.payload.new_tier_status,
        },
      };
    },
    updateRenewEarlyStatus: (
      state,
      action: PayloadAction<{
        renew_early_pending_subscription?: StripeSubscription;
      }>,
    ) => {
      return {
        ...state,
        appUser: {
          ...state.appUser,
          stripe_info: state.appUser.stripe_info
            ? {
                ...state.appUser.stripe_info,
                renew_early_pending_subscription:
                  action.payload.renew_early_pending_subscription,
              }
            : undefined,
        },
      };
    },
    setUserAppVersion: (state, action: PayloadAction<UpdateAppVersion>) => {
      return {
        ...state,
        appUser: {
          ...state.appUser,
          metadata: {
            ...state.appUser.metadata,
            app_version: action.payload.new_version.trim(),
          },
        },
      };
    },
    setIsDarkTheme: (state, action: PayloadAction<boolean>) => {
      state.isDarkTheme = action.payload;
    },
    showAccessRequestOnSchedulerTask: (
      state,
      action: PayloadAction<ApiTaskSelectable>,
    ) => {
      if (
        action.payload.skill === TaskSkill.SCHEDULING &&
        !state.isFirstSchedulerTask
      ) {
        state.isFirstSchedulerTask = true;
      }
    },
    setIsAccessModalShown: (state, action: PayloadAction<boolean>) => {
      state.isAccessModalShown = action.payload;
    },
    setSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.isSocketConnected = action.payload;
    },
    clearNotification: (state) => {
      state.notification = undefined;
    },
    setOnboardingHintStep: (state, action: PayloadAction<number>) => {
      state.onboardingHintsStep = action.payload;
    },
    setIsShowOnboardingHints: (state, action: PayloadAction<boolean>) => {
      state.isShowOnboardingHints = action.payload;
    },
    setCurrentTaskId: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        currentTaskId: action.payload,
      };
    },
    setFirstUserQuery: (state) => {
      state.isFirstUserQuery = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // TODO(olha): move to RTK query
      .addCase(fetchUserById.fulfilled, (state, action) => {
        if (!action.payload) return state;
        const avatar = action.payload.first_name || '';
        const appUser = {
          ...action.payload,
          avatar: avatar !== '' ? `avatar${avatar}` : '',
          role: ConversationRole.USER,
        };

        // create an app user entry
        return { ...state, appUser };
      })
      .addCase(PURGE, () => {
        return initialState;
      })
      .addMatcher(
        usersApi.endpoints.getUserById.matchFulfilled,
        (state, action) => {
          return { ...state, appUser: { ...state.appUser, ...action.payload } };
        },
      );
  },
});

export const sessionState = (state: RootState) => state.session as SessionState;

export const {
  resetSession,
  setSocketConnected,
  setNotification,
  clearNotification,
  setAppUser,
  setUserStatus,
  setUserAppVersion,
  setIsDarkTheme,
  setOnboardingHintStep,
  setCurrentTaskId,
  showAccessRequestOnSchedulerTask,
  setIsAccessModalShown,
  updateUserTier,
  setFirstUserQuery,
  updateRenewEarlyStatus,
  setNewBannerToSet,
  removeBannerFromSet,
  removeBannersFromSet,
  setCurrentBannerIndex,
  setToBlackListBanner,
} = sessionSlice.actions;

export default sessionSlice.reducer;
