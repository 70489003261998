import { forwardRef } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  Cube,
  Wallet,
  Pulse,
  ArrowLeft,
  UserCircle,
  CirclesThreePlus,
  Gear,
} from '@phosphor-icons/react';
import {
  AppRoutes,
  BillingSettingsNestedRoutes,
  UserSettingsNestedRoutes,
} from 'src/types';
import { useSession } from 'src/hooks';
import styles from './ManageAccountNavigation.module.scss';

const SVG_SIZE_L = 24;
const SVG_SIZE_M = 20;

const SVG_PROPS = {
  size: SVG_SIZE_M,
};

interface ManageAccountNavigationProps {
  onClose?: () => void;
}

export const ManageAccountNavigation = forwardRef<
  HTMLDivElement,
  ManageAccountNavigationProps
>(({ onClose }, ref) => {
  const { pathname } = useLocation();
  const { isProTier, isProTrialTier } = useSession();

  const navigation = [
    {
      category: 'User Settings',
      links: [
        {
          route: UserSettingsNestedRoutes.PROFILE,
          icon: <UserCircle {...SVG_PROPS} weight="fill" />,
          title: 'Profile',
          defaultRoute: false,
          disabled: false,
          visible: true,
        },
        {
          route: UserSettingsNestedRoutes.INTEGRATIONS,
          icon: <CirclesThreePlus {...SVG_PROPS} />,
          title: 'Integrations',
          defaultRoute: false,
          disabled: false,
          visible: true,
        },
        {
          route: UserSettingsNestedRoutes.GENERAL,
          icon: <Gear {...SVG_PROPS} />,
          title: 'General',
          defaultRoute: false,
          disabled: false,
          visible: true,
        },
        {
          route: UserSettingsNestedRoutes.ACTIVITY,
          icon: <Pulse {...SVG_PROPS} />,
          title: 'Activity',
          defaultRoute: false,
          disabled: !(isProTier || isProTrialTier),
          visible: true,
        },
      ],
    },
    {
      category: 'Billing Settings',
      links: [
        {
          route: BillingSettingsNestedRoutes.SUBSCRIPTION,
          icon: <Cube {...SVG_PROPS} />,
          title: 'Subscription',
          defaultRoute: true,
          disabled: false,
          visible: true,
        },
        {
          route: BillingSettingsNestedRoutes.BILLING,
          icon: <Wallet {...SVG_PROPS} />,
          title: 'Billing',
          defaultRoute: false,
          disabled: !isProTier,
          visible: true,
        },
      ],
    },
  ];

  return (
    <div className={styles.root} ref={ref}>
      <Link to={AppRoutes.HOME} className={styles.goBackLink}>
        <ArrowLeft size={SVG_SIZE_L} />
        Back to Ninja
      </Link>

      <div className={styles.menu}>
        {navigation.map(({ category, links }, index) => (
          <div className={styles.navGroup} key={category + index}>
            <span className={styles.category}>{category}</span>
            <>
              {links.map(
                (
                  { route, icon, title, defaultRoute, disabled, visible },
                  index,
                ) => (
                  <div key={route + index}>
                    {visible && (
                      <NavLink
                        onClick={(e) => {
                          if (disabled) {
                            e.preventDefault();
                          } else if (onClose) {
                            onClose();
                          }
                        }}
                        className={({ isActive }) =>
                          classNames(styles.menuLink, {
                            active:
                              isActive ||
                              (defaultRoute &&
                                pathname === AppRoutes.MANAGE_ACCOUNT),
                            disabled,
                          })
                        }
                        key={route}
                        to={route}
                      >
                        {icon}
                        {title}
                      </NavLink>
                    )}
                  </div>
                ),
              )}
            </>
          </div>
        ))}
      </div>
    </div>
  );
});
