import { useSession } from 'src/hooks';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { InsufficientCreditsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/InsufficientCreditsSection';
import { RemainingTasksSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/RemainingTasksSection';
import { CreditsInfoSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/CreditsInfoSection';
import { UserTierStatus } from 'src/types';
import { LowCreditsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/LowCreditsSection';
import { useGetUserBudgetQuotaInfoQuery } from 'src/store/services';
import styles from './WalletSection.module.scss';
import { useMemo } from 'react';
import { UserTier } from 'src/types/models/UserTier';
import { TrialDaysSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/TrialDaysSection';

export const WalletSection = () => {
  const { isOpenTier, isFreeTier, isProTier, appUser, isProTrialTier } =
    useSession();

  const { data: quotaData } = useGetUserBudgetQuotaInfoQuery({
    user_id: appUser.user_id,
  });

  const description = useMemo(() => {
    switch (appUser.tier_id) {
      case UserTier.OPEN:
        return 'Enjoy 7 days of unlimited access to Ninja Agents and receive $5 in credits for external model usage.';
      case UserTier.FREE:
        return 'Enjoy unlimited access to Ninja and receive credits towards 24+ external models.';
      case UserTier.PRO_TRIAL:
        return 'Get Ninja PRO $1 for first month';
      default:
        return '';
    }
  }, [appUser.tier_id]);

  return (
    <div className={styles.root}>
      {(isOpenTier || isFreeTier) && <RemainingTasksSection />}

      {isProTrialTier && (
        <>
          <TrialDaysSection />
          <hr className="divider" />
        </>
      )}

      {(isProTier || isProTrialTier) && <CreditsInfoSection />}

      {(isOpenTier || isFreeTier || isProTrialTier) && (
        <>
          <hr className="divider" />

          {isProTrialTier &&
            appUser?.tier_status !== UserTierStatus.QUOTA_EXCEEDED && (
              <p className={styles.offerLabel}>Trial Offer</p>
            )}
          {isProTrialTier &&
            appUser?.tier_status === UserTierStatus.QUOTA_EXCEEDED && (
              <span className={styles.insufficientlabel}>
                Insufficient credits
              </span>
            )}
          {isOpenTier && (
            <p className={styles.offerLabel}>Limited time Offer</p>
          )}

          <UpsellMessage
            description={description}
            className={styles.upsellMessage}
          />
        </>
      )}

      {isProTier &&
        appUser?.tier_status === UserTierStatus.OK &&
        quotaData?.is_low_balance && (
          <>
            <hr className="divider" />
            <LowCreditsSection />
          </>
        )}

      {isProTier && appUser?.tier_status === UserTierStatus.QUOTA_EXCEEDED && (
        <>
          <hr className="divider" />
          <InsufficientCreditsSection />
        </>
      )}
    </div>
  );
};
