import { useContext, useEffect, useCallback, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDropzone } from 'react-dropzone';
import AudioContext from 'src/contexts/AudioContext';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useSession,
  useBreakpoint,
  useFullScreen,
  useAvatarQueue,
  useThreads,
  useChatPageQueryParams,
  useSpeechRecognizer,
  useSubmitUserInput,
  useAvatarPreferences,
  usePictureInPicture,
  useAttachFile,
  useAvatarIdleTimer,
} from 'src/hooks';
import { ChatMode } from 'src/types';
import { MultiConversationsPanel } from './components/MultiConversationsPanel';
import { MobileSidePanel } from './components/MobileSidePanel';
import { MultiConversationsListPanel } from './components/MultiConversationsListPanel';
import {
  interruptMetahuman,
  replaceFirstWordWithCommands,
  logAction,
} from 'src/utils';
import { ConversationsHeader } from './components/ConversationsHeader';
import { TaskView } from 'src/components/FlatAppearance/components/TaskView';
import { AvatarFrame } from 'src/pages/ManageTasksChatPage/components/AvatarFrame';
import { AvatarJoiningQueueModal } from 'src/pages/ManageTasksChatPage/components/AvatarJoininigQueueModal';
import {
  ANIMATION_TIMEOUT,
  LOCAL_STORAGE_VALUE,
  SHOWN_ONBOARDING_MODAL,
} from 'src/constants';
import { ModalWithGallery } from 'src/components/ModalWithGallery';
import AuthContext from 'src/contexts/AuthContext';
import { AvatarSelectorModal } from './components/AvatarSelectorButton/components/AvatarSelectorModal';
import { AvatarUpsellVideoModal } from 'src/components/AvatarUpsellVideoModal';
import { SupportModal } from 'src/components/SupportModal';
import { PromoTooltip } from './components/PromoTooltip/PromoTooltip';
import { DnDOverlay } from 'src/pages/ManageTasksChatPage/components/DnDOverlay';
import styles from './ManageTasksChatPage.module.scss';
import SessionContext from 'src/contexts/SessionContext';
import { useGetUserTaskQuotaInfoQuery } from 'src/store/services';
import { useBanner } from 'src/hooks/useBanner';
import classNames from 'classnames';
import { OnboardingModal } from 'src/components/OnboardingModal';

/**
 * Manage Tasks Page displays a list of tasks and task creation is enabled
 * by chat powered communication with EA via audio or text.
 */
export const ManageTasksChatPage = () => {
  const {
    chatMode,
    appUser: user,
    isOpenTier,
    isFreeTier,
    isProTier,
    isProTrialTier,
  } = useSession();

  const { isGuestAccess } = useContext(AuthContext);
  const { bannersSet } = useBanner();
  const { isMobileOrTablet } = useBreakpoint();
  const { isSubmitHappened } = useThreads();
  const { onSubmitUserInput } = useSubmitUserInput();
  const { avatarAudioLocale } = useAvatarPreferences();
  useChatPageQueryParams();
  const {
    isVisibleModalGallery,
    onChangeIsOpenOnboardingModal,
    isOpenOnboardingModal,
  } = useContext(SessionContext);

  const { isPictureInPicture } = usePictureInPicture();

  const { threadInputBoxValue, setThreadInputBoxValue } = useContext(
    ThreadInputBoxContext,
  );

  const { data: quotaData } = useGetUserTaskQuotaInfoQuery(
    {
      user_id: user.user_id,
    },
    {
      skip: !user.user_id || isProTier || isProTrialTier,
    },
  );

  const { setMetaHumanTalking } = useContext(AudioContext);
  const { showTranscript } = useContext(AvatarModeContext);

  useFullScreen();
  useAvatarQueue();
  useAvatarIdleTimer();

  const isAvatarMode = useMemo(() => chatMode === ChatMode.AVATAR, [chatMode]);

  const { addFile } = useAttachFile();

  const { getRootProps, isDragActive } = useDropzone({
    onDropAccepted: async (files) => {
      await addFile(files[0]);
    },
    noClick: true,
    disabled:
      isVisibleModalGallery ||
      ((isOpenTier || isFreeTier) && quotaData && quotaData.count === 0),
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'text/plain': ['.txt', '.tsv'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/rtf': ['.rtf'],
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
  });

  // interrupt metahuman during unmount
  useEffect(() => {
    return () => {
      interruptMetahuman(user.user_id);
      setMetaHumanTalking(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isOpenOnboardingModal &&
      isOpenTier &&
      localStorage.getItem(SHOWN_ONBOARDING_MODAL) !== LOCAL_STORAGE_VALUE
    ) {
      onChangeIsOpenOnboardingModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenTier]);

  const handleSpeechRecognizing = useCallback(
    (newValue: string) => {
      setThreadInputBoxValue(`${threadInputBoxValue} ${newValue}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadInputBoxValue],
  );

  const handleSpeechRecognized = useCallback(
    async (newValue: string) => {
      // We don't want to send the message if "stop record" was clicked.
      if (!recordInProgressRef?.current) {
        return;
      }

      const updatedNewValue = threadInputBoxValue
        ? `${threadInputBoxValue} ${
            newValue[0].toLowerCase() + newValue.slice(1)
          }`
        : newValue;

      setThreadInputBoxValue(updatedNewValue);

      const resultValue = replaceFirstWordWithCommands(updatedNewValue);

      logAction('send_message', { type: 'voice' });

      muteMicrophone();
      await onSubmitUserInput(resultValue);
      if (isAvatarMode) {
        // Event that is coming from the iFrame is taking some time and UI behavior in not smooth.
        // So setting metaHumanTalking to true as soon as we send the request.
        setMetaHumanTalking(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadInputBoxValue, onSubmitUserInput],
  );

  const {
    startSpeechRecognizing,
    stopSpeechRecognizing,
    recordInProgressRef,
    microphoneIsMuted,
    muteMicrophone,
    unMuteMicrophone,
  } = useSpeechRecognizer({
    onRecognizing: handleSpeechRecognizing,
    onRecognized: handleSpeechRecognized,
    sourceLanguage: avatarAudioLocale,
  });

  useEffect(() => {
    // TODO(olha): double-check isSubmitHappened here
    if (!isAvatarMode && !isSubmitHappened && microphoneIsMuted) {
      unMuteMicrophone();
    }
  }, [isSubmitHappened, microphoneIsMuted, isAvatarMode, unMuteMicrophone]);

  const conversationsPanelProps = {
    startSpeechRecognizing,
    stopSpeechRecognizing,
    unMuteMicrophone,
  };

  const props = { ...getRootProps() };

  //  don't wait for user_id in the guest mode
  return user.user_id || isGuestAccess ? (
    <div
      className={classNames(styles.root, {
        [styles.reducedHight]: bannersSet.length !== 0,
      })}
      ref={props.ref}
      onDrop={props.onDrop}
      onDragLeave={props.onDragLeave}
      onDragEnter={props.onDragEnter}
    >
      {isDragActive && <DnDOverlay />}

      {isPictureInPicture && <AvatarFrame isFloating />}

      <div className={styles.container}>
        {/* TODO(olha): rename to LeftSidePanel */}
        <MultiConversationsListPanel />

        {isMobileOrTablet ? (
          <>
            <ConversationsHeader />
            <MultiConversationsPanel {...conversationsPanelProps} />
          </>
        ) : (
          <div className={styles.middleContainer}>
            <ConversationsHeader />

            <div className={styles.threadContainerWrapper}>
              {/* TODO(olha): rename to ThreadMainContainer */}
              <CSSTransition
                in={showTranscript}
                timeout={ANIMATION_TIMEOUT}
                classNames="nj-animate-avatar-frame-width"
                unmountOnExit
              >
                <MultiConversationsPanel {...conversationsPanelProps} />
              </CSSTransition>

              {!isPictureInPicture && <AvatarFrame />}
            </div>
          </div>
        )}

        {/* TODO(olha): rename to RightSidePanel */}
        <MobileSidePanel />
      </div>

      <AvatarJoiningQueueModal />

      <TaskView />

      <ModalWithGallery />

      <PromoTooltip />

      {isMobileOrTablet && <AvatarSelectorModal />}

      <AvatarUpsellVideoModal />

      <SupportModal />

      <OnboardingModal />
    </div>
  ) : (
    <></>
  );
};
