import { Info, Warning } from '@phosphor-icons/react';
import { useSession } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { SVG_SIZE_L } from 'src/constants';
import dayjs from 'dayjs';

export const LowDaysBanner = () => {
  const { appUser, isProTrialTier } = useSession();

  const diff = Math.ceil(
    dayjs(appUser?.tier_expiration_date).diff(dayjs(), 'day', true),
  );

  const { onToggleVisibleUpgradeModal } = useContext(SessionContext);

  const handleUpgradeClick = () => {
    onToggleVisibleUpgradeModal(true);
  };

  if (!isProTrialTier) {
    return null;
  }

  return (
    <div className="nj-common-banner">
      <div className="nj-common-banner--left-side-wrapper">
        {diff > 0 ? (
          <Info size={SVG_SIZE_L} weight="fill" />
        ) : (
          <Warning size={SVG_SIZE_L} weight="fill" color="#D74E45" />
        )}
        <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
          <h5 className="nj-common-banner--title">
            {diff > 0
              ? `${diff} days of PRO Trial left`
              : 'No days of PRO Trial left'}
          </h5>
        </div>
      </div>
      <div className="nj-common-banner--right-side-wrapper">
        <button
          type="button"
          className="nj-button nj-banner--renew-button"
          onClick={handleUpgradeClick}
        >
          Upgrade
        </button>
      </div>
    </div>
  );
};
