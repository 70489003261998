import { TaskSkill } from './api';

export type PlanSectionType = 'free' | 'pro';

export interface PaymentPlanOption {
  lookup_key: string;
  amount: number;
  isDefault?: boolean;
  tasksCount?: number;
  isAnnual?: boolean;
  promotion_code?: string;
}

export interface PaymentPlanOptionWithSale extends PaymentPlanOption {
  newAmount: number;
}

export interface PaymentsActivity {
  skill: TaskSkill;
  task_hash: string;
  timestamp: string;
  cost: number;
  status: string;
  models: PaymentsActivityModel[];
  components: PaymentsActivityComponent[];
}

export interface PaymentsActivityModel {
  platform: string;
  provider: string;
  model_name: string;
}

export interface PaymentsActivityComponent {
  component: string;
}

export interface PaymentsActivityColumn {
  header: string;
  accessor: keyof PaymentsActivity;
  render?: (activity: PaymentsActivity) => React.ReactNode;
  format?: (value: number | string) => string;
}

export enum PaymentPlanInterval {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export interface PaymentsSubscriptionInfo {
  inception_timestamp: string;
  is_cancelled: boolean;
  curr_period_start_timestamp: string;
  curr_period_end_timestamp: string;
  interval: PaymentPlanInterval;
  price_info: {
    amount: number;
    currency: string;
    lookup_key: string;
    credit: number;
  };
}

export interface PlanOption {
  title: string;
  withBorder: boolean;
  link?: { href: string; label: string };
  isExternalModels?: boolean;
}
