import { useContext } from 'react';
import { Check, Copy } from '@phosphor-icons/react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import SessionContext from 'src/contexts/SessionContext';
import { Message, MessageType } from 'src/types';
import {
  getCodeCopyTemplate,
  getResearchCopyTemplate,
  getSchedulerCopyTemplate,
  getChatCardCopyTemplate,
} from 'src/utils';

type CopyMessageButtonProps = {
  item: Message;
  withTitle?: boolean;
  disabled?: boolean;
};

const SVG_SIZE = 20;

export const CopyMessageButton = ({
  item,
  withTitle,
  disabled,
}: CopyMessageButtonProps) => {
  const { isOpenBubbleActionPanel, onCloseBubbleActionPanel } =
    useContext(SessionContext);

  const getCopyString = (item: Message) => {
    switch (item.message_type) {
      case MessageType.CONVERSATION:
      case MessageType.TASK_CREATED:
        return item.content.trim();
      case MessageType.CODE_TASK_CREATION_CARD:
        return getCodeCopyTemplate(item);
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        return getResearchCopyTemplate(item);
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        return getSchedulerCopyTemplate(item);
      case MessageType.CHAT_CARD:
        return getChatCardCopyTemplate(item);

      default:
        // since all cards will have content at least
        // providing a default case, otherwise, each
        // copyable card type should have a template.
        return item.content.trim();
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getCopyString(item));
    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={24} />
        <span>Copied</span>
      </div>,
      {
        className: 'nj-copy-snackbar',
      },
    );
    if (isOpenBubbleActionPanel) {
      onCloseBubbleActionPanel();
    }
  };

  return (
    <button
      className={classNames('nj-copy-button', {
        'button-with-flex': withTitle,
      })}
      onClick={handleCopy}
      disabled={disabled}
    >
      <Copy size={SVG_SIZE} color="currentColor" />
      {withTitle && <span>Copy</span>}
    </button>
  );
};
