import { DebugMessageWrapper } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/DebugMessageWrapper';
import { TaskStatusIcon } from 'src/components/TaskStatusIcon';
import { ProgressSection } from 'src/components/FlatAppearance/components/TaskView/components/TaskViewHeader/components';
import { MessageType, TaskState } from 'src/types';
import { useMemo } from 'react';
import { useTaskById } from 'src/hooks';
import { FILE_REGEX } from 'src/constants';

export const TaskQueryStatusSection = () => {
  const { currentTaskData } = useTaskById();

  const { state, task_subject } = currentTaskData || {};

  const progressMessage = useMemo(
    () =>
      currentTaskData?.conversation?.messages?.find(
        (item) => item.message_type === MessageType.PROGRESS_UPDATE,
      ),
    [currentTaskData?.conversation?.messages],
  );

  return (
    <div className="nj-task-view-header--query-state-info">
      <div className="nj-task-view-header--query-state-info-row">
        <span className="nj-task-view-header--query-state-label">
          Refined Query
        </span>
        <div className="nj-task-view-header--query-state-content">
          {task_subject?.replace(FILE_REGEX, '')}
        </div>
      </div>
      {progressMessage && (
        <div className="nj-task-view-header--query-state-info-row">
          <span className="nj-task-view-header--query-state-label">Status</span>
          <div className="nj-task-view-header--query-state-content">
            <DebugMessageWrapper message={progressMessage}>
              <div className="nj-task-view-header--progress-section">
                <TaskStatusIcon
                  taskState={state}
                  className="nj-task-view-header--status-icon"
                />

                <ProgressSection
                  message={progressMessage}
                  taskState={state || TaskState.IN_PROGRESS}
                />
              </div>
            </DebugMessageWrapper>
          </div>
        </div>
      )}
    </div>
  );
};
