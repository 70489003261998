import { useContext, useEffect, useMemo, useState } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import throttle from 'lodash/throttle';
import { isMobile as isMobileDevice } from 'react-device-detect';
import {
  removeAutoScrollRegistry,
  useAppDispatch,
  useAppSelector,
  userChatsState,
} from 'src/store';
import { useConversation } from './useConversation';

interface UseAutoScrollArgs {
  messageListLength: number;
}

const BOTTOM_SCROLL_THRESHOLD = 100;

// for now is deprecated. probably we will return it later
export const useAutoScroll = ({ messageListLength }: UseAutoScrollArgs) => {
  const dispatch = useAppDispatch();

  const { threadMessageListAnchorRef, threadMessageListRef } =
    useContext(ForwardRefContext);

  const [userScrolledUp, setUserScrolledUp] = useState<boolean>(false);

  const { autoScrollRegistry } = useAppSelector(userChatsState);
  const { currentConversationId, conversationMessages } = useConversation();

  const currentAutoScrollRegistry = useMemo(
    () => autoScrollRegistry[currentConversationId],
    [currentConversationId, autoScrollRegistry],
  );

  const scrolledMessage = useMemo(
    () =>
      conversationMessages.find(
        (item) => item.message_id === currentAutoScrollRegistry,
      ),
    [conversationMessages, currentAutoScrollRegistry],
  );

  useEffect(() => {
    if (isMobileDevice) {
      return;
    }

    const element = threadMessageListRef?.current;
    if (!element) {
      return;
    }

    const handler = () => {
      const scrollPosition = element.scrollTop + element.clientHeight;
      const scrollHeight = element.scrollHeight;
      const isNearBottom =
        scrollPosition >= scrollHeight - BOTTOM_SCROLL_THRESHOLD;
      const isUserScrolled = element.scrollTop >= 0;

      setUserScrolledUp(!isNearBottom && isUserScrolled);
    };

    element.addEventListener('scroll', handler);

    return () => {
      element.removeEventListener('scroll', handler);
    };
  }, [
    threadMessageListRef,
    // (olha): we need it for trigger effect
    messageListLength,
  ]);

  useEffect(() => {
    if (
      isMobileDevice ||
      !currentAutoScrollRegistry ||
      !threadMessageListAnchorRef?.current ||
      !scrolledMessage ||
      userScrolledUp
    ) {
      return;
    }

    const throttledScrollToBottom = throttle(() => {
      if (threadMessageListAnchorRef?.current) {
        threadMessageListAnchorRef.current.onScrollToBottomMessageList(0);
      }
    }, 100);

    throttledScrollToBottom();

    if (scrolledMessage.is_final_answer) {
      dispatch(
        removeAutoScrollRegistry({
          conversationId: scrolledMessage.conversation_id || '',
          messageId: scrolledMessage.message_id || '',
        }),
      );
    }
  }, [
    currentAutoScrollRegistry,
    threadMessageListAnchorRef,
    userScrolledUp,
    scrolledMessage,
    dispatch,
  ]);
};
