import classNames from 'classnames';
import { PlanOption, PlanSectionType } from 'src/types';
import {
  PLAN_FREE_FEATURES,
  PLAN_PRO_FEATURES,
  SVG_SIZE_M,
} from 'src/constants';
import { UpgradeForm } from '../UpgradeForm';
import './PlanSection.scss';
import { lazy, Suspense } from 'react';
import { useBreakpoint, useSession } from 'src/hooks';
import { useCollapse } from 'react-collapsed';
import { CollapsedButton } from 'src/components/FlatAppearance/components/CollapsedButton';
import { CheckCircle } from '@phosphor-icons/react';

const LazyFeatureItem = lazy(() =>
  import('../FeatureItem').then((module) => ({ default: module.FeatureItem })),
);

interface PlanSectionProps {
  type: PlanSectionType;
  onClose: () => void;
}

export const PlanSection = ({ type, onClose }: PlanSectionProps) => {
  const { isProTrialTier, isFreeTier } = useSession();
  const { isMobile } = useBreakpoint();
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse();
  const isPro = type === 'pro';

  const ListOfFeatures = (type: PlanSectionType) =>
    (type === 'pro' ? PLAN_PRO_FEATURES : PLAN_FREE_FEATURES).map(
      ({ title, withBorder, link, isExternalModels }: PlanOption) => (
        <Suspense key={title}>
          <LazyFeatureItem
            title={title}
            withBorder={withBorder}
            type={type}
            link={link}
            isExternalModels={isExternalModels}
          />
        </Suspense>
      ),
    );

  return (
    <div className={classNames('nj-plan-section', { active: isPro })}>
      <h5 className="nj-plan-section--title-wrapper">
        <span className="nj-plan-section--title">
          Ninja{isPro ? ' PRO' : ''}
        </span>
        {isProTrialTier && isPro && (
          <span className="nj-plan-section--title-label">
            Currently in trial
          </span>
        )}
        {isFreeTier && !isPro && (
          <span className="nj-plan-section--title-label">Current plan</span>
        )}
      </h5>

      <p className="nj-plan-section--description">
        {isPro
          ? 'For advanced users needing enhanced features with flexible monthly credits - $10, $20 & $30.'
          : 'For casual users needing quick answers with limited external model access.'}
      </p>

      {isPro ? (
        <UpgradeForm />
      ) : (
        <div className="nj-plan-section--current-plan">
          <p className="nj-plan-section--current-plan-price">
            <span className="nj-plan-section--current-plan-title">
              $0&nbsp;
            </span>
            <span className="nj-plan-section--current-plan-subtitle">/mo</span>
          </p>

          {isProTrialTier ? (
            <span className="nj-plan-section--current-trial-description">
              Automatically applied when Ninja PRO trial ends.
            </span>
          ) : (
            <button
              className="nj-button nj-plan-section--free-button"
              onClick={onClose}
              type="button"
            >
              Continue as FREE
            </button>
          )}
        </div>
      )}

      {/*todo need to refactoring this part*/}
      <ul className="nj-plan-section--feature-list">
        {!isPro &&
          (isMobile ? (
            <>
              <div
                className="nj-accordion--header"
                {...getToggleProps({
                  onClick: () => {
                    setExpanded((prevExpanded) => !prevExpanded);
                  },
                })}
              >
                <div className="nj-accordion--label">
                  <div className="nj-accordion--label-content">
                    <p className="nj-accordion--title">
                      View whats included in Ninja
                    </p>
                  </div>
                </div>
                <div className="nj-accordion--label-right-side">
                  <CollapsedButton isExpanded={isExpanded} />
                </div>
              </div>
              <div {...getCollapseProps()}>
                <div className="nj-accordion--content-wrapper">
                  {ListOfFeatures('free')}
                </div>
              </div>
            </>
          ) : (
            <>
              <li className="nj-plan-section--feature-list-title">
                12 tasks per day
              </li>
              {ListOfFeatures('free')}
            </>
          ))}

        {isPro &&
          (isMobile ? (
            <>
              <div
                className="nj-accordion--header"
                {...getToggleProps({
                  onClick: () => {
                    setExpanded((prevExpanded) => !prevExpanded);
                  },
                })}
              >
                <div className="nj-accordion--label">
                  <div className="nj-accordion--label-content">
                    <p className="nj-accordion--title">
                      View whats included in Ninja PRO
                    </p>
                  </div>
                </div>
                <div className="nj-accordion--label-right-side">
                  <CollapsedButton isExpanded={isExpanded} />
                </div>
              </div>
              <div {...getCollapseProps()}>
                <div className="nj-accordion--content-wrapper">
                  {ListOfFeatures('pro')}
                </div>
              </div>
            </>
          ) : (
            <>
              <li className="nj-plan-section--feature-list-title">
                <span>Unlimited tasks</span>
                <div className="nj-plan-section--pro-label">
                  <CheckCircle size={SVG_SIZE_M} weight="fill" />
                  <span className="nj-plan-section--pro-label-text">
                    included with PRO
                  </span>
                </div>
              </li>
              {ListOfFeatures('pro')}
            </>
          ))}
      </ul>
    </div>
  );
};
