import { useState } from 'react';
import { getErrorMessage } from 'src/utils';
import { fetchUserById, useAppDispatch } from 'src/store';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useSession, useUserLocation } from 'src/hooks';
import log from 'src/utils/logger';
import { LocationSource } from 'src/types';

export const useAuthUser = () => {
  const { appUser } = useSession();
  const dispatch = useAppDispatch();
  const [isUserLoading, setIsUserLoading] = useState(false);

  const { setLocationInterval } = useUserLocation();

  const fetchUserInfo = async (userId: string) => {
    try {
      if (!appUser?.user_id) {
        setIsUserLoading(true);
      }

      const resultAction = await dispatch(fetchUserById(userId));
      let tier_id = 'unknown';
      // set type to the resultAction
      if (
        fetchUserById.fulfilled.match(resultAction) &&
        resultAction.payload.location?.source === LocationSource.GPS
      ) {
        setLocationInterval();
      }

      if (
        fetchUserById.fulfilled.match(resultAction) &&
        resultAction.payload.tier_id
      ) {
        tier_id = resultAction.payload.tier_id;
      }

      datadogLogs.logger.info('User logged in', {
        user_id: userId,
        tier_id: tier_id,
      });

      // Set user data in session
      datadogRum.setUser({
        // Use the user ID from Cognito, it seems there is a delay
        // for appUser.user_id to be available
        id: userId,
        tier_id: tier_id,
        // name: getFullName(appUser),
        // email: appUser?.email
      });

      setIsUserLoading(false);
    } catch (e: unknown) {
      datadogLogs.logger.error(getErrorMessage(e), {
        user_id: appUser?.user_id,
      });
      log.error(e);
      setIsUserLoading(false);
    }
  };

  return {
    fetchUserInfo,
    isUserLoading,
  };
};
