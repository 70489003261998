import { Link } from 'react-router-dom';

export const UpsellChatsMessage = () => {
  return (
    <div className="nj-upsell-message-chats">
      <span>To save your chat history </span>
      <div>
        <Link to="/sign-up">Sign up</Link> or <Link to="/login">Login</Link>
      </div>
    </div>
  );
};
