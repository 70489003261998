import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { Modal } from 'src/components/Modal';
import { PlanSection } from './components/PlanSection';
import './UpgradeModal.scss';
import { PlanSectionType } from 'src/types';
import { useBreakpoint } from 'src/hooks';

export const UpgradeModal = () => {
  const { isVisibleUpgradeModal, onToggleVisibleUpgradeModal } =
    useContext(SessionContext);
  const { isMobile } = useBreakpoint();

  const PlanSections = isMobile ? ['pro', 'free'] : ['free', 'pro'];

  const handleClose = () => {
    onToggleVisibleUpgradeModal();
  };

  return (
    <Modal
      className="nj-upgrade-modal"
      overlayClassName="nj-upgrade-modal-overlay"
      open={isVisibleUpgradeModal}
      onClose={handleClose}
      blockScroll={false}
    >
      <div className="nj-upgrade-modal--container">
        <div className="nj-upgrade-modal--header">
          <h5 className="nj-upgrade-modal--main-title">Let's get started</h5>
        </div>

        {/* TODO(olha): monthly/early switch will be add later */}

        <div className="nj-upgrade-modal--content-wrapper">
          <div className="nj-upgrade-modal--content">
            {PlanSections.map((item) => (
              <PlanSection
                key={item}
                type={item as PlanSectionType}
                onClose={handleClose}
              />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};
