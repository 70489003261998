import {
  ChitChatCard,
  CodeTaskCreationCard,
  ConversationRole,
  Message,
  MessageType,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  ImageCard,
} from 'src/types';
import { ThreadMessageContent } from '../ThreadMessageContent';
import { ThreadCombinedCard } from '../ThreadCombinedCard';
import { ThreadResearchCard } from '../ThreadResearchCard';
import { ThreadScheduleCard } from '../ThreadScheduleCard';
import { DebugMessageWrapper } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/DebugMessageWrapper';
import { ThreadCodeCard } from 'src/components/FlatAppearance/components/ThreadCodeCard';
import { ThreadErrorMessageContent } from 'src/components/FlatAppearance/components/ThreadErrorMessageContent';
import { ErrorPayload } from 'src/types/models/ErrorPayload';
import log from 'src/utils/logger';
import { ThreadChitChatCard } from '../ThreadChitChatCard';
import { ThreadImageGenerationCard } from 'src/components/FlatAppearance/components/ThreadImageGenerationCard';

interface ThreadMessageBodyProps {
  message: Message;
}

export const ThreadMessageBody = ({ message }: ThreadMessageBodyProps) => {
  const { message_type, payload, is_final_answer, role } = message;

  const shouldStreaming = !is_final_answer && role !== ConversationRole.USER;

  const getCardToDisplay = () => {
    switch (message_type) {
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        if (!payload) {
          log.warn('empty payload in the message', message);
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ResearchTaskCreationCard}>
            <ThreadResearchCard
              researchCard={(payload as ResearchTaskCreationCard).research_card}
              // TODO(olha): this is a workaround! needs to change condition and moves to child components to avoid chain of props!!!
              isStreaming={shouldStreaming}
              originalQuery={message.clean_query}
              taskId={(payload as ResearchTaskCreationCard)?.task_id}
            />
          </ThreadCombinedCard>
        );
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        if (!payload) {
          log.warn('empty payload in the message', message);
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as SchedulerCreationCard}>
            <ThreadScheduleCard
              calendarCard={(payload as SchedulerCreationCard).calendar_card}
              details={(payload as SchedulerCreationCard).details}
              //TODO(olha): add prop from BE
              summary=""
            />
          </ThreadCombinedCard>
        );
      case MessageType.CODE_TASK_CREATION_CARD:
        if (!payload) {
          log.warn('empty payload in the message', message);
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ResearchTaskCreationCard}>
            <ThreadCodeCard
              codeCard={(payload as CodeTaskCreationCard).code_card}
              isStreaming={shouldStreaming}
            />
          </ThreadCombinedCard>
        );
      case MessageType.ERROR_MESSAGE:
        return (
          <ThreadErrorMessageContent
            content={message.content}
            errorCode={(payload as ErrorPayload).error_code}
            unsupportedSkills={(payload as ErrorPayload).unsupported_skills}
          />
        );
      case MessageType.CHAT_CARD:
        if (!payload) {
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ChitChatCard}>
            <ThreadChitChatCard
              chitChatCard={payload as ChitChatCard}
              isStreaming={shouldStreaming}
              originalQuery={message.clean_query}
            />
          </ThreadCombinedCard>
        );
      case MessageType.IMAGE_CARD:
        if (!payload) {
          return null;
        }
        return (
          <ThreadCombinedCard data={payload as ImageCard}>
            <ThreadImageGenerationCard imageCard={payload as ImageCard} />
          </ThreadCombinedCard>
        );
      default:
        return (
          <ThreadMessageContent
            content={message.content}
            isStreaming={shouldStreaming}
          />
        );
    }
  };

  return (
    <DebugMessageWrapper message={message}>
      {getCardToDisplay()}
    </DebugMessageWrapper>
  );
};
