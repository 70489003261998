import { useCallback } from 'react';
import { ChatMode, Tag } from 'src/types';
import { translateText, translations, addEmptyWaitingMessage } from 'src/utils';
import { SYNC_WITH_AUDIO, AUTO_DETECT } from 'src/constants';
import {
  useSession,
  useAvatarPreferences,
  useThreads,
  useConversation,
} from 'src/hooks';

export const useTranslateMessages = () => {
  const { avatarCCLocale, avatarAudioLocale } = useAvatarPreferences();
  const { ignoreMessages } = useThreads();
  const { chatMode } = useSession();
  const { conversationMessages } = useConversation();

  const isAvatarMode = chatMode === ChatMode.AVATAR;
  // TODO(olha): handleMessageTranslations was moved from MultiConversationsMessagesList. Needs refactoring
  const handleMessageTranslations = useCallback(async () => {
    const avatarCCLanguage =
      avatarCCLocale === SYNC_WITH_AUDIO
        ? avatarAudioLocale !== AUTO_DETECT
          ? avatarAudioLocale.split('-')[0]
          : 'en'
        : avatarCCLocale.split('-')[0];

    // if waiting messages was added at the end,
    // remove it to handle translations
    const lastMessage = conversationMessages?.at(-1);
    const onlyMessages =
      lastMessage && lastMessage.tag === Tag.WAITING
        ? conversationMessages.slice(0, -1)
        : conversationMessages;

    /**
     * Translate last 4 messages (last 4 visible messages in the chat window)
     * and store them in a map, so next time new messages are added we can
     * only translate the new messages.
     */
    await Promise.all(
      onlyMessages
        .slice(-4)
        .map(
          async (message) =>
            await translateText(
              message.content,
              [avatarCCLanguage],
              avatarAudioLocale,
            ),
        ),
    );

    /**
     * Replace content of messages with translated content.
     */
    const updatedMessages = onlyMessages.map((message) => {
      const translation = translations
        .get(message.content)
        ?.get(avatarCCLanguage);
      if (translation) {
        return { ...message, content: translation };
      }
      return message;
    });

    // setTranslatedMessages(
    return addEmptyWaitingMessage(updatedMessages, ignoreMessages);
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvatarMode, conversationMessages, avatarCCLocale, avatarAudioLocale]);

  return {
    onTranslateMessages: handleMessageTranslations,
  };
};
