import { useEffect, RefObject } from 'react';
import { useConversation } from 'src/hooks';

export const useScrollToBottomThread = (
  endOfMessagesRef: RefObject<HTMLDivElement> | null,
) => {
  const {
    currentConversationId,
    isConversationSuccess,
    isCurrentConversationLoading,
  } = useConversation();

  useEffect(() => {
    if (
      isConversationSuccess &&
      !isCurrentConversationLoading &&
      endOfMessagesRef &&
      endOfMessagesRef.current
    ) {
      endOfMessagesRef.current.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentConversationId,
    isConversationSuccess,
    isCurrentConversationLoading,
    endOfMessagesRef,
  ]);
};
