import { Message } from 'src/types';
import { EDIT_FILE_REGEX } from 'src/constants';
import { UserMessageContent } from '../UserMessageContent';
import { FileImageThumbnail } from 'src/components/FlatAppearance/components/FileImageThumbnail';

interface UserContentWithEditFileProps {
  message: Message;
}

export const UserContentWithEditFile = ({
  message,
}: UserContentWithEditFileProps) => {
  const url =
    message.content.match(EDIT_FILE_REGEX) !== null
      ? (message.content.match(EDIT_FILE_REGEX) || [])[1]
      : '';
  const editedFilename =
    message.content.match(EDIT_FILE_REGEX) !== null
      ? (message.content.match(EDIT_FILE_REGEX) || [])[1].split('/').pop()
      : '';

  const newContent = message.content.replace(EDIT_FILE_REGEX, '');

  return (
    <div className="nj-thread-message--content-with-file-wrapper">
      <UserMessageContent content={newContent} />

      <FileImageThumbnail
        fileName={editedFilename || 'Edited file'}
        url={url}
      />
    </div>
  );
};
