import { useSession } from 'src/hooks';

export const LabelLimitedTime = () => {
  const { isProTrialTier } = useSession();
  return (
    <label className="nj-label-limited-time">
      {isProTrialTier ? 'TRIAL OFFER' : 'LIMITED TIME'}
    </label>
  );
};
