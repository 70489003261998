/* eslint-disable react-hooks/exhaustive-deps */
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import classNames from 'classnames';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { ContentPane } from './ContentPane';
import {
  useSession,
  useAuthUser,
  useTheme,
  useSocket,
  useDocumentHeightChange,
  useQueryParams,
  useTermsServiceAgreement,
} from 'src/hooks';
import { NotificationModal } from './NotificationModal';
import { Loading } from 'src/components/Loading';
import { toast, ToastContainer } from 'react-toastify';
import { PrivateContent } from 'src/components/PrivateContent';
import { PublicContent } from 'src/components/PublicContent';
import AuthContext from 'src/contexts/AuthContext';
import { Banner } from 'src/components/Banner';
import { env } from 'src/env';

datadogRum.init({
  applicationId: `${env.REACT_APP_DATADOG_APP_ID}`,
  clientToken: `${env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
  site: `${env.REACT_APP_DATADOG_SITE}`,
  service: 'ninja_ea_frontend',
  env: `${env.REACT_APP_ENVIRONMENT}`,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: Number(env.REACT_APP_DATADOG_RUM_SESSION_SAMPLE_RATE || 0),
  sessionReplaySampleRate: Number(
    env.REACT_APP_DATADOG_RUM_REPLAY_SAMPLE_RATE || 0,
  ),
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

if (
  env.REACT_APP_ENVIRONMENT === 'beta' ||
  env.REACT_APP_ENVIRONMENT === 'gamma' ||
  env.REACT_APP_ENVIRONMENT === 'production'
) {
  datadogLogs.init({
    clientToken: `${env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
    site: `${env.REACT_APP_DATADOG_SITE}`,
    service: 'ninja_ea_frontend',
    env: `${env.REACT_APP_ENVIRONMENT}`,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    forwardErrorsToLogs: true,
    sessionSampleRate: Number(env.REACT_APP_DATADOG_LOGS_SAMPLE_RATE || 0),
  });
}

const App = () => {
  useDocumentHeightChange();
  const location = useLocation();
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { searchParams } = useQueryParams();
  const { isUserLoading, fetchUserInfo } = useAuthUser();
  const { appUser, agent } = useSession();
  const { isDarkTheme } = useTheme();
  useSocket(appUser.user_id, agent.user_id);
  useTermsServiceAgreement();

  const {
    isGuestAccess,
    shouldFetchUser,
    isAuthenticated,
    isAuthLoading,
    userId: authUserId,
  } = useContext(AuthContext);

  useEffect(() => {
    // Only for Chrome-based browsers. "stale issue" might not happen in other browsers because they use different approach for resource optimization.
    const resumeHandler = (event: unknown) => {
      console.debug('resume', event);
      // re-fetch initial data and reconnect to WS: should be enough to fix "stale issue"
      window.location.reload();
    };
    document.addEventListener('resume', resumeHandler);

    return () => {
      document.removeEventListener('resume', resumeHandler);
    };
  }, []);

  useEffect(() => {
    // load user
    if (!isAuthLoading && authUserId && shouldFetchUser) {
      fetchUserInfo(authUserId);
    }
  }, [isAuthLoading, authUserId, shouldFetchUser]);

  // cognito sign-in error
  const isSignError = searchParams.error && searchParams.error_description;

  useEffect(() => {
    if (isSignError) {
      window.location.replace('/');
    }
  }, [isSignError]);

  const isScreenWithAllowedDarkMode =
    isAuthenticated ||
    (!isAuthenticated && location.pathname === '/') ||
    isAuthLoading ||
    isUserLoading; // todo temporary workaround till auth screens will have dark mode design

  return !isUserLoading &&
    !isAuthLoading &&
    !isSignError &&
    (appUser.user_id || isGuestAccess) ? (
    <div
      ref={rootContainerRef}
      className={classNames('nj-theme', {
        'dark-mode': isDarkTheme && isScreenWithAllowedDarkMode,
      })}
    >
      <ContentPane>
        <Banner />

        {isAuthenticated ? <PrivateContent /> : <PublicContent />}

        <NotificationModal />
        <ToastContainer
          limit={5}
          style={{ width: 'auto', zIndex: 10000010 }} // 10000010 is set to be upper than modals which have 10000000
          position={toast.POSITION.TOP_CENTER}
          hideProgressBar={true}
          autoClose={3000}
          closeButton={false}
        />
      </ContentPane>
    </div>
  ) : (
    <ContentPane>
      <Loading />
    </ContentPane>
  );
};

export default App;
