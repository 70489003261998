import { fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { createContext, useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useResetAllStoredData, useSession } from 'src/hooks';
import {
  useCreateGuestUserMutation,
  useLazyGetUserBudgetQuotaInfoQuery,
  useLazyGetUserByIdQuery,
} from 'src/store/services';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import log from 'src/utils/logger';
import { GUEST_ACCESS_TOKEN } from 'src/constants';
import { useLogout } from 'src/hooks/useLogout';
import { useBanner } from 'src/hooks/useBanner';
import { useQueryParams } from 'src/hooks';
import { BannerType } from 'src/types';
import { UserTierStatus } from 'src/types/models/UserTierStatus';

export type AuthContextType = {
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  isGuestAccess: boolean;
  shouldFetchUser: boolean;
  userId: string | undefined;
  setAuthLoading: (value: boolean) => void;
  isShowOnboardingModal: boolean;
  setIsShowOnboardingModal: (value: boolean) => void;
};

type AuthContextProviderProps = {
  children: React.ReactNode;
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isAuthLoading: true,
  isGuestAccess: true,
  shouldFetchUser: true,
  userId: undefined,
  setAuthLoading: () => undefined,
  isShowOnboardingModal: false,
  setIsShowOnboardingModal: () => undefined,
});

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthLoading, setAuthLoading] = useState(true);
  const [isGuestAccess, setGuessAccess] = useState(false);
  const [shouldFetchUser, setShouldFetchUser] = useState(true);
  const [isShowOnboardingModal, setIsShowOnboardingModal] = useState(false);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [createGuestUser] = useCreateGuestUserMutation();
  const { appUser, updateAppUser, isProTier, isProTrialTier } = useSession();
  const { logout } = useLogout();
  const { addBanner } = useBanner();
  const [triggerGetUserBudget] = useLazyGetUserBudgetQuotaInfoQuery();
  const [triggerGetUserById] = useLazyGetUserByIdQuery();

  const { removeSearchParam } = useQueryParams();
  const { onResetAllStoredData } = useResetAllStoredData();

  // TODO this useEffect needs to refactor and add comments about the logic
  useEffect(() => {
    const createNewGuestUser = async () => {
      try {
        await onResetAllStoredData();

        const fingerprint = await getFingerprint();
        const guest_user_response = await createGuestUser({
          fingerprint: fingerprint as string,
        }).unwrap();
        if (guest_user_response.access_token) {
          localStorage.setItem(
            GUEST_ACCESS_TOKEN,
            guest_user_response.access_token,
          );
          setUserId(guest_user_response.user.user_id);
        }
        // instead of fetch it right after in App.tsx, we can update it from guest response here
        updateAppUser(guest_user_response.user);
        setShouldFetchUser(false);
        setGuessAccess(true);
      } catch (e) {
        log.error('Unable to create new guest user', e);
      }
    };

    const checkAuthenticated = async () => {
      const authSession = await fetchAuthSession();
      if (authSession.tokens) {
        setAuthenticated(true);
        setAuthLoading(false);
        setGuessAccess(false);
        localStorage.removeItem(GUEST_ACCESS_TOKEN);
        setUserId(
          authSession.tokens?.accessToken.payload[
            'custom:ninja_user_id'
          ] as string,
        );
      } else {
        setGuessAccess(true);
        setAuthenticated(false);
        setAuthLoading(false);
        const encodedToken = localStorage.getItem(GUEST_ACCESS_TOKEN);
        if (!encodedToken) {
          createNewGuestUser();
        } else {
          const decodedToken = jwtDecode(encodedToken);
          if (dayjs.unix(Number(decodedToken.exp)).isBefore(dayjs())) {
            createNewGuestUser();
          }
          setUserId(
            (decodedToken as Record<string, string>)['custom:ninja_user_id'],
          );
        }
      }
    };

    checkAuthenticated();

    const updateUserDataAfterLogin = async () => {
      setUserId(undefined);

      await onResetAllStoredData();

      const authSession = await fetchAuthSession();
      if (authSession.tokens) {
        setAuthenticated(true);
        setAuthLoading(false);
        setGuessAccess(false);
        setUserId(
          authSession.tokens?.accessToken.payload[
            'custom:ninja_user_id'
          ] as string,
        );
      }
      try {
        const { data: userData } = await triggerGetUserById(
          authSession.tokens?.accessToken.payload[
            'custom:ninja_user_id'
          ] as string,
        );
        if (userData?.user_id) {
          setIsShowOnboardingModal(true);
          if (
            (isProTier || isProTrialTier) &&
            userData?.tier_status === UserTierStatus.QUOTA_EXCEEDED
          ) {
            addBanner(BannerType.INSUFFICIENT_CREDITS);
          }

          const { data: budgetData } = await triggerGetUserBudget({
            user_id: userData?.user_id || '',
          });

          if (
            (isProTier || isProTrialTier) &&
            userData?.tier_status === UserTierStatus.OK &&
            budgetData &&
            budgetData.is_low_balance
          ) {
            addBanner(BannerType.LOW_CREDITS);
          }
        }
      } catch (e) {
        log.error('Unable to get user and budget info to show banners', e);
      }
    };

    const updateDataAfterLogout = async () => {
      setUserId(undefined);
      setAuthenticated(false);
      setAuthLoading(true);
      await createNewGuestUser();
      setAuthLoading(false);
    };

    const stopListen = Hub.listen('auth', (data) => {
      const event = data?.payload?.event;

      datadogLogs.logger.info(event, {
        user_id: appUser?.user_id,
      });

      switch (event) {
        case 'signedIn':
          updateUserDataAfterLogin();
          removeSearchParam('code');
          removeSearchParam('state');
          return;
        case 'signInWithRedirect_failure':
          setAuthenticated(false);
          setAuthLoading(false);
          datadogLogs.logger.error('Sign in failure', {
            user_id: appUser?.user_id,
          });
          window.location.reload();
          return;
        case 'tokenRefresh_failure':
          logout();
          return;
        case 'signedOut': {
          updateDataAfterLogout();
          return;
        }
        default:
          return;
      }
    });

    return () => {
      stopListen();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAuthLoading,
        setAuthLoading,
        isGuestAccess,
        shouldFetchUser,
        userId,
        isShowOnboardingModal,
        setIsShowOnboardingModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider };
export default AuthContext;
