import { useCallback, useMemo } from 'react';
import { useSearchParams, NavigateOptions } from 'react-router-dom';
import { QueryParams } from 'src/types';
import { sanitizeOutgoingContent } from 'src/utils';

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const typedSearchParams = useMemo(() => {
    const parsedParams: Partial<QueryParams> = {};
    searchParams.forEach((value, key) => {
      parsedParams[key as keyof QueryParams] = sanitizeOutgoingContent(value);
    });
    return parsedParams;
  }, [searchParams]);

  const setTypedSearchParams = useCallback(
    (newParams: Partial<QueryParams>, navigateOpts?: NavigateOptions) => {
      const updatedParams = new URLSearchParams(searchParams);

      Object.keys(newParams).forEach((key) => {
        const value = newParams[key as keyof QueryParams];
        if (value !== undefined) {
          updatedParams.set(key, sanitizeOutgoingContent(value));
        }
      });

      setSearchParams(updatedParams, navigateOpts);
    },
    [searchParams, setSearchParams],
  );

  const removeSearchParam = useCallback(
    (paramName: keyof QueryParams) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(paramName);
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  return {
    searchParams: typedSearchParams,
    setSearchParams: setTypedSearchParams,
    removeSearchParam,
  };
};
