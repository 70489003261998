import {
  FileCsv,
  FileDoc,
  FilePdf,
  FileText,
  FileTxt,
  FileXls,
} from '@phosphor-icons/react';
import { Message } from 'src/types';
import { FileImageThumbnail } from 'src/components/FlatAppearance/components/FileImageThumbnail';
import { UserMessageContent } from '../UserMessageContent';
import { FILE_REGEX, SVG_SIZE_M } from 'src/constants';

interface UserContentWithFileProps {
  message: Message;
}

export const UserContentWithFile = ({ message }: UserContentWithFileProps) => {
  const filename =
    message.content.match(FILE_REGEX) !== null
      ? (message.content.match(FILE_REGEX) || [])[2].replaceAll('"', '')
      : '';
  const originalFilename =
    message.content.match(FILE_REGEX) !== null
      ? (message.content.match(FILE_REGEX) || [])[1].replaceAll('"', '')
      : '';

  const extention = originalFilename.split('.').pop()?.toLowerCase();
  const isImage =
    extention === 'png' || extention === 'jpg' || extention === 'jpeg';

  const newContent = message.content.replace(FILE_REGEX, '');

  const handleFileClick = () => {
    // todo
  };

  const renderExceptionIcon = (type: string) => {
    switch (type) {
      case 'pdf':
        return <FilePdf size={SVG_SIZE_M} />;
      case 'txt':
        return <FileTxt size={SVG_SIZE_M} />;
      case 'docx':
        return <FileDoc size={SVG_SIZE_M} />;
      case 'csv':
        return <FileCsv size={SVG_SIZE_M} />;
      case 'xls':
        return <FileXls size={SVG_SIZE_M} />;
      default:
        return <FileText size={SVG_SIZE_M} />;
    }
  };

  return (
    <div className="nj-thread-message--content-with-file-wrapper">
      <UserMessageContent content={newContent} />

      {isImage ? (
        <FileImageThumbnail
          fileName={filename}
          originalFileName={originalFilename}
        />
      ) : (
        !!originalFilename && (
          <button
            type="button"
            onClick={handleFileClick}
            className=" nj-thread-message--content-with-file-wrapper--file-label"
          >
            <div className="nj-thread-message--content-with-file-wrapper--file-label-text">
              {renderExceptionIcon(extention || '')}
              <span className="nj-thread-message--content-with-file-wrapper--file-name ellipsis">
                {originalFilename}
              </span>
            </div>
          </button>
        )
      )}
    </div>
  );
};
