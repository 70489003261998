import styles from './PlanSelect.module.scss';
import { PaymentPlanOption, PaymentPlanOptionWithSale } from 'src/types';
import { lazy, Suspense, useRef } from 'react';
import { useOutsideClick, useSession, useVisible } from 'src/hooks';
import { CaretDown, CaretUp, DotOutline } from '@phosphor-icons/react';
import { PLAN_OPTIONS, PLAN_OPTIONS_FOR_FREE, SVG_SIZE_L } from 'src/constants';
import { roundPriceAmount } from 'src/components/UpgradeModal/utils/prepareData';

type PlanSelectProps = {
  selectedPlan: PaymentPlanOptionWithSale | PaymentPlanOption;
  onPlanChange: (value: PaymentPlanOptionWithSale | PaymentPlanOption) => void;
};

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const PlanSelect = ({ selectedPlan, onPlanChange }: PlanSelectProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isVisible, handleVisibilityRemove, handleVisibilityToggle } =
    useVisible();
  const { isFreeTier } = useSession();

  useOutsideClick(containerRef, handleVisibilityRemove);

  return (
    <div className={styles.root} ref={containerRef}>
      <button
        onClick={handleVisibilityToggle}
        id="plan-selector-trigger"
        className={styles.triggerButton}
      >
        {isFreeTier ? (
          <div>
            <span className={styles.price}>
              ${roundPriceAmount(selectedPlan.amount)}&nbsp;
            </span>
            <span className={styles.priceDescription}>/mo</span>
          </div>
        ) : (
          <div>
            <span className={styles.price}>
              <s>${roundPriceAmount(selectedPlan.amount)}</s> $
              {(selectedPlan as PaymentPlanOptionWithSale)?.newAmount}&nbsp;
            </span>
            <span className={styles.priceDescription}>/mo for first month</span>
          </div>
        )}
        {isVisible ? (
          <CaretUp size={SVG_SIZE_L} />
        ) : (
          <CaretDown size={SVG_SIZE_L} />
        )}
      </button>

      <Suspense>
        <LazyReactTooltip
          anchorSelect="#plan-selector-trigger"
          clickable
          noArrow
          openOnClick
          disableStyleInjection
          className={styles.planSelectTooltip}
          isOpen={isVisible}
          opacity={1}
          place="bottom-end"
        >
          <div className={styles.menu} onClick={handleVisibilityRemove}>
            {(isFreeTier ? PLAN_OPTIONS_FOR_FREE : PLAN_OPTIONS).map((item) => {
              return (
                <button
                  className={styles.planOption}
                  key={item.lookup_key}
                  onClick={() => onPlanChange(item)}
                >
                  {isFreeTier ? (
                    <span className={styles.planPriceText}>
                      ${roundPriceAmount(item.amount)} /mo
                    </span>
                  ) : (
                    <span className={styles.planPriceText}>
                      ${roundPriceAmount(item.amount)} /mo ($
                      {(item as PaymentPlanOptionWithSale)?.newAmount} for first
                      month)
                    </span>
                  )}

                  {item.lookup_key === selectedPlan.lookup_key && (
                    <DotOutline size={SVG_SIZE_L} weight="fill" />
                  )}
                </button>
              );
            })}
          </div>
        </LazyReactTooltip>
      </Suspense>
    </div>
  );
};
