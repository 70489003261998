import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DotsThreeOutlineVertical,
  IconWeight,
  ArrowUp,
  ChatsCircle,
  Broom,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { DEFAULT_DATE_FORMAT } from 'src/constants';
import SessionContext from 'src/contexts/SessionContext';
import AuthContext from 'src/contexts/AuthContext';
import {
  useBreakpoint,
  useSession,
  useTheme,
  useConversation,
  useTasks,
  useScrollToTask,
} from 'src/hooks';
import { Conversation, ChatsFilter } from 'src/types';
import { Button } from 'src/components/Button';
import { Spinner } from 'src/components/Loading';
import { ConversationControl } from '../ConversationControl';
import { ConversationGroup } from '../ConversationGroup';
import { UpsellChatsMessage } from 'src/components/UpsellChatsMessage';

const SVG_SIZE_LG = 48;

const ICON_PROPS = {
  size: 20,
  color: 'currentColor',
  weight: 'regular' as IconWeight,
};

enum ConversationsListEmptyStates {
  NO_RESULTS = `There are no matches for your criteria.`,
  DEFAULT = `Starting a chat with Ninja creates new conversation threads. Dive in and chat whenever you are ready.`,
}

export const ConversationsList = () => {
  const { isOpenTier } = useSession();
  const { isTasksListExpanded } = useTheme();
  const { isMobile } = useBreakpoint();
  const {
    currentConversationId,
    conversations,
    isConversationsLoading,
    newPageToken,
  } = useConversation();

  const { filteredTasks, isFilteredTasksList, newFilteredTaskPageToken } =
    useTasks();

  const conversationsForRender = useMemo(
    () => (isFilteredTasksList ? filteredTasks : conversations),
    [conversations, filteredTasks, isFilteredTasksList],
  );

  const {
    onOpenTaskListControlsPanel,
    pageToken,
    setPageToken,
    chatsFilter,
    filteredTasksPageToken,
    setFilteredTasksPageToken,
  } = useContext(SessionContext);
  const { isGuestAccess } = useContext(AuthContext);

  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(false);

  const threadsListScrollRef = useRef<HTMLDivElement>(null);

  const [isButtonScrollUpVisible, setIsButtonScrollUpVisible] = useState(false);

  const { activeThreadAnchor, setActiveThreadAnchor } = useScrollToTask();

  const scrollTo = useCallback((x = 0) => {
    if (threadsListScrollRef.current) {
      threadsListScrollRef.current.scrollTo({
        top: x,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    const scrollRef = threadsListScrollRef?.current;

    const onConversationsScroll = () => {
      const {
        scrollTop = 0,
        clientHeight = 0,
        scrollHeight = 0,
      } = scrollRef || {};

      setIsButtonScrollUpVisible(scrollTop > 30);

      if (!scrolledToBottom) {
        if (scrollTop + clientHeight >= scrollHeight) {
          setScrolledToBottom(true);
        }
      }
    };

    scrollRef?.addEventListener('scroll', onConversationsScroll);

    return () => {
      scrollRef?.removeEventListener('scroll', onConversationsScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conversationsForRender.length === 0) {
      setScrolledToBottom(false);
    }

    if (scrolledToBottom && (newPageToken || newFilteredTaskPageToken)) {
      if (!isFilteredTasksList && newPageToken) {
        setPageToken(newPageToken);
      }

      if (isFilteredTasksList && newFilteredTaskPageToken) {
        setFilteredTasksPageToken(newFilteredTaskPageToken);
      }

      setScrolledToBottom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledToBottom]);

  const groupedConversations: Record<string, Conversation[]> = useMemo(() => {
    const acc: Record<string, Conversation[]> = {};

    if (!conversationsForRender) {
      return acc;
    }

    for (const conversation of conversationsForRender) {
      const groupedDate = dayjs(conversation.timestamp).format(
        DEFAULT_DATE_FORMAT,
      );

      if (!acc[groupedDate]) {
        acc[groupedDate] = [conversation];
      } else {
        acc[groupedDate] = [...acc[groupedDate], conversation];
      }
    }

    return acc;
  }, [conversationsForRender]);

  // empty flag for empty states
  const isEmpty = useMemo(() => {
    return Object.keys(groupedConversations).length === 0;
  }, [groupedConversations]);

  // pick the right empty state message
  const emptyStateMessage = useMemo(() => {
    switch (chatsFilter) {
      case ChatsFilter.ALL:
        return (
          <Fragment>
            <span className="nj-task-list--icon">
              <ChatsCircle size={SVG_SIZE_LG} weight="light" />
            </span>
            <span className="nj-task-list--message">
              {ConversationsListEmptyStates.DEFAULT}
            </span>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <span className="nj-task-list--icon">
              <Broom size={SVG_SIZE_LG} weight="light" />
            </span>
            <span className="nj-task-list--message">
              {ConversationsListEmptyStates.NO_RESULTS}
            </span>
          </Fragment>
        );
    }
  }, [chatsFilter]);

  return (
    <div
      className={classNames('nj-task-list', {
        'reduced-height': isGuestAccess,
      })}
      ref={threadsListScrollRef}
    >
      <div className="nj-task-list--header" data-testid="task-header">
        <div className="nj-task-list--header-wrapper">
          <h5
            className="nj-task-list--header-title"
            id="onboarding-element-mobile-2"
          >
            Chats
          </h5>
          <div className="nj-task-list--header-buttons-wrapper">
            {isMobile ? (
              <button onClick={onOpenTaskListControlsPanel}>
                <DotsThreeOutlineVertical {...ICON_PROPS} alt="Tasks menu" />
              </button>
            ) : (
              <ConversationControl type="menu" />
            )}
          </div>
        </div>
      </div>
      <div className="nj-task-list--container">
        {isConversationsLoading ? (
          <Spinner />
        ) : (
          <>
            {Object.entries(groupedConversations).map(([date, group]) => (
              <ConversationGroup
                key={date}
                date={date}
                conversations={group}
                selectedConversationId={currentConversationId}
                activeThreadAnchor={activeThreadAnchor}
                setActiveThreadAnchor={setActiveThreadAnchor}
              />
            ))}
            {isOpenTier && <UpsellChatsMessage />}

            {!isEmpty && (
              <Spinner
                inline={true}
                visibility={
                  (!!pageToken || !!filteredTasksPageToken) && scrolledToBottom
                }
              />
            )}

            {!isConversationsLoading && isEmpty && (
              <div className="nj-task-list--empty-list-warning">
                {emptyStateMessage}
              </div>
            )}
          </>
        )}
      </div>
      <Button
        className={classNames('nj-tasks-list--back-to-top--button', {
          visible: isButtonScrollUpVisible && isTasksListExpanded,
        })}
        onClick={() => scrollTo()}
      >
        <span>
          <ArrowUp {...ICON_PROPS} />
        </span>
      </Button>
    </div>
  );
};
