import { useTheme } from 'src/hooks';

export const AnthropicLogo = ({ size = 20 }: { size?: number }) => {
  const { isDarkTheme } = useTheme();
  const COLOR = isDarkTheme ? 'white' : '#181818';

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_101_14483)">
        <path
          d="M11.2734 4.0249L16.2521 16.5406H18.9808L14.0021 4.0249H11.2734Z"
          fill={COLOR}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.97298 4.00952H8.82808L13.8011 16.5253H11.0187L10.0019 13.898H4.79915L3.78043 16.5253H1L5.97298 4.00952ZM5.69723 11.5714H9.10574L7.40149 7.17293L5.69723 11.5714Z"
          fill={COLOR}
        />
      </g>
      <defs>
        <clipPath id="clip0_101_14483">
          <rect
            width={size - 2}
            height="12.5426"
            fill="white"
            transform="translate(1 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
