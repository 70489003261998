import { usersApi } from 'src/store/services';
import { AppUser, UserSettings } from 'src/types';
import { useSession } from 'src/hooks';
import { toast } from 'react-toastify';
import log from 'src/utils/logger';

const SAVED_TEXT = 'Saved';

export const useUserData = () => {
  const { appUser, updateAppUser } = useSession();

  const [updateUserMutation] = usersApi.useUpdateUserMutation();

  const updateUserData = async (
    data: Partial<AppUser>,
    showNotificationToast = true,
    notificationText?: string,
  ) => {
    try {
      const request = {
        id: appUser.user_id,
        body: {
          ...data,
        },
      };

      await updateUserMutation(request).unwrap();

      // update session
      updateAppUser({
        ...appUser,
        ...data,
      });

      // display toast
      if (showNotificationToast) {
        toast(notificationText || SAVED_TEXT);
      }
    } catch (error) {
      log.error(error);
    }
  };

  const updateUserSettings = async (
    data: Partial<UserSettings>,
    notificationText?: string,
  ) => {
    try {
      await updateUserData(
        {
          settings: {
            ...appUser.settings,
            ...data,
          },
        },
        true,
        notificationText,
      );
    } catch (e) {
      log.error(e);
    }
  };

  return {
    updateUserData,
    updateUserSettings,
  };
};
