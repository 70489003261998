import { RenewEarlyButton } from 'src/components/RenewEarlyButton';
// import { RemainingTasksSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/RemainingTasksSection';
import styles from './InsufficientCreditsSection.module.scss';

export const InsufficientCreditsSection = () => {
  return (
    <div className={styles.root}>
      <span className={styles.label}>Insufficient credits</span>
      <p className={styles.description}>
        Renew your subscription early to continue access to PRO features.
      </p>
      {/*<RemainingTasksSection />*/}
      <RenewEarlyButton className={styles.button} />
    </div>
  );
};
