import { Warning } from '@phosphor-icons/react';
import { useBreakpoint, useSession } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { SVG_SIZE_L } from 'src/constants';

export const InsufficientCreditsBanner = () => {
  const { appUser, isProTrialTier } = useSession();
  const { isTabletAndUp, isMobileOrTablet } = useBreakpoint();
  const { onChangeIsVisibleUpdatePlanModal, onToggleVisibleUpgradeModal } =
    useContext(SessionContext);

  const handleRenewEarlyClick = () => {
    onChangeIsVisibleUpdatePlanModal(true);
  };

  const handleUpgradeClick = () => {
    onToggleVisibleUpgradeModal(true);
  };

  return (
    <div className="nj-common-banner">
      <div className="nj-common-banner--left-side-wrapper">
        <Warning size={SVG_SIZE_L} weight="fill" color="#D74E45" />
        {isProTrialTier ? (
          <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
            <h5 className="nj-common-banner--title">
              You’ve used up all your credits for external models
            </h5>
          </div>
        ) : (
          <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
            <h5 className="nj-common-banner--title">
              {isTabletAndUp ? 'Insufficient credits' : 'Insufficient funds'}
            </h5>
            {!isMobileOrTablet && (
              <p className="nj-common-banner--description ellipsis">
                Renew your subscription early to continue access to PRO
                features.
              </p>
            )}
          </div>
        )}
      </div>
      <div className="nj-common-banner--right-side-wrapper">
        {isProTrialTier ? (
          <button
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleUpgradeClick}
          >
            Upgrade
          </button>
        ) : (
          <button
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleRenewEarlyClick}
            disabled={!!appUser?.stripe_info?.renew_early_pending_subscription}
          >
            Renew early
          </button>
        )}
      </div>
    </div>
  );
};
