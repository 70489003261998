import { useCallback, useMemo, useContext, useEffect } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  useGetConversationByIdQuery,
  useUpdateConversationByIdMutation,
  useGetConversationsQuery,
  useArchiveAllConversationsMutation,
} from 'src/store/services';
import { useAppSelector, sessionState } from 'src/store';
import { useQueryParams } from './useQueryParams';
import { convertSlugToConversationId } from 'src/utils';
import { DEFAULT_CHAT_ID, TEMPORARY_CONVERSATION_ID } from 'src/constants';
import { ChatsFilter, ConversationUpdateRequest } from 'src/types';
import log from 'src/utils/logger';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

// TODO(olha): divide into two separate hooks: useConversationsList, useCurrentConversation, useConversationActions
export const useConversation = () => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const {
    searchParams: { conv },
  } = useQueryParams();

  const { chatsFilter, pageToken } = useContext(SessionContext);

  const includeArchived = useMemo(
    () => chatsFilter === ChatsFilter.ALL_AND_ARCHIVED,
    [chatsFilter],
  );

  const { data: conversationListData, isLoading: isConversationsLoading } =
    useGetConversationsQuery(
      {
        user_id,
        pageToken,
        includeArchived,
      },
      { skip: !user_id },
    );

  const isTemporaryConversationId = useMemo(
    () => conv?.includes(TEMPORARY_CONVERSATION_ID),
    [conv],
  );

  const currentConversationId = useMemo(
    () =>
      isTemporaryConversationId && conv
        ? conv
        : // TODO(olha): DEFAULT_CHAT_ID is deprecated, should be removed
          convertSlugToConversationId(conv) || DEFAULT_CHAT_ID,
    [isTemporaryConversationId, conv],
  );

  const isLandingPage = useMemo(
    () => currentConversationId === DEFAULT_CHAT_ID,
    [currentConversationId],
  );

  const shouldSkipGetConversationById = isLandingPage || !user_id;

  const {
    data: conversationData,
    isLoading: isConversationLoading,
    isFetching: isConversationFetching,
    isError: isConversationError,
    error: conversationError,
    isSuccess: isConversationSuccess,
  } = useGetConversationByIdQuery(
    {
      user_id,
      conversation_id: currentConversationId,
    },
    {
      skip: shouldSkipGetConversationById,
    },
  );

  // TODO(olha): to avoid "window.location.replace" multiple calls it should be moved to one of components
  useEffect(() => {
    // Redirect to the main page if the conversation is not found
    if (
      isConversationError &&
      isFetchBaseQueryError(conversationError) &&
      conversationError?.status === 404
    ) {
      window.location.replace('/');
    }
  }, [isConversationError, conversationError]);

  const currentConversation = useMemo(
    () => (!isLandingPage ? conversationData : undefined),
    [conversationData, isLandingPage],
  );

  const [updateConversationById] = useUpdateConversationByIdMutation();

  const handleUpdateConversationById = useCallback(
    async (conversation: Omit<ConversationUpdateRequest, 'user_id'>) => {
      try {
        await updateConversationById({ user_id, ...conversation }).unwrap();
      } catch (error) {
        log.error(error);
      }
    },
    [updateConversationById, user_id],
  );

  const [archiveAllConversations, { isLoading: isArchiveAllLoading }] =
    useArchiveAllConversationsMutation();

  const handleArchiveAllConversations = useCallback(async () => {
    await archiveAllConversations({ user_id }).unwrap();
  }, [user_id, archiveAllConversations]);

  const getTaskFromConversationById = useCallback(
    (task_id?: string) => {
      if (!task_id) {
        return;
      }

      return currentConversation?.tasks?.find(
        (item) => item.task_id === task_id,
      );
    },
    [currentConversation],
  );

  return useMemo(
    () => ({
      conversations: conversationListData?.data || [],
      newPageToken: conversationListData?.page_token || '',
      isConversationsLoading,
      isLandingPage,
      currentConversationId,
      currentConversation,
      conversationMessages: conversationData?.messages || [],
      isCurrentConversationLoading:
        isConversationLoading || isConversationFetching,
      isArchiveAllLoading,
      isConversationSuccess,
      updateConversationById: handleUpdateConversationById,
      archiveAllConversations: handleArchiveAllConversations,
      getTaskFromConversationById,
    }),
    [
      conversationListData,
      isConversationsLoading,
      isLandingPage,
      currentConversationId,
      currentConversation,
      conversationData?.messages,
      isConversationLoading,
      isConversationFetching,
      isArchiveAllLoading,
      isConversationSuccess,
      handleUpdateConversationById,
      handleArchiveAllConversations,
      getTaskFromConversationById,
    ],
  );
};
