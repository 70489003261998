import {
  useEffect,
  useRef,
  useState,
  Fragment,
  useContext,
  useImperativeHandle,
  forwardRef,
} from 'react';
import classNames from 'classnames';
import ForwardRefContext, {
  ThreadMessageListHandlers,
} from 'src/contexts/ForwardRefContext';
import { ChatMode, ConversationRole, Message } from 'src/types';
import {
  addEmptyWaitingMessage,
  prepareMessagesToRender,
  formatByDayjsMonthDay,
} from 'src/utils';
import {
  useSession,
  useAvatarPreferences,
  useScrollToBottomThread,
  useThreads,
  useTranslateMessages,
  useTimeZone,
  useTheme,
  useConversation,
  useAutoScroll,
} from 'src/hooks';
import { ThreadMessage } from '../ThreadMessage';
import { ThreadDividerLine } from '../ThreadDividerLine';
import { NinjaLoader } from 'src/components/Loading';
import { UserMessage } from 'src/components/UserMessage';

export const ThreadMessageList = forwardRef<ThreadMessageListHandlers>(() => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { threadMessageListAnchorRef, threadMessageListRef, endOfMessagesRef } =
    useContext(ForwardRefContext);

  const { avatarCCLocale, avatarAudioLocale } = useAvatarPreferences();
  const { ignoreMessages } = useThreads();
  const { chatMode } = useSession();
  const { isSettingsPanelExpanded } = useTheme();
  const { conversationMessages, isCurrentConversationLoading } =
    useConversation();

  const { userTimeZone } = useTimeZone();

  useScrollToBottomThread(endOfMessagesRef);

  const [translatedMessages, setTranslatedMessages] = useState<Message[]>([]);

  const { onTranslateMessages } = useTranslateMessages();

  const handleScrollToBottom = (delay = 1000) => {
    setTimeout(() => {
      endOfMessagesRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, delay);
  };

  useImperativeHandle(threadMessageListAnchorRef, () => ({
    onScrollToBottomMessageList: handleScrollToBottom,
  }));

  const isAvatarMode = chatMode === ChatMode.AVATAR;

  // TODO(olha): moved from MultiConversationsMessagesList. Try to call translation by clicking to the avatar mode
  useEffect(() => {
    if (isAvatarMode) {
      const translateMessages = async () => {
        const result = await onTranslateMessages();
        setTranslatedMessages(result);
      };

      translateMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conversationMessages,
    avatarCCLocale,
    avatarAudioLocale,
    isAvatarMode,
    onTranslateMessages,
  ]);

  // attn(olha): we removed useMemo to avoid some cache issues.
  const messagesToRender = prepareMessagesToRender(
    !isAvatarMode
      ? addEmptyWaitingMessage(conversationMessages, ignoreMessages)
      : translatedMessages,
  );

  useAutoScroll({
    messageListLength: messagesToRender.length,
  });

  if (isCurrentConversationLoading) {
    return <NinjaLoader content="Ninja is loading the content" />;
  }

  return (
    <div
      ref={threadMessageListRef}
      className={classNames('nj-thread-message-list', {
        'avatar-mode': isAvatarMode,
        'is-settings-panel-expanded': isSettingsPanelExpanded,
        'with-min-height': conversationMessages.length !== 0,
      })}
    >
      <div ref={containerRef} className="nj-thread-message-list-wrapper">
        {messagesToRender.map(
          ({ showDate, showSkillDivider, anchorId, ...message }, index) => (
            // TODO(olha): Double-check using index here. We already have message_id for all messages
            <Fragment key={message.message_id || index}>
              {showDate && message.timestamp && (
                <p className="nj-thread-message-list--date-line">
                  {formatByDayjsMonthDay(message.timestamp, userTimeZone)}
                </p>
              )}

              <ThreadDividerLine anchorId={anchorId} />

              {message.role === ConversationRole.USER ? (
                <UserMessage message={message} />
              ) : (
                <ThreadMessage
                  message={message}
                  canShowProgress={index === messagesToRender.length - 1}
                />
              )}
            </Fragment>
          ),
        )}

        <span
          // anchor for scrolling to the bottom
          className="nj-thread-message-list--anchor"
          ref={endOfMessagesRef}
        />
      </div>
    </div>
  );
});
