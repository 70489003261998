import { useEffect, useMemo, useRef } from 'react';
import { BannerType, UserTierStatus } from 'src/types';
import { CSSTransition } from 'react-transition-group';
import { InsufficientCreditsBanner } from 'src/pages/ManageTasksChatPage/components/InsufficientCreditsBanner';
import { useBanner } from 'src/hooks/useBanner';
import { LowCreditsBanner } from 'src/pages/ManageTasksChatPage/components/LowCreditsBanner';
import { ANIMATION_TIMEOUT, SVG_SIZE_M } from 'src/constants';
import { BannerPagination } from 'src/components/Banner/components/BannerPagination';
import { XCircle } from '@phosphor-icons/react';
import { LowTasksBanner } from 'src/pages/ManageTasksChatPage/components/LowTasksBanner';
import { useSession } from 'src/hooks';
import {
  useLazyGetUserBudgetQuotaInfoQuery,
  useLazyGetUserTaskQuotaInfoQuery,
} from 'src/store/services';
import { LowDaysBanner } from 'src/pages/ManageTasksChatPage/components/LowDaysBanner';
import { AvatarQueueBanner } from 'src/pages/ManageTasksChatPage/components/AvatarQueueBanner';
import { AvatarIdleBanner } from 'src/pages/ManageTasksChatPage/components/AvatarIdleBanner';
import { useAvatarBanner } from 'src/hooks';

export const Banner = () => {
  const {
    bannersSet,
    removeBanner,
    currentBannerIndex,
    addBanner,
    removeAllPaymentBanners,
    addToBlackListBanner,
    blackListBanners,
  } = useBanner();
  const { appUser, isProTrialTier, isFreeTier, isProTier, isOpenTier } =
    useSession();
  const [triggerGetUserBudget] = useLazyGetUserBudgetQuotaInfoQuery();
  const [triggerGetUserTasksCount] = useLazyGetUserTaskQuotaInfoQuery();

  const checkBudget = async () => {
    const { data: budgetData } = await triggerGetUserBudget({
      user_id: appUser?.user_id || '',
    });

    if (budgetData && budgetData.is_low_balance) {
      addBanner(BannerType.LOW_CREDITS);
    }
  };

  const checkTasks = async () => {
    const { data: quotaData } = await triggerGetUserTasksCount({
      user_id: appUser?.user_id || '',
    });

    if (quotaData && quotaData?.count !== null && quotaData?.count <= 3) {
      addBanner(BannerType.LOW_TASKS);
    }
  };

  useEffect(() => {
    removeAllPaymentBanners();

    if (isProTrialTier && !blackListBanners[BannerType.LOW_DAYS]?.isHidden) {
      addBanner(BannerType.LOW_DAYS);
    }
    if (isProTier || isProTrialTier) {
      if (appUser.tier_status === UserTierStatus.QUOTA_EXCEEDED) {
        addBanner(BannerType.INSUFFICIENT_CREDITS);
      }
      checkBudget();
    }

    if (isFreeTier || isOpenTier) {
      checkTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appUser.tier_id,
    appUser.tier_status,
    appUser.tier_expiration_date,
    blackListBanners,
  ]);

  const { onCloseAvatarQueueBanner } = useAvatarBanner();

  const containerRef = useRef<HTMLDivElement>(null);

  const currentBanner = useMemo(
    () => bannersSet?.[currentBannerIndex],
    [bannersSet, currentBannerIndex],
  );

  const renderContent = useMemo(() => {
    switch (currentBanner) {
      case BannerType.LOW_CREDITS:
        return <LowCreditsBanner />;
      case BannerType.INSUFFICIENT_CREDITS:
        return <InsufficientCreditsBanner />;
      case BannerType.LOW_TASKS:
        return <LowTasksBanner />;
      case BannerType.LOW_DAYS:
        return <LowDaysBanner />;
      case BannerType.AVATAR_IDLE:
        return <AvatarIdleBanner />;
      case BannerType.AVATAR_QUEUE:
        return <AvatarQueueBanner />;
      default:
        return <></>;
    }
  }, [currentBanner]);

  const handleCloseBanner = () => {
    if (currentBanner === BannerType.LOW_DAYS) {
      addToBlackListBanner(BannerType.LOW_DAYS);
    }

    if (currentBanner === BannerType.AVATAR_QUEUE) {
      onCloseAvatarQueueBanner();
    }

    removeBanner(currentBanner);
  };

  return (
    <CSSTransition
      in={bannersSet.length !== 0}
      timeout={ANIMATION_TIMEOUT}
      classNames="nj-animate-vertical-appearance-banner"
      unmountOnExit
      nodeRef={containerRef}
    >
      <div ref={containerRef} className="nj-banner-wrapper">
        <BannerPagination />
        {renderContent}
        <button type="button" onClick={handleCloseBanner}>
          <XCircle size={SVG_SIZE_M} weight="fill" />
        </button>
      </div>
    </CSSTransition>
  );
};
