import styles from './UpsellMessageBlock.module.scss';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';
import { useSession } from 'src/hooks';
import { useMemo } from 'react';
import { UserTier } from 'src/types/models/UserTier';
import { LabelPro } from 'src/components/LabelPro';

export const UpsellMessageBlock = () => {
  const { isOpenTier, isProTrialTier, isFreeTier, appUser } = useSession();
  const title = useMemo(() => {
    switch (appUser?.tier_id) {
      case UserTier.OPEN:
        return 'Sign up for Ninja PRO Trial';
      case UserTier.PRO_TRIAL:
        return 'Upgrade to Ninja PRO: $1 for first month';
      case UserTier.FREE:
        return 'Upgrade to Ninja PRO';
      case UserTier.TESTING:
      case UserTier.PAID:
      case UserTier.PRO_ZERO:
        return <LabelPro />;

      default:
        return '';
    }
  }, [appUser]);

  const description = useMemo(() => {
    switch (appUser?.tier_id) {
      case UserTier.OPEN:
        return 'Enjoy 7 days of unlimited access to Ninja Agents and receive $5 in credits for external model usage.';
      case UserTier.PRO_TRIAL:
        return 'Continue enjoying PRO features - Upgrade now!';
      case UserTier.FREE:
        return 'Enjoy unlimited access to Ninja and receive credits towards 24+ external models.';
      case UserTier.TESTING:
      case UserTier.PAID:
      case UserTier.PRO_ZERO:
        return <LabelPro />;

      default:
        return '';
    }
  }, [appUser]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div>
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>{title}</h4>
            {isProTrialTier && <span className={styles.tag}>Trial Offer</span>}
            {isOpenTier && <span className={styles.tag}>Limited time</span>}
          </div>
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.buttonWrapper}>
          {isOpenTier && <GuestAuthLinks />}
          {(isProTrialTier || isFreeTier) && <UpgradeLearnMoreButtons />}
        </div>
      </div>
    </div>
  );
};
