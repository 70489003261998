import { ChangeEvent, lazy, Suspense, useContext, useRef } from 'react';
import { Paperclip, PlusCircle } from '@phosphor-icons/react';
import { isDesktop as isDesktopDevice } from 'react-device-detect';
import { AttachmentFileInputId, SVG_SIZE_L } from 'src/constants';
import {
  useAttachFile,
  useBreakpoint,
  useOutsideClick,
  useSession,
  useVisible,
} from 'src/hooks';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { useGetUserTaskQuotaInfoQuery } from 'src/store/services';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const AddAttachmentButton = () => {
  const { addFile } = useAttachFile();
  const { isMobile } = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);
  const { threadInputBoxFile } = useContext<ThreadInputBoxContextType>(
    ThreadInputBoxContext,
  );
  const {
    isVisible: isVisibleTooltip,
    handleVisibilitySet: onShowTooltip,
    handleVisibilityRemove: onHideTooltip,
  } = useVisible();
  useOutsideClick(containerRef, onHideTooltip);

  const { isOpenTier, isFreeTier, appUser, isProTrialTier, isProTier } =
    useSession();

  const {
    data: quotaData,
    isLoading,
    isError,
    isFetching,
  } = useGetUserTaskQuotaInfoQuery(
    {
      user_id: appUser.user_id,
    },
    {
      skip: !appUser.user_id || isProTier || isProTrialTier,
    },
  );

  const handleAttachClick = () => {
    if ((isOpenTier || isFreeTier) && !!quotaData && quotaData?.count === 0) {
      onShowTooltip();
    } else {
      document.getElementById(AttachmentFileInputId)?.click();
    }
  };

  const handleFileSelect = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement)?.files;
    const file = files ? files[0] : null;
    if (file) {
      await addFile(file);
    }
  };

  const isDisabled = isLoading || isFetching || isError;

  return (
    <div ref={containerRef}>
      <button
        className="nj-thread-input-box--attach-button"
        disabled={isDisabled}
        onClick={handleAttachClick}
        data-tooltip-id="attachment-tooltip"
        data-tooltip-place={isMobile ? 'bottom-start' : 'top-start'}
      >
        {isDesktopDevice ? (
          <Paperclip size={SVG_SIZE_L} />
        ) : (
          <PlusCircle size={SVG_SIZE_L} weight="fill" />
        )}
      </button>
      <input
        id="attachFile"
        type="file"
        accept=".txt,.docx,.pdf,.rtf,.csv,.tsv,.xlsx,.png,.jpg,.jpeg"
        onChange={handleFileSelect}
        className="nj-thread-input-box--attach-input"
      />
      <Suspense>
        <LazyReactTooltip
          clickable
          id="attachment-tooltip"
          disableStyleInjection
          noArrow={true}
          className="nj-attachment-tooltip"
          isOpen={isVisibleTooltip}
          offset={isMobile ? 0 : 20}
        >
          {!!threadInputBoxFile ? (
            <span>Only one attachment is allowed per request</span>
          ) : (
            <>
              <span>Sorry you have reached your daily limit</span>
              {isOpenTier && <GuestAuthLinks />}
              {(isProTrialTier || isFreeTier) && <UpgradeLearnMoreButtons />}
            </>
          )}
        </LazyReactTooltip>
      </Suspense>
    </div>
  );
};
