import {
  ExternalModelProviderType,
  WebSearchTypes,
  WebSearchTypesLabels,
} from 'src/types';
import { Image, Video } from '@phosphor-icons/react';
import { ResearchLlmOpenAiModel } from 'src/types/models/ResearchLlmOpenAiModel';
import { ResearchLlmGoogleModel } from 'src/types/models/ResearchLlmGoogleModel';
import { ResearchLlmAnthropicModel } from 'src/types/models/ResearchLlmAnthropicModel';
import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { AWSLogo } from 'src/images/logos/aws-logo';
import { AnthropicLogo } from 'src/images/logos/anthropic-logo';

export const webSearchTypesList = [
  {
    value: WebSearchTypes.VIDEO_SEARCH,
    label: WebSearchTypesLabels.VIDEO_SEARCH,
    icon: Video,
  },
  {
    value: WebSearchTypes.IMAGE_SEARCH,
    label: WebSearchTypesLabels.IMAGE_SEARCH,
    icon: Image,
  },
];

export const openAIOptions = [
  {
    label: 'GPT-3.5 Turbo',
    value: ResearchLlmOpenAiModel.GPT_3_5_TURBO_1106,
  },
  {
    label: 'GPT-4 Turbo',
    value: ResearchLlmOpenAiModel.GPT_4_1106_PREVIEW,
  },
];

export const googleGeminiOptions = [
  {
    label: 'Gemini 1.0 Pro',
    value: ResearchLlmGoogleModel.GEMINI_1_0_PRO,
  },
];

export const anthropicOptions = [
  {
    label: 'Claude 3 Haiku',
    value: ResearchLlmAnthropicModel.CLAUDE_3_HAIKU_20240307,
  },
  {
    label: 'Claude 3 Sonnet',
    value: ResearchLlmAnthropicModel.CLAUDE_3_SONNET_20240229,
  },
  {
    label: 'Claude 3 Opus',
    value: ResearchLlmAnthropicModel.CLAUDE_3_OPUS_20240229,
  },
];

export enum ExternalModelProvider {
  AMAZON_BEDROCK = 'amazon_bedrock',
  GOOGLE = 'google',
  OPENAI = 'openai',
}

export enum ImageGeneratorsModelProvider {
  BEDROCK = 'bedrock',
  OPENAI = 'openai',
  NINJA = 'ninja',
}

export const externalModelProvidersSet: Record<
  ExternalModelProvider,
  ExternalModelProviderType
> = {
  [ExternalModelProvider.AMAZON_BEDROCK]: {
    icon: AWSLogo,
    icon_name: 'awsLogo',
    label: 'Amazon Bedrock',
    url: 'https://aws.amazon.com/bedrock/?refid=0eaabb80-ee46-4e73-94ae-368ffb759b62',
  },
  [ExternalModelProvider.OPENAI]: {
    icon: ChatGPTLogo,
    icon_name: 'chatGPTLogo',
    label: 'OpenAI',
    url: 'https://platform.openai.com/docs/models',
  },
  [ExternalModelProvider.GOOGLE]: {
    icon_name: 'googleGeminiProLogo',
    label: 'Google',
    url: 'https://ai.google.dev/gemini-api/docs/models/gemini',
  },
};

// the list of external models which will have "NEW" label
export const newExternalModelsList = [
  'bedrock/amazon/amazon.titan-text-premier-v1:0',
  'bedrock/mistral/mistral.mistral-large-2402-v1:0',
  'bedrock/anthropic/anthropic.claude-3-5-sonnet-20240620-v1:0',
  'gemini/google/gemini-1.5-pro',
  'gemini/google/gemini-1.5-flash',
  'openai/openai/gpt-4o-2024-05-13',
];

// the list of image generator models which will have "NEW" label
export const newImageGeneratorModels: string[] = [
  'openai/openai/dall-e-3',
  'bedrock/amazon/amazon.titan-image-generator-v1',
  'bedrock/amazon/stability.stable-diffusion-xl-v1',
];

export const externalModelsSet: Record<
  string,
  {
    icon_name: string;
    display_name: string;
    provider?: ExternalModelProvider;
    hide_icon_in_settings?: boolean;
    icon?: ({ size }: { size?: number }) => JSX.Element;
  }
> = {
  'bedrock/amazon/amazon.titan-text-express-v1': {
    icon: AWSLogo,
    icon_name: 'awsLogo',
    display_name: 'Titan Text G1 - Express',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/amazon.titan-text-lite-v1': {
    icon: AWSLogo,
    icon_name: 'awsLogo',
    display_name: 'Titan Text G1 - Lite',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/amazon.titan-text-premier-v1:0': {
    icon: AWSLogo,
    icon_name: 'awsLogo',
    display_name: 'Amazon Titan Text Premier',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/anthropic/anthropic.claude-3-haiku-20240307-v1:0': {
    icon: AnthropicLogo,
    icon_name: 'claudeLogo',
    display_name: 'Claude 3 Haiku',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-sonnet-20240229-v1:0': {
    icon: AnthropicLogo,
    icon_name: 'claudeLogo',
    display_name: 'Claude 3 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-5-sonnet-20240620-v1:0': {
    icon: AnthropicLogo,
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-opus-20240229-v1:0': {
    icon: AnthropicLogo,
    icon_name: 'claudeLogo',
    display_name: 'Claude 3 Opus',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/cohere/cohere.command-text-v14': {
    icon_name: 'cohereLogo',
    display_name: 'Command',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/cohere/cohere.command-light-text-v14': {
    icon_name: 'cohereLogo',
    display_name: 'Command Light',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/cohere/cohere.command-r-plus-v1:0': {
    icon_name: 'cohereLogo',
    display_name: 'Command R+',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/ai21/ai21.j2-mid-v1': {
    icon_name: 'jurassicLogo',
    display_name: 'Jurassic-2 Mid',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/ai21/ai21.j2-ultra-v1': {
    icon_name: 'jurassicLogo',
    display_name: 'Jurassic-2 Ultra',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/mistral/mistral.mistral-small-2402-v1:0': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Small',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/mistral/mistral.mistral-large-2402-v1:0': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Large',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'gemini/google/gemini-1.0-pro': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.0 Pro',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  'gemini/google/gemini-1.5-pro': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Pro',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  'gemini/google/gemini-1.5-flash': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Flash',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  'openai/openai/gpt-3.5-turbo-1106': {
    icon: ChatGPTLogo,
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-3.5 Turbo',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'openai/openai/gpt-4-0125-preview': {
    icon: ChatGPTLogo,
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4 Turbo',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'openai/openai/gpt-4o-2024-05-13': {
    icon: ChatGPTLogo,
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
};

export const imageGeneratorsModelsSet: Record<
  string,
  {
    display_name: string;
    icon_name?: string;
    provider: ImageGeneratorsModelProvider;
    hide_icon_in_settings?: boolean;
    icon?: ({ size }: { size?: number }) => JSX.Element;
  }
> = {
  'openai/openai/dall-e-2': {
    icon: ChatGPTLogo,
    icon_name: 'chatGPTLogo',
    display_name: 'DALL-E 2',
    provider: ImageGeneratorsModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'openai/openai/dall-e-3': {
    icon: ChatGPTLogo,
    icon_name: 'chatGPTLogo',
    display_name: 'DALL-E 3',
    provider: ImageGeneratorsModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/amazon.titan-image-generator-v1': {
    icon: AWSLogo,
    display_name: 'Amazon Titan Image Generator',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'awsLogo',
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/stability.stable-diffusion-xl-v1': {
    display_name: 'Stable Diffusion XL 1.0',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'bedrockStableLogo',
  },
  'sagemaker/ninjatech/pixart-sigma': {
    display_name: 'Ninja',
    provider: ImageGeneratorsModelProvider.NINJA,
    icon_name: 'ninjaIcon',
  },
};

export const ninjaLLMComponents: Record<
  string,
  {
    display_name: string;
  }
> = {
  coder: {
    display_name: 'NinjaLLM Coder',
  },
  ninja_coder: {
    display_name: 'NinjaLLM Coder',
  },
  scheduler: {
    display_name: 'NinjaLLM Scheduler',
  },
  ninja_scheduler: {
    display_name: 'NinjaLLM Scheduler',
  },
  advisor: {
    display_name: 'NinjaLLM Advisor',
  },
  ninja_advisor: {
    display_name: 'NinjaLLM Advisor',
  },
  chitchat: {
    display_name: 'NinjaLLM ChitChat',
  },
  research: {
    display_name: 'NinjaLLM Research',
  },
  ninja_deep_research: {
    display_name: 'NinjaLLM Deep Research',
  },
  ninja_fast_research: {
    display_name: 'NinjaLLM Fast Research',
  },
  ninja_media_search: {
    display_name: 'NinjaLLM Media Search',
  },
  image: {
    display_name: 'NinjaLLM Image',
  },
  image_generator: {
    display_name: 'NinjaLLM Image',
  },
  ninja_image_generator: {
    display_name: 'NinjaLLM Image Generator',
  },
  ninja_image_editor: {
    display_name: 'NinjaLLM Image Editor',
  },
};
