import { useMemo } from 'react';
import { useBreakpoint, useTimeZone } from 'src/hooks';
import { Message } from 'src/types';
import { formatHours } from 'src/utils';
import { MessageAuthorPicture } from '../FlatAppearance/components/MessageAuthorPicture';
import { MessageAuthorName } from '../FlatAppearance/components/MessageAuthorName';
import { UserMessageBody } from './components/UserMessageBody';

interface UserMessageProps {
  message: Message;
}

export const UserMessage = ({ message }: UserMessageProps) => {
  const { userTimeZone } = useTimeZone();
  const { isMobile } = useBreakpoint();
  const { timestamp } = message;

  const date = useMemo(
    () => formatHours(timestamp, userTimeZone),
    [timestamp, userTimeZone],
  );

  return (
    <div className="nj-thread-message">
      <div className="nj-thread-message--author-wrapper">
        <div className="nj-thread-message--flex-wrapper">
          <MessageAuthorPicture message={message} />

          {isMobile && (
            <>
              <MessageAuthorName message={message} />
              <span className="nj-thread-message--date">{date}</span>
            </>
          )}
        </div>
      </div>

      <div className="nj-thread-message--container">
        {!isMobile && (
          <div className="nj-thread-message--header">
            <div className="nj-thread-message--flex-wrapper">
              <MessageAuthorName message={message} />

              <span className="nj-thread-message--date">{date}</span>
            </div>
          </div>
        )}

        <UserMessageBody message={message} />
      </div>
    </div>
  );
};
