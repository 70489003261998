// TODO(olha): add all routes
export enum AppRoutes {
  HOME = '/',
  MANAGE_ACCOUNT = '/manage-account',
}

export enum UserSettingsNestedRoutes {
  PROFILE = 'profile',
  INTEGRATIONS = 'integrations',
  GENERAL = 'general',
  ACTIVITY = 'activity',
}

export enum BillingSettingsNestedRoutes {
  SUBSCRIPTION = 'subscription',
  BILLING = 'billing',
}

export interface QueryParams {
  session_id?: string;
  query?: string;
  conv?: string;
  // temporary until our App is verified - Meta needs a prod url to test login functionality
  meta_verification?: string;
  username?: string;
  code?: string;
  state?: string;
  expired_at?: string;
  email?: string;
  recommendation?: string;
  error?: string;
  error_description?: string;
  ['password-changed']?: string;
}
