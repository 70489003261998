import { Link } from 'react-router-dom';
import { Pulse } from '@phosphor-icons/react';
import { AppRoutes, UserSettingsNestedRoutes } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';
import styles from './AccountActivityLink.module.scss';
import { useSession } from 'src/hooks';
import classNames from 'classnames';

type AccountActivityLinkProps = {
  className?: string;
};

export const AccountActivityLink = ({
  className,
}: AccountActivityLinkProps) => {
  const { isProTier, isProTrialTier } = useSession();

  if (!(isProTier || isProTrialTier)) {
    return null;
  }

  return (
    <Link
      to={`${AppRoutes.MANAGE_ACCOUNT}/${UserSettingsNestedRoutes.ACTIVITY}`}
      className={classNames(styles.root, className)}
    >
      <Pulse size={SVG_SIZE_M} />
      Account activity
    </Link>
  );
};
