export const DEFAULT_NINJA_BETA_LINK = 'https://www.ninjatech.ai/';
export const DEFAULT_PRIVACY_POLICY_LINK = 'https://www.ninjatech.ai/privacy';

export const DEFAULT_TERMS_OF_SERVICE_LINK =
  'https://www.ninjatech.ai/terms-of-service';

export const DEFAULT_ACCEPTABLE_USE_POLICY =
  'https://www.ninjatech.ai/acceptable-use-policy';

export const DEFAULT_NINJATECH_LINK = 'https://www.ninjatech.ai';
export const DEFAULT_NINJATECH_BLOG_LINK = 'https://www.ninjatech.ai/news';

export const LEARN_MORE_PRICES_LINK = 'https://www.ninjatech.ai/pricing';
export const PAY_PER_USE = 'https://www.ninjatech.ai/pricing#pay-per-use';
export const RATE_LIMIT = 'https://www.ninjatech.ai/pricing#rate-limits';

export const DEEP_RESEARCHER = 'https://www.ninjatech.ai#deep-researcher';
export const IMAGE_GEN = 'https://www.ninjatech.ai#image-gen';
export const CODER = 'https://www.ninjatech.ai#coder';
export const ADVISOR = 'https://www.ninjatech.ai#advisor';
export const SCHEDULER = 'https://www.ninjatech.ai#scheduler';

export const ONBOARDING_IMAGE =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/Onbarding_Screen_Visual.gif';

export const WELCOME_PRO_TRIAL_IMAGE =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/Welcome_to_ninja_pro_trial.gif';
