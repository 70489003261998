import { Header } from 'src/pages/AuthPages/components/Header';
import ninjaOops from 'src/images/login/atlas_oops_desktop.png';
import { LoginHeader } from 'src/pages/AuthPages/components/LoginHeader';
import { resendSignUpCode } from 'aws-amplify/auth';
import { toast } from 'react-toastify';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { useQueryParams } from 'src/hooks';

export const VerificationFailedPage = () => {
  const { searchParams } = useQueryParams();
  const { username } = searchParams;

  const handleResendLink = async () => {
    try {
      if (username) {
        await resendSignUpCode({ username });
        toast('Verification link resent');
      }
    } catch (error) {
      toast.error(
        error
          ? (error as ServiceError).message
          : 'Something went wrong, try again later',
      );
    }
  };
  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<LoginHeader />} />
      <main className="nj-auth-content">
        <h3 className="nj-auth-header-secondary">Verification failed</h3>
        <div className="nj-auth-verified-block">
          <p className="nj-auth-verified-text">
            This verification link is either invalid or has expired
          </p>
          <button
            className="nj-auth-form--submit-button"
            onClick={handleResendLink}
          >
            Resend verification link
          </button>
        </div>
      </main>
      <img className="nj-auth-background-image" src={ninjaOops} alt="Ninja" />
    </div>
  );
};
