import { useBreakpoint, useGoogleResourcesAccess, useSession } from 'src/hooks';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import styles from './Integrations.module.scss';
import {
  Plus,
  CloudCheck,
  CaretDown,
  CaretUp,
  CheckCircle,
} from '@phosphor-icons/react';
import GoogleLogo from 'src/images/logos/google-logo.svg';
import { SVG_SIZE_M, SVG_SIZE_XXXL } from 'src/constants';
import { useCollapse } from 'react-collapsed';
import { useAppDispatch } from 'src/store';
import { contactsApi, ContactsTags } from 'src/store/services';

export const Integrations = () => {
  const { appUser } = useSession();
  const {
    canAccessCalendar,
    canAccessContacts,
    canAccessDirectory,
    revokeGoogleAccess,
    connectAll,
  } = useGoogleResourcesAccess();
  const { email } = appUser;
  const dispatch = useAppDispatch();

  const { getToggleProps, isExpanded } = useCollapse();
  const { isDesktopAndUp, isMobileOrTablet } = useBreakpoint();

  const hasConnection =
    canAccessCalendar || canAccessContacts || canAccessDirectory;

  const accessData = [
    {
      title: 'Calendar',
      isConnected: canAccessCalendar,
    },
    {
      title: 'Contacts',
      isConnected: canAccessContacts,
    },
    {
      title: 'Directory',
      isConnected: canAccessDirectory,
    },
  ];

  const accessCount = accessData.filter((access) => access.isConnected);
  const accessText = accessCount.length === 1 ? ' access' : ' accesses';

  const handleDisconnect = () => {
    revokeGoogleAccess(appUser.user_id);
    dispatch(contactsApi.util.invalidateTags([ContactsTags.Contacts]));
  };

  return (
    <ManageAccountContentPage
      title="Integrations"
      subtitle="Connect Ninja with your existing tools"
    >
      <div className={styles.root}>
        <h4 className={styles.title}>
          Accounts
          {isDesktopAndUp && (
            <>
              {!hasConnection && (
                <span className={styles.addAccount} onClick={connectAll}>
                  <Plus size={SVG_SIZE_M} /> Add
                </span>
              )}
            </>
          )}
        </h4>
        <h5 className={styles.subtitle}>
          Allow Ninja to access your calendars and contacts.
        </h5>

        <div className={styles.content}>
          {!hasConnection && (
            <CloudCheck size={SVG_SIZE_XXXL} className={styles.cloudImage} />
          )}

          {isMobileOrTablet && (
            <>
              {!hasConnection && (
                <span className={styles.addAccount} onClick={connectAll}>
                  <Plus size={SVG_SIZE_M} /> Add
                </span>
              )}

              <hr className="divider" />
            </>
          )}
        </div>

        {hasConnection && (
          <div className={styles.card}>
            <span className={styles.logo}>
              <img
                src={GoogleLogo}
                alt="Google Account"
                width="20"
                height="20"
              />
            </span>
            <div className={styles.info}>
              <b className={styles.email}>{email}</b>
              <span className={styles.access}>
                {accessCount.length}
                {accessText}
              </span>

              {isExpanded && (
                <ul className={styles.list}>
                  {accessData.map(({ title, isConnected }) => {
                    return isConnected ? (
                      <li key={title}>
                        <CheckCircle size={SVG_SIZE_M} weight="fill" /> {title}
                      </li>
                    ) : null;
                  })}

                  {accessData.map(({ title, isConnected }) => {
                    return !isConnected ? (
                      <li key={title}>
                        <span className={styles.connect} onClick={connectAll}>
                          <Plus size={SVG_SIZE_M} /> Add {title}
                        </span>
                      </li>
                    ) : null;
                  })}
                </ul>
              )}
            </div>

            {isExpanded && (
              <span className={styles.remove} onClick={handleDisconnect}>
                Remove
              </span>
            )}

            <span className={styles.action} {...getToggleProps()}>
              {isExpanded ? (
                <CaretUp size={SVG_SIZE_M} />
              ) : (
                <CaretDown size={SVG_SIZE_M} />
              )}
            </span>
          </div>
        )}
      </div>
    </ManageAccountContentPage>
  );
};
