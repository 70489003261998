import { useState } from 'react';
import { PaymentPlanOption, PaymentPlanOptionWithSale } from 'src/types';
import { PLAN_OPTIONS } from 'src/constants';
import { useSession } from 'src/hooks';
import log from 'src/utils/logger';
import { useUpgradeSubscriptionMutation } from 'src/store/services';
import './UpgradeForm.scss';
import { PlanSelect } from 'src/components/UpgradeModal/components/PlanSelect';

export const UpgradeForm = () => {
  const { appUser, isProTrialTier } = useSession();

  const defaultPlan =
    PLAN_OPTIONS.find((item) => item.isDefault) || PLAN_OPTIONS[0];

  const [selectedPlan, setSelectedPlan] = useState<
    PaymentPlanOptionWithSale | PaymentPlanOption
  >(defaultPlan);

  // TODO(olha): will be implement in the next PR
  // const { data } = useGetStripePricesQuery({ user_id: appUser.user_id });

  const [upgradeSubscription, { isLoading }] = useUpgradeSubscriptionMutation();

  const onSubmit = async () => {
    const { lookup_key, promotion_code } = selectedPlan;

    try {
      const result = await upgradeSubscription({
        lookup_key,
        user_id: appUser.user_id,
        promotion_code: isProTrialTier ? promotion_code : undefined,
      }).unwrap();

      const redirectUrl =
        result.checkout_session?.url || result.billing_portal_session?.url;

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      log.error(error);
    }
  };

  return (
    <div className="nj-upgrade-form">
      <PlanSelect selectedPlan={selectedPlan} onPlanChange={setSelectedPlan} />

      <button
        className="nj-button nj-button--primary nj-upgrade-form--submit"
        onClick={onSubmit}
        disabled={isLoading}
        // TODO(olha): will be implement in the next PR
        // disabled={isLoading || !data}
      >
        Upgrade
      </button>
    </div>
  );
};
