import { useMemo } from 'react';
import {
  ConversationRole,
  Message,
  TaskState,
  isMessageWithCreationTaskId,
  ResearchTaskCreationCard,
} from 'src/types';
import { useConversation } from 'src/hooks';
import { StopTaskButton } from 'src/components/StopTaskButton';
import { ViewTaskButton } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ViewTaskButton';

interface ThreadMessageActionsProps {
  message: Message;
}

export const ThreadMessageActions = ({
  message,
}: ThreadMessageActionsProps) => {
  const { role } = message;
  const { getTaskFromConversationById } = useConversation();

  const taskState = useMemo(() => {
    return (
      (isMessageWithCreationTaskId(message) &&
        getTaskFromConversationById(message.payload.task_id)?.state) ||
      TaskState.IN_PROGRESS
    );
  }, [getTaskFromConversationById, message]);

  if (role === ConversationRole.USER || !isMessageWithCreationTaskId(message)) {
    return null;
  }

  const { task_id, task_hash } = message.payload;

  return (
    <div className="nj-thread-message--flex-wrapper">
      <StopTaskButton
        taskId={task_id}
        taskState={taskState}
        taskHash={task_hash}
        className="nj-thread-message--action-round-button"
      />

      <ViewTaskButton
        taskId={task_id}
        taskState={taskState}
        hasDeepResearchSection={
          !!(message.payload as ResearchTaskCreationCard)?.research_card?.data
            ?.deep_research_result
        }
      />
    </div>
  );
};
