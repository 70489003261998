import { useMemo } from 'react';
import classNames from 'classnames';
import { useSession } from 'src/hooks';
import { UserTierStatus } from 'src/types';
import { DefaultStaticField } from '../DefaultStaticField';
import { GuestsNotesDefaultValue } from '../GuestsNotesDefaultValue';
import { AttendeeListField } from '../AttendeeListField';
import { GuestAvailabilityField } from '../GuestAvailabilityField';
import { DescriptionField } from '../DescriptionField';
import { useMeetingCardFieldsWatch } from '../../hooks/useMeetingCardFieldsWatch';
import type { MeetingCardFormData } from '../../CreateMeetingCard';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { RenewEarlyButton } from 'src/components/RenewEarlyButton';

interface GuestsNotesFieldProps {
  maxAttendeesLength: number;
}

export const GuestsNotesField = ({
  maxAttendeesLength,
}: GuestsNotesFieldProps) => {
  const { isFreeTier, isProTier, appUser, isProTrialTier } = useSession();

  const { attendees } = useMeetingCardFieldsWatch<MeetingCardFormData>();

  const isErrorInAttendeeList = useMemo(
    () => attendees && attendees.length > maxAttendeesLength,
    [attendees, maxAttendeesLength],
  );

  const label = useMemo(() => {
    if (isFreeTier) {
      return (
        <div className="nj-create-meeting-card--guest-notes-label">
          <p className="nj-create-meeting-card--guest-notes-label-text">
            <span>Guests & notes</span>

            <span className="nj-create-meeting-card--guest-notes-label-description">
              Only 1:1 meetings available. Upgrade to PRO plan for multiparty
              scheduling.
            </span>
          </p>

          <UpgradeButton />
        </div>
      );
    }

    if (
      (isProTier || isProTrialTier) &&
      appUser.tier_status === UserTierStatus.QUOTA_EXCEEDED
    ) {
      return (
        <div className="nj-create-meeting-card--guest-notes-label">
          <p className="nj-create-meeting-card--guest-notes-label-text">
            <span>Guests & notes</span>

            <span className="nj-create-meeting-card--guest-notes-label-description">
              Only 1:1 meetings available due to insufficient credits.
              {isProTier &&
                ' Renew your subscription early to continue access to PRO features.'}
            </span>
          </p>

          {isProTrialTier ? <UpgradeButton /> : <RenewEarlyButton />}
        </div>
      );
    }

    return 'Guests & notes';
  }, [isFreeTier, isProTier, isProTrialTier, appUser.tier_status]);

  return (
    <DefaultStaticField
      label={label}
      defaultValue={<GuestsNotesDefaultValue />}
      className={classNames({ warning: isErrorInAttendeeList })}
    >
      <>
        <AttendeeListField maxAttendeesLength={maxAttendeesLength} />
        <GuestAvailabilityField />
        <DescriptionField />
      </>
    </DefaultStaticField>
  );
};
