import { MessageSkill, SortOrder } from 'src/types';
import {
  Calendar,
  ChatCircleText,
  Code,
  Icon,
  ListMagnifyingGlass,
} from '@phosphor-icons/react';

export const DEFAULT_NUMBER_OF_REFERENCES = 3;
export const DEFAULT_REFERENCE_WIDTH = 200;
export const DEFAULT_REFERENCE_HEIGHT = 112;
export const RESEARCH_STREAMING_SPEED = 5;
export const SCROLLER_PAUSE_TIME = 350;
export const DEFAULT_SCROLLER_SPEED = 50;
export const DEFAULT_STREAMING_SPEED = 2;
export const DEFAULT_CHAT_NAME = 'new-chat';
export const DEFAULT_CHAT_ID = 'default';
export const DEFAULT_PAGE_LIMIT = 25;
export const DEFAULT_SORT_ORDER = SortOrder.DESC;

export const TEMPORARY_CONVERSATION_ID = 'new-chat';

export const DEFAULT_GUEST_TAG = '[@guest]';
export const TAGS_TO_REPLACE = [DEFAULT_GUEST_TAG];

export const CommunicationStyle: Record<number, string> = {
  1: 'Concise',
  2: 'Brief',
  3: 'Moderate',
  4: 'Detailed',
  5: 'Verbose',
};

export const RedoSkillButtons: Array<{ skill: MessageSkill; icon: Icon }> = [
  {
    skill: MessageSkill.ADVISOR,
    icon: ChatCircleText,
  },
  {
    skill: MessageSkill.CODER,
    icon: Code,
  },
  {
    skill: MessageSkill.RESEARCHER,
    icon: ListMagnifyingGlass,
  },
  {
    skill: MessageSkill.SCHEDULER,
    icon: Calendar,
  },
];

export const CITATION_REGEX = /\[citation:(\d+)\]/g;
export const CITATION_TITLE_REGEX = /^citation:(\d+)$/;
export const CODE_WITHOUT_NEWLINE_REGEX = /(\\n)?\s*```/g;
export const CODE_LANGUAGE_REGEX = /```(\w+)?\n([\s\S]*?)```/g;
export const FILE_REGEX =
  /---\[original-filename="([^"]+)" converted-filename="([^"]+)"\]---/;
export const EDIT_FILE_REGEX = /---\[edit-image-url="([^"]+)"\]---/;

export const AttachmentFileInputId = 'attachFile';
