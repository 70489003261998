import { CompanyLinksSet } from 'src/pages/AuthPages/components/CompanyLinksSet';
import { useSession } from 'src/hooks';
import { UserTier } from 'src/types/models/UserTier';
import { LabelPro } from 'src/components/LabelPro';
import { LabelLimitedTime } from 'src/components/LabelLimitedTime';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';

export const TiersAuthFooter = () => {
  const { appUser, isProTrialTier } = useSession();

  const renderContent = () => {
    switch (appUser.tier_id) {
      case UserTier.OPEN:
        return (
          <>
            <h4 className="nj-tier-auth-footer-title">
              <span>Sign up for Ninja PRO Trial</span>
              <LabelLimitedTime />
            </h4>

            <p className="nj-tier-auth-footer-description">
              Enjoy 7 days of unlimited access to Ninja Agents and receive $5 in
              credits for external model usage.
            </p>

            <GuestAuthLinks />
          </>
        );
      case UserTier.FREE:
      case UserTier.PRO_TRIAL:
        return (
          <>
            {isProTrialTier ? (
              <h4 className="nj-tier-auth-footer-title">
                <span>Upgrade to Ninja PRO: $1 for first month</span>
                <LabelLimitedTime />
              </h4>
            ) : (
              <h4 className="nj-tier-auth-footer-title">
                <span>Upgrade to Ninja PRO</span>
              </h4>
            )}

            <p className="nj-tier-auth-footer-description">
              {appUser.tier_id === UserTier.FREE
                ? 'Enjoy unlimited access to Ninja and receive credits towards 24+ external models.'
                : 'Continue enjoying PRO features - Upgrade now!'}
            </p>

            <UpgradeLearnMoreButtons />
          </>
        );
      case UserTier.PAID:
      case UserTier.TESTING:
        return (
          <div className="nj-tier-auth-footer-current-plan-row">
            <span>Current plan:</span>
            <LabelPro />
          </div>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className="nj-tier-auth-footer">
      {renderContent()}

      <div className="nj-tier-auth-footer-links-wrapper">
        <CompanyLinksSet />
      </div>
    </div>
  );
};
