import { useContext, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { useBreakpoint, useTheme } from 'src/hooks';
import { ConversationListMainContent } from './components/ConversationListMainContent';
import { OverlayBackground } from 'src/components/OverlayBackground';
import { ConversationControl } from './components/ConversationControl';
import { ANIMATION_TIMEOUT } from 'src/constants';
import styles from './MultiConversationsListPanel.module.scss';

/**
 * New Paradigm Multi-Threaded Conversation & Task Chat.
 */
export const MultiConversationsListPanel = () => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileOrTablet } = useBreakpoint();

  const { isFullscreen } = useContext(AvatarModeContext);

  const { isTasksListExpanded, setIsTasksListExpanded } = useTheme();
  const { toggleProfileSettingsBarExpanded, toggleSettingsPanelExpanded } =
    useTheme();

  useEffect(() => {
    if (isMobileOrTablet) {
      toggleProfileSettingsBarExpanded(false);
      toggleSettingsPanelExpanded(false);
      setIsTasksListExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileOrTablet]);

  if (isFullscreen) {
    return null;
  }

  return (
    <>
      {isMobileOrTablet && (
        <OverlayBackground
          showOverlay={isTasksListExpanded}
          onClick={() => setIsTasksListExpanded(false)}
          zIndex={1000}
          className="transparent-tablet"
          ref={overlayRef}
        />
      )}

      <CSSTransition
        in={isTasksListExpanded}
        timeout={ANIMATION_TIMEOUT}
        classNames={
          isMobileOrTablet
            ? 'nj-animate-horizontal-appearance-ltr'
            : 'nj-animate-left-panel-width-reduce'
        }
        unmountOnExit
        nodeRef={containerRef}
      >
        <div ref={containerRef} className={styles.root}>
          <ConversationListMainContent />
        </div>
      </CSSTransition>

      {isMobileOrTablet && <ConversationControl type="panel" />}
    </>
  );
};
